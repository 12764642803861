
import { Options, Vue } from 'vue-class-component';
import SavedPaymentModule from '@/components/SavedPaymentModule.vue';
import ProductCardList from '@/components/ProductCardList.vue';
import CreateOrderItemTable from '@/components/CreateOrderItemTable.vue';
import axios, { AxiosResponse } from 'axios';
import router from '@/router';
import { PaymentType } from '@/enums/PaymentType';
import SelectedCustomer from '@/components/SelectedCustomer.vue';
import { OrderCalculationService } from '@/services/OrderCalculationService';
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import ComplimentaryService from '@/services/ComplimentaryService';
import { DiscountType } from '@/enums/DiscountType';
import { ItemType } from '@/enums/ItemType';
import { v4 } from 'uuid';
import { TransactionType } from '../enums/TransactionType';
import LocalStorageService from '@/services/LocalStorageService';
import RoleService from '@/services/RoleService';
import createDiscountPopoverService from '@/services/createDiscountPopoverService';
import { v4 as uuidv4 } from 'uuid';

// import CalculationResults from '@/Interfaces/CalculationResults'
// @ts-ignore
// import { Carousel, Slide } from 'vue-carousel'
// @ts-ignore
import { orderBy, sortBy } from 'lodash';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import QuoteService from '@/services/QuoteService';
// @ts-ignore
import { mapActions, mapGetters, mapMutations } from 'vuex';
// @ts-ignore
import CheckoutProcess from '@/components/CheckoutProcess.vue';

import { ApiFilter } from '@/contracts/ApiFilter';
import SelectCustomerModal from '@/components/SelectCustomerModal.vue';

import ItemService from '@/services/ItemService';
import AddTaxButton from '@/shared/AddTaxButton.vue';
import TaxesSelection from '@/components/TaxesSelection.vue';
import QuoteForm from '@/components/QuoteForm.vue';
import ContactService from '@/services/ContactService';
import ItemVariantFlattend from '@/Interfaces/ItemVariantFlattend';
import AddDiscountButton from '@/shared/AddDiscountButton.vue';
import { getItemType } from '@/helper/products';
import CategoryListInCreateOrder from '@/components/CategoryListInCreateOrder.vue';
import DatePicker from '@/shared/DatePicker.vue';
import { parseDueDate } from '@/helper/Helpers';

@Options({
  components: {
    SavedPaymentModule,
    SelectedCustomer,
    ProductCardList,
    CreateOrderItemTable,
    CheckoutProcess,
    SelectCustomerModal,
    AddTaxButton,
    TaxesSelection,
    AddDiscountButton,
    CategoryListInCreateOrder,
    DatePicker,
    QuoteForm,
  },
  data() {
    return {
      ItemType,
    };
  },
  providers: [
    LocalStorageService,
    RoleService,
    createDiscountPopoverService,
    QuoteService,
  ],
  filters: {},
  methods: {
    parseDueDate,
    getItemType,
    ...mapActions([
      'getLocationList',
      'getDepositeAccounts',
      'setItemFilter',
      'getitemList',
      'setSelectedLocationId',
      'getCustomerList',
      'setSelectedCustomerId',
      'setLocationOptions',
      'allQuotes_List',
      'setLocationDiscount',
      'getSelectedItems',
    ]),
    ...mapMutations(['setItemFilter']),
  },
  computed: {
    ...mapGetters([
      'allLocationList',
      'allCustomerList',
      'allItemList',
      'depositeOptions',
      'locationOptions',
      'customerOptions',
      'selectedLocationId',
      'selectedCustomerId',
      'locationDiscounts',
      'locationDiscountOptions',
    ]),
    filteredCompOptions() {
      return this.compOptions.filter((option) => option && option.reason);
    },
    checkCompItemInOrder() {
      return this.newOrder.variantSnapshots.some(
        (item: any) => item.isCompItem,
      );
    },
  },
})
export default class CreateOrder extends Vue {
  quoteIsPaid: false;
  updatedPrice: number;
  createNewQuote: boolean = true;
  variantSnapshots: ItemVariantFlattend[];
  activateNotification = false;
  quotesListCount = 0;
  typeNotification = 'success';
  titleNotification = '';
  messageNotification = '';
  showSelectCustomerModal = false;
  customerValue = '';
  itemsService = new ItemService();
  contactService = new ContactService();
  show_payment_option = false;
  textCount = 0;
  myHeight = '98%';
  searchTimeout: any;
  itemAdded = false;
  popupProuductName: string | undefined = '';
  selectedCategoryId: any;
  selectedCategoryName: string;
  bundleCount = 0;
  roleService = new RoleService(new LocalStorageService());
  localStorageService = new LocalStorageService();
  createDiscountService = new createDiscountPopoverService();
  quoteService = new QuoteService();
  calcService = new OrderCalculationService();
  complimentaryService = new ComplimentaryService();
  userList: any = [];
  calcResults: any = {};
  newOrder = new CreateOrderRequest();
  newTransaction: any = {};
  clickoncharger = false;
  selectableItems: any[] = [];
  bundleOpened = false;
  filteredItems: any[] = [];
  gfilteredItems: any[] = [];
  sfilteredItems: any[] = [];
  bfilteredItems: any[] = [];
  tfilteredItems: any[] = [];
  ofilteredItems: any[] = [];
  categoryFilteredItems = [];
  searchTerm = '';
  iframeUrl = null;
  iframeUrlError: string[] | null = null;
  orderId = null;
  locations: any[] = [];
  customers: any[] = [];
  showZeroDollarErrorMessage = false;
  showConflictErrorMessage = false;
  openCategorySlide = false;
  savedPaymentMethods: any[] = [];
  variantLocation: any[] = [];
  isMarkAsPaidClicked = false;
  f;
  loading = false;
  paymentDetailsloading = false;
  loaderForQuote = false;
  isProductLoading = false;
  isCardPaymentProgress = false;
  barcodeValue = '';
  iframeshow = false;
  iframeshowurl = '';
  isEditContactShow = false;
  url_parameters: any = {};
  filterByGoods = false;
  filterByServices = false;
  filterByBundle = false;
  filterByToppick = false;
  filterByInStock = false;
  popover_tip = false;
  quotes: any = [];
  showimport = false;
  selectedQuoteId: any = '';
  isSuperadminLoc = false;
  page = 1;
  pageSize = 20;
  previous_Next_Disable = true;
  errorMsg = '';
  showErrorMsg = false;
  selectedItem = null;
  searchQuery = '';
  itemTaxDetails = [];
  fields_quotes = {
    itemName: {
      label: 'Item Name',
      key: 'itemName',
    },
    itemManufacturer: {
      label: 'Item manufacturer',
      key: 'itemManufacturer',
    },
    variantName: {
      label: 'Variant Name',
      key: 'variantName',
    },
    itemType: {
      label: 'Item Type',
      key: 'itemType',
    },
    variantSku: {
      label: 'SKU',
      key: 'variantSku',
    },
    variantPrice: {
      label: 'Price',
      formatter: (price: any) => {
        return this!.$options!.filters!.currency(price);
      },
    },
    variantQuantity: {
      label: 'Quantity',
      key: 'variantQuantity',
    },
    variantUpc: {
      label: 'UPC',
      key: 'variantUpc',
    },
  };
  iFrameUrl2: any = '';
  fields_tip = {
    itemName: {
      label: 'Item Name',
      key: 'itemName',
    },
    variantPrice: {
      label: 'Price',
      formatter: (price: any) => {
        return this!.$options!.filters!.currency(price);
      },
    },
    variantQuantity: {
      label: 'Quantity',
      key: 'variantQuantity',
    },
    variantTotalPrice: {
      label: 'Total Price',
      formatter: (price: any, key: any, item: any) => {
        const totalPrice = item.variantPrice * item.variantQuantity;
        return this!.$options!.filters!.currency(totalPrice);
      },
    },
    tip: {
      label: 'Tip',
    },
    remove: {
      label: '%',
    },
  };
  newvariantlist: any = [];
  menuOpen = false;
  pages = 1;
  selectedItemMenu = null;
  selectedReason = { id: '', reason: '' };
  selectedMenuItem = '';
  showMarkComplimentary = false;
  compOptions = [];
  compReason = '' as Object | string;
  pageCount = 50;
  reasonForComp = '';
  useLater = false;

  isAddToPopupBlock = false;
  itemPluseFlag = false;
  addedItem: any = {};
  addedType = '';
  addItemProductName = '';
  outOfStockList: any = [];
  newOrderQuote: any = {};
  markedAsPaid = false;
  showQuote = false;
  selectedCustomer: any = null;
  locationId: any = this.localStorageService.getItem('primary_location_id');
  customerId: any = null;
  URLcustomer: any;
  showError = false;
  chargeDisabled = false;
  chargeInitiated = false;
  popoverShow = false;
  showDiscountButton = true;
  paymentType: any = PaymentType.None;
  payformVisible = false;
  savedPaymentFormVisible = false;
  disableLocationSelect = false;
  disableCustomerSelect = false;
  showCardOptions = false;
  isProcessingSavedCard = false;
  discount: any;
  showPayform = false;
  itemsInCartForBundle: any = [];
  updatedVariant: any;
  alertTitle = '';
  alertMessage = '';
  customerID = '';
  showConfirm = false;
  is_product_accountvault = false; // IVI-226
  sortByCol: any[] = [
    { value: null, text: 'Please Select Column' },
    { value: 'name', text: 'Name Ascending' },
    { value: 'nameDesc', text: 'Name Descending' },
    { value: 'price', text: 'Price Ascending' },
    { value: 'priceDesc', text: 'Price Descending' },
    { value: 'mostPurchasedItem', text: 'Most Purchased Ascending' },
    { value: 'mostPurchasedItemDesc', text: 'Most Purchased Descending' },
  ];
  selectedCol: any = null;
  private isChargeDisabledNew = false;
  customerSelectedIndex: any;

  checkoutItemToRemove;
  pageView = true;
  isAdded = false;
  splitPaymentsNumber = 0;
  currentPaymentSplit = 0;
  primaryOrderResponse: any = {};
  isDivided = false;
  orderTransactions: any = [];

  nextItems() {
    if (this.$store.getters.selectedLocationId) {
      this.page++;
      this.fetchItemOnPagination();
    }
  }

  newQuote(value: boolean) {
    if (value) {
      this.createNewQuote = true;
      this.newOrder.id = this.getuuid();
      this.newOrder.dueDate = '';
      this.newOrderQuote.dueDate = '';
      this.newOrder.quote_name = '';
      this.clearCustomer();
    } else {
      this.createNewQuote = false;
    }
  }

  isInStock(item) {
    if (item.itemType === ItemType.Bundle) {
      if (item.itemType === ItemType.Bundle && item.variantStock < 1) {
        return false;
      } else {
        return true;
      }
    }
    if (item.itemType === ItemType.Bundle) {
      if (item.bundleOutOfStock) {
        return false;
      }
      return true;
    }
    if (item.itemType === ItemType.Good) {
      if (
        (item.variantStock > 0 && item.variantTracking) ||
        !item.variantTracking
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (item.itemType === 2) {
      return true;
    }
    return true;
  }

  async fetchItemOnPagination() {
    this.loading = true;
    let itemType = '0';
    const topPicks = this.filterByToppick ? 1 : 0;
    const outOfStock = this.filterByInStock ? 1 : 0;

    if (this.filterByGoods) {
      itemType += ',1,';
    }

    if (this.filterByBundle) {
      itemType += ',3,';
    }

    if (this.filterByServices) {
      itemType += ',2,';
    }

    if (itemType !== '0') {
      itemType = itemType.substring(2);
      itemType = itemType.substring(0, itemType.length - 1).replace(',,', ',');
    }

    this.$store.commit('setItemFilter', {
      location: this.$store.getters.selectedLocationId,
      page: this.page,
      pageSize: this.pageSize,
      search: this.searchTerm,
      itemType: itemType,
      topPicks: topPicks,
      outofStock: outOfStock,
      ids: null,
    });
    await this.$store.dispatch('getItemList').then(() => {
      const items = this.$store.getters.allItemList;
      this.filteredItems = this.selectableItems;
      if (this.filterByInStock) {
        this.filteredItems = this.filteredItems.filter(
          (filteredItem) => filteredItem.bundleOutOfStock !== true,
        );
      }
      this.isProductLoading = false;
      this.loading = false;
      items.forEach(async (item: any) => {
        const listOfVariantStock = [];
        if (item.itemType === ItemType.Bundle) {
          let price = 0;
          let updatedPrice = 0;
          await item.bundles.forEach((bundle: any) => {
            price += parseFloat(bundle.variant.price.toString());
            listOfVariantStock.push({
              id: bundle.variant.id,
              stock: bundle.variant.variantLocations[0].currentStock,
            });
          });
          if (item.bundleCustomPrice !== '0.00') {
            updatedPrice = item.bundleCustomPrice;
          }
          if (item.bundleDiscount) {
            updatedPrice = price - (item.bundleDiscount / 100) * price;
          }
          if (!item.bundleCustomPrice && !item.bundleDiscount) {
            updatedPrice = price;
          }
          if (item.bundles && item.bundles.length > 0) {
            this.selectableItems.push({
              discountSelection: true,
              variantId: '',
              variantName: '',
              variantSku: item.bundles ? item.bundles[0].bundleSKU : '',
              itemHasImage: item.hasImage,
              itemImageSrc: item.imageSrc,
              variantPrice: updatedPrice,
              variantQuantity: 0,
              itemDiscount: 0,
              itemName: item.name,
              itemType: item.itemType,
              itemDescription: item.description,
              itemManufacturer: item.manufacturer,
              itemTaxes: item.taxes!,
              itemId: item.id,
              bundles: item.bundles,
              bundleOutOfStock: item.bundle_out_of_stock,
              bundleTotalStock: item.bundle_total_stock,
              variantStockList: listOfVariantStock,
              bundleLeastStock: item.bundle_least_stock?.least_stock,
              bundleLeastStockVarId: item.bundle_least_stock?.least_variant_id,
              bundleDiscount: item.bundleDiscount ?? null,
              bundleCustomPrice:
                item.bundleCustomPrice !== '0.00'
                  ? item.bundleCustomPrice
                  : null,
              bundleDiscountFinal: item.bundleDiscount
                ? price - (item.bundleDiscount / 100) * price
                : null,
              sellingVariant:
                item.variants && item.variants.length > 0
                  ? item.variants[0].mostPurchasedItem
                  : 0,
              variantStock: item.bundle_least_stock?.least_stock,
              variantTracking:
                item.bundle_least_stock?.least_stock !== null ? true : false,
              variantUpc: item.bundles ? item.bundles[0].bundleSKU : '',
              variantLocation:
                item.bundles && item.bundles.length > 0
                  ? item.bundles[0].variant.variantLocations
                  : [],
              isLoading: false,
            });
          }
        } else {
          let totalDiscountInPercentage = 0;
          let totalDiscountInDoller = 0;

          if (item && item.itemDiscount && item.itemDiscount.length > 0) {
            for (let index = 0; index < item.itemDiscount.length; index++) {
              if (
                item.itemDiscount[index].discount.discountType.data[0] ===
                DiscountType.Percentage
              ) {
                if (
                  (item.itemDiscount[index].discount.discountFrom &&
                    item.itemDiscount[index].discount.discountTo &&
                    item.itemDiscount[index].discount.applyToAllGoods) ||
                  (item.itemDiscount[index].discount.discountFrom &&
                    item.itemDiscount[index].discount.discountTo &&
                    item.itemDiscount[index].discount.applyToSpecificItems)
                ) {
                  const discountFromDate = new Date(
                    item.itemDiscount[index].discount.discountFrom,
                  );
                  discountFromDate.setHours(0, 0, 1);
                  const discountToDate = new Date(
                    item.itemDiscount[index].discount.discountTo,
                  );
                  discountToDate.setHours(23, 59, 59);
                  const currentDate = new Date();
                  if (
                    currentDate >= discountFromDate &&
                    currentDate <= discountToDate
                  ) {
                    totalDiscountInPercentage =
                      totalDiscountInPercentage +
                      parseFloat(item.itemDiscount[index].discount.value);
                  }
                } else if (
                  item.itemDiscount[index].discount.applyToSpecificItems
                ) {
                  totalDiscountInPercentage =
                    totalDiscountInPercentage +
                    parseFloat(item.itemDiscount[index].discount.value);
                } else if (item.itemDiscount[index].discount.applyToAllGoods) {
                  totalDiscountInPercentage =
                    totalDiscountInPercentage +
                    parseFloat(item.itemDiscount[index].discount.value);
                }
              } else {
                if (
                  (item.itemDiscount[index].discount.discountFrom &&
                    item.itemDiscount[index].discount.discountTo &&
                    item.itemDiscount[index].discount.applyToAllGoods) ||
                  (item.itemDiscount[index].discount.discountFrom &&
                    item.itemDiscount[index].discount.discountTo &&
                    item.itemDiscount[index].discount.applyToSpecificItems)
                ) {
                  const discountFromDate = new Date(
                    item.itemDiscount[index].discount.discountFrom,
                  );
                  discountFromDate.setHours(0, 0, 1);
                  const discountToDate = new Date(
                    item.itemDiscount[index].discount.discountTo,
                  );
                  discountToDate.setHours(23, 59, 59);
                  const currentDate = new Date();
                  if (
                    currentDate >= discountFromDate &&
                    currentDate <= discountToDate
                  ) {
                    totalDiscountInDoller =
                      totalDiscountInDoller +
                      parseFloat(item.itemDiscount[index].discount.value);
                  }
                } else if (
                  item.itemDiscount[index].discount.applyToSpecificItems
                ) {
                  totalDiscountInDoller =
                    totalDiscountInDoller +
                    parseFloat(item.itemDiscount[index].discount.value);
                } else if (item.itemDiscount[index].discount.applyToAllGoods) {
                  totalDiscountInDoller =
                    totalDiscountInDoller +
                    parseFloat(item.itemDiscount[index].discount.value);
                }
              }
            }
          }
          if (item.variants) {
            item.variants.forEach((variant: any) => {
              // code for excluding out of stock items
              const isVariantTrackingEnable =
                variant.variantLocations.length > 0
                  ? variant.variantLocations[0].isInventoryTrackingEnabled
                  : false;

              if (!isVariantTrackingEnable) {
                variant.variantLocations[0].currentStock =
                  Number.POSITIVE_INFINITY;
              }
              if (this.filterByInStock) {
                const inStock =
                  variant.variantLocations.length > 0
                    ? variant.variantLocations[0].currentStock > 0
                      ? true
                      : false
                    : false;
                if (inStock || item.itemType === 2) {
                  const variantIdExist = this.selectableItems.some(
                    (item) => item.variantId === variant.id,
                  );

                  if (!variantIdExist) {
                    this.selectableItems.push({
                      discountSelection: true,
                      variantId: variant.id!,
                      variantName: variant.name,
                      variantSku: variant.sku,
                      itemHasImage: item.hasImage,
                      itemImageSrc: item.imageSrc,
                      variantPrice: variant.price,
                      variantWholesalePrice: Number(variant.wholesalePrice),
                      variantTotalWholesalePrice: Number(
                        variant.wholesalePrice,
                      ),
                      variantQuantity: 0,
                      itemDiscount: 0,
                      itemName: item.name,
                      itemType: item.itemType,
                      itemDescription: item.description,
                      itemManufacturer: item.manufacturer,
                      itemTaxes: item.taxes!,
                      itemId: item.id,
                      variantStock:
                        variant.variantLocations.length > 0
                          ? variant.variantLocations[0].currentStock
                          : 0,
                      variantTracking:
                        variant.variantLocations.length > 0
                          ? variant.variantLocations[0]
                              .isInventoryTrackingEnabled
                          : false,
                      variantUpc: variant.upc,
                      sellingVariant: variant.mostPurchasedItem,
                      variantLocation: variant.variantLocations,
                      variantDiscountedPrice:
                        variant.price * (totalDiscountInPercentage / 100) +
                        totalDiscountInDoller,
                      isLoading: false,
                    });
                  }
                }
              } else {
                const variantIdExist = this.selectableItems.some(
                  (item) => item.variantId === variant.id,
                );
                if (!variantIdExist) {
                  this.selectableItems.push({
                    discountSelection: true,
                    variantId: variant.id!,
                    variantName: variant.name,
                    variantSku: variant.sku,
                    itemHasImage: item.hasImage,
                    itemImageSrc: item.imageSrc,
                    variantPrice: variant.price,
                    variantWholesalePrice: Number(variant.wholesalePrice),
                    variantTotalWholesalePrice: Number(variant.wholesalePrice),
                    variantQuantity: 0,
                    itemDiscount: 0,
                    itemName: item.name,
                    itemType: item.itemType,
                    itemDescription: item.description,
                    itemManufacturer: item.manufacturer,
                    itemTaxes: item.taxes!,
                    itemId: item.id,
                    variantStock:
                      variant.variantLocations.length > 0
                        ? variant.variantLocations[0].currentStock
                        : 0,
                    variantTracking:
                      variant.variantLocations.length > 0
                        ? variant.variantLocations[0].isInventoryTrackingEnabled
                        : false,
                    variantUpc: variant.upc,
                    sellingVariant: variant.mostPurchasedItem,
                    variantLocation: variant.variantLocations,
                    variantDiscountedPrice:
                      variant.price * (totalDiscountInPercentage / 100) +
                      totalDiscountInDoller,
                    isLoading: false,
                  });
                }
              }
            });
          }
        }
      });
      this.filteredItems = this.selectableItems;
    });
  }

  productCategoryEvent(category) {
    this.filteredItems = [];
    this.selectedCategoryId = category.id;
    this.selectedCategoryName = category.name;
    this.changedValue();
    this.openCategorySlide = true;
  }

  backToMain() {
    this.selectedCategoryId = 0;
    this.filteredItems = [];
    this.selectedCategoryName = '';
    this.changedValue();
    this.openCategorySlide = false;
  }

  parseOrderRequestForBundles(
    newOrder: CreateOrderRequest,
  ): CreateOrderRequest {
    const bundleVariants: ItemVariantFlattend[] = [];
    const bundles = newOrder.variantSnapshots.filter(
      (variantSnapshot: ItemVariantFlattend) => {
        return variantSnapshot.itemType === ItemType.Bundle;
      },
    );
    const variantSnapshots = newOrder.variantSnapshots
      .filter((variantSnapshot: ItemVariantFlattend) => {
        return variantSnapshot.itemType !== ItemType.Bundle;
      })
      .map((variantSnapshot: ItemVariantFlattend) => {
        return {
          ...variantSnapshot,
          variantSku:
            variantSnapshot.variantSku === null
              ? ''
              : variantSnapshot.variantSku,
          variantUpc:
            variantSnapshot.variantUpc === null
              ? ''
              : variantSnapshot.variantUpc,
        };
      });

    // Parse bundles to send request for endpoint
    bundles.forEach((variantSnapshot: ItemVariantFlattend) => {
      let firstItem = true;
      variantSnapshot.bundles.forEach((bundle: any) => {
        let variantWholesalePrice = bundle.variant.wholesalePrice;
        let variantTotalWholesalePrice = bundle.variant.wholesalePrice;

        if (variantSnapshot.bundleCustomPrice) {
          if (variantSnapshot.bundleCustomPrice && firstItem) {
            const val =
              Math.trunc(
                (variantSnapshot.bundleCustomPrice /
                  variantSnapshot.bundles.length) *
                  100,
              ) / 100;
            const finalVal =
              variantSnapshot.bundleCustomPrice -
              val * (variantSnapshot.bundles.length - 1);
            this.updatedPrice = finalVal;
            variantWholesalePrice = 0;
            variantTotalWholesalePrice = 0;
          } else {
            this.updatedPrice =
              Math.trunc(
                (variantSnapshot.bundleCustomPrice /
                  variantSnapshot.bundles.length) *
                  100,
              ) / 100;
            variantWholesalePrice = 0;
            variantTotalWholesalePrice = 0;
          }
        }
        if (variantSnapshot.bundleDiscountFinal) {
          if (variantSnapshot.bundleDiscountFinal && firstItem) {
            const val =
              Math.trunc(
                (variantSnapshot.bundleDiscountFinal /
                  variantSnapshot.bundles.length) *
                  100,
              ) / 100;
            const finalVal =
              variantSnapshot.bundleDiscountFinal -
              val * (variantSnapshot.bundles.length - 1);
            this.updatedPrice = finalVal;
            variantWholesalePrice = 0;
            variantTotalWholesalePrice = 0;
          } else {
            this.updatedPrice =
              Math.trunc(
                (variantSnapshot.bundleDiscountFinal /
                  variantSnapshot.bundles.length) *
                  100,
              ) / 100;
            variantWholesalePrice = 0;
            variantTotalWholesalePrice = 0;
          }
        }

        const variant = {
          ...variantSnapshot,
          variantId: bundle.variant.id,
          variantName: bundle.variant.name,
          variantPrice:
            variantSnapshot.bundleCustomPrice ||
            variantSnapshot.bundleDiscountFinal
              ? this.updatedPrice
              : bundle.variant.price,
          variantQuantity: variantSnapshot.variantQuantity,
          variantSku: bundle.variant.sku === null ? '' : bundle.variant.sku,
          variantUpc: bundle.variant.upc === null ? '' : bundle.variant.upc,
          variantStock: bundle.variant.variantLocations[0].currentStock,
          variantTracking:
            bundle.variant.variantLocations[0].isInventoryTrackingEnabled,
          variantTotalWholesalePrice,
          variantWholesalePrice,
        };
        bundleVariants.push(variant);
        firstItem = false;
      });
    });

    newOrder.variantSnapshots = bundleVariants.concat(variantSnapshots);
    return newOrder;
  }

  currency(value: any) {
    const parseValue = typeof value === 'undefined' ? 0 : value;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(parseValue);
  }

  get getRemainingItems() {
    return this.$store.getters.getRemainingItems;
  }

  get iFrameUrl() {
    return this.iframeUrl;
  }

  get iFrameUrlError() {
    return this.iframeUrlError;
  }

  get isBrowseDisabled(): boolean {
    return this.$store.getters.selectedLocationId === null;
  }

  get isChargeDisabled(): boolean {
    return this.newOrder.variantSnapshots.length === 0;
  }

  get isMarkAsPaidDisabled(): boolean {
    return this.newOrder.variantSnapshots.length === 0;
  }

  get theOrderId() {
    return this.orderId;
  }

  hideQuoteModel() {
    document.body.style.overflow = 'visible';
    this.showQuote = false;
  }

  get isDiscountDisabled(): boolean {
    return (
      this.newOrder.variantSnapshots.length === 0 || this.isProcessingSavedCard
    );
  }

  /** Function after change dropdown value to set calculations */
  public changeTip(data: any) {
    if (data.item.tipText === true) {
      if (typeof data.item.tipTextType === 'undefined') {
        data.item.tipTextType = 1;
      }
      if (data.item.tipTextType === 2) {
        data.item.tip =
          (data.item.variantPrice *
            data.item.variantQuantity *
            data.item.tipAmount) /
          100;
      } else {
        data.item.tip = 0;
      }
    } else {
      data.item.tip =
        (data.item.variantPrice *
          data.item.variantQuantity *
          data.item.tipText) /
        100;
    }
    this.calcResults.tip = this.getTipTotal();
  }

  /** Function after change tipe type dropdown when select manule value to set calculations */
  public changeTipType(data: any) {
    if (Number(data.item.tipTextType) === 2) {
      if (data.item.tipAmount) {
        data.item.tip =
          (data.item.variantPrice *
            data.item.variantQuantity *
            data.item.tipAmount) /
          100;
      } else {
        data.item.tip = 0;
      }
    } else {
      if (data.item.tipAmount) {
        data.item.tip = parseFloat(data.item.tipAmount);
      } else {
        data.item.tip = 0;
      }
    }
    this.calcResults.tip = this.getTipTotal();
  }

  /** Function to calculate value of tip */
  public getTipTotal() {
    let tip = 0;
    this.newOrder.variantSnapshots.map((item) => {
      tip = tip + item.tip;
      return item;
    });
    return tip;
  }

  /** Function to calculate tip */
  public calculateTip(data: any) {
    if (Number(data.item.tipTextType) == 2) {
      if (data.item.tipAmount) {
        data.item.tip =
          (data.item.variantPrice *
            data.item.variantQuantity *
            parseFloat(data.item.tipAmount)) /
          100;
      } else {
        data.item.tip = 0;
      }
    } else {
      if (data.item.tipAmount) {
        data.item.tip = parseFloat(data.item.tipAmount);
      } else {
        data.item.tip = 0;
      }
    }
    this.calcResults.tip = this.getTipTotal();
    return data.item.tip;
  }

  /** Function to hide import button and dropdown in generate quote modal */
  public importHide() {
    this.variantSnapshots = [];
    if (
      this.newOrder.variantSnapshots.length > 0 &&
      this.calcResults.total > 0
    ) {
      const snapshots = this.newOrder.variantSnapshots;
      this.variantSnapshots = this.parseOrderRequestForBundles(
        this.newOrder,
      ).variantSnapshots;
      this.newOrder.variantSnapshots = snapshots;
      this.showQuote = true;
      this.showimport = false;

      const win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName('body')[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth,
        y = win.innerHeight || docElem.clientHeight || body.clientHeight;
      this.myHeight = '98%';
      if (y >= 1000) {
        this.myHeight = '75%';
      }
      document.body.style.overflow = 'hidden';
    }
  }

  /** Function to get details of selected quoteId **/
  public async changeQuote() {
    if (this.selectedQuoteId === 'load_more') {
      this.loadMoreQuotes();
      this.selectedQuoteId = '';
    } else {
      this.loaderForQuote = true;
      const res = await this.quoteService.getQuoteDetails(
        this.selectedQuoteId,
        this.locationId,
      );
      this.newOrderQuote = {};
      this.newOrderQuote = res.data.quotes;
      this.newOrderQuote.variantSnapshots =
        this.newOrderQuote.variantQuoteSnapshots;
      this.loaderForQuote = false;
    }
  }

  loadMoreQuotes() {
    const filter = {
      locationId: this.locationId,
    };
    this.getQuoteList(filter);
  }

  /** Function to show dropdown and import button on generate quote dropdown */
  public importQuote() {
    this.setQuotesFilter({ page: 0, PageSize: 0, locationId: this.locationId });
    const win = window,
      doc = document,
      docElem = doc.documentElement,
      body = doc.getElementsByTagName('body')[0],
      x = win.innerWidth || docElem.clientWidth || body.clientWidth,
      y = win.innerHeight || docElem.clientHeight || body.clientHeight;
    this.myHeight = '98%';
    if (y >= 1000) {
      this.myHeight = '75%';
    }
    document.body.style.overflow = 'hidden';

    this.showQuote = true;
    this.showimport = true;
    const filter = this.$store.getters.quoteFilter;
    filter.page = 1;
    filter.pageSize = 20;
    filter.locationId = this.locationId;
    this.getQuoteList(filter);
  }

  public bundleListToggle(item) {
    item.bundleOpened = !item.bundleOpened;
  }

  /** Function to assign quote data to current order summery details */
  public async assignNewOrder() {
    this.loaderForQuote = true;
    let itemsAddedToOrder = [];
    const item_import: any = [];

    const data = JSON.parse(this.newOrderQuote?.jsonQuoteImport);
    let results;
    if (data) {
      const promises = data?.variantSnapshots.map(async (i) => {
        const locationId = this.$store.getters.selectedLocationId;
        const itemPromise = await this.itemsService.getItemById(
          i.itemId,
          locationId,
        );
        let isTracking;
        if (
          itemPromise.data.bundles.length > 0 ||
          itemPromise.data.itemType === ItemType.Bundle
        ) {
          isTracking = itemPromise.data.bundles.every(
            (x) => x.variant.variantLocations[0].isInventoryTrackingEnabled,
          );
        } else {
          isTracking = itemPromise.data.variants.every(
            (x) => x.variantLocations[0].isInventoryTrackingEnabled == true,
          );
        }
        if (isTracking) {
          if (itemPromise.data.itemType === ItemType.Good) {
            return itemPromise.data.totalStock <= 0;
          } else if (itemPromise.data.itemType === ItemType.Bundle) {
            return (
              itemPromise.data.bundle_least_stock.least_stock <= 0 &&
              itemPromise.data.bundle_least_stock.hasInventoryTrackingEnabled
            );
          } else if (itemPromise.data.itemType === ItemType.Service) {
            return false;
          }
        } else if (
          !isTracking &&
          itemPromise.data.itemType === ItemType.Bundle
        ) {
          return (
            itemPromise.data.bundle_least_stock.least_stock <= 0 &&
            itemPromise.data.bundle_least_stock.hasInventoryTrackingEnabled
          );
        } else {
          return false;
        }
      });
      results = await Promise.all(promises);
    }
    const outOfStock = results?.includes(true);

    if (outOfStock) {
      this.displayNotification(
        'error',
        `Sorry, the item you've selected for the quote is currently out of stock.`,
        'error',
      );
      this.isProductLoading = false;
      this.showimport = false;
      this.loaderForQuote = false;
      this.showQuote = false;
      document.body.style.overflow = 'visible';

      return;
    } else {
      this.newOrderQuote.variantQuoteSnapshots
        .filter((item) => item?.variant?.isDeleted === false)
        .map((item: any) => {
          item_import.push(item.variantId);
          return item;
        });
      this.locationId =
        this.newOrderQuote.location.id ||
        this.localStorageService.getItem('primary_location_id');
      await this.$store.dispatch(
        'setSelectedLocationId',
        this.newOrderQuote.location.id,
      );
      if (this.newOrderQuote.customer) {
        this.customerId = this.newOrderQuote.customer.id;
        await this.$store.dispatch(
          'setSelectedCustomerId',
          this.newOrderQuote.customer.id,
        );
      }
      this.newOrder.location = this.getSelectedLocation();
      this.payformVisible = false;
      this.tearDownListeners();
      if (this.chargeInitiated) this.resetCharge();
      this.$store.dispatch('setLocationDiscount');
      this.isProductLoading = true;
      this.$store.commit('setItemFilter', {
        page: 0,
        pageSize: 0,
        search: '',
        location: this.$store.getters.selectedLocationId,
        ids: item_import,
      });
      await this.$store.dispatch('getSelectedItems', item_import).then(() => {
        let popupFlag = false;
        this.outOfStockList = [];
        this.$store.getters.selectedItemsById.forEach(async (item: any) => {
          if (item.itemType === ItemType.Bundle) {
            let price = 0;
            await item.bundles.forEach((bundle: any) => {
              price += parseFloat(bundle.variant.price.toString());
            });
            if (item.bundles && item.bundles.length > 0) {
              itemsAddedToOrder.push({
                discountSelection: true,
                variantId: '',
                variantName: '',
                variantSku: item.bundles ? item.bundles[0].bundleSKU : '',
                itemHasImage: item.hasImage,
                itemImageSrc: item.imageSrc,
                variantPrice: price,
                variantQuantity: 0,
                sellingVariant:
                  item.variants && item.variants.length > 0
                    ? item.variants[0].mostPurchasedItem
                    : 0,
                itemDiscount: 0,
                itemName: item.name,
                itemType: item.itemType,
                itemDescription: item.description,
                itemManufacturer: item.manufacturer,
                itemTaxes: item.taxes!,
                itemId: item.id,
                bundles: item.bundles,
                variantStock:
                  item.bundles && item.bundles.length > 0
                    ? item.bundles[0].variant.variantLocations[0].currentStock
                    : 0,
                variantTracking:
                  item.bundle_least_stock?.least_stock !== null ? true : false,
                variantUpc: item.bundles ? item.bundles[0].bundleSKU : '',
                variantLocation:
                  item.bundles && item.bundles.length > 0
                    ? item.bundles[0].variant.variantLocations
                    : [],
                isLoading: false,
              });
            }
          } else {
            let totalDiscountInPercentage = 0;
            let totalDiscountInDoller = 0;
            if (item && item.itemDiscount && item.itemDiscount.length > 0) {
              for (let index = 0; index < item.itemDiscount.length; index++) {
                if (
                  item.itemDiscount[index].discount.discountType.data[0] ===
                  DiscountType.Percentage
                ) {
                  if (
                    (item.itemDiscount[index].discount.discountFrom &&
                      item.itemDiscount[index].discount.discountTo &&
                      item.itemDiscount[index].discount.applyToSpecificItems) ||
                    (item.itemDiscount[index].discount.discountFrom &&
                      item.itemDiscount[index].discount.discountTo &&
                      item.itemDiscount[index].discount.applyToAllGoods)
                  ) {
                    const discountFromDate = new Date(
                      item.itemDiscount[index].discount.discountFrom,
                    );
                    discountFromDate.setHours(0, 0, 1);
                    const discountToDate = new Date(
                      item.itemDiscount[index].discount.discountTo,
                    );
                    discountToDate.setHours(23, 59, 59);
                    const currentDate = new Date();
                    if (
                      currentDate >= discountFromDate &&
                      currentDate <= discountToDate
                    ) {
                      totalDiscountInPercentage =
                        totalDiscountInPercentage +
                        parseFloat(item.itemDiscount[index].discount.value);
                    }
                  } else if (
                    item.itemDiscount[index].discount.applyToSpecificItems
                  ) {
                    totalDiscountInPercentage =
                      totalDiscountInPercentage +
                      parseFloat(item.itemDiscount[index].discount.value);
                  } else if (
                    item.itemDiscount[index].discount.applyToAllGoods
                  ) {
                    totalDiscountInPercentage =
                      totalDiscountInPercentage +
                      parseFloat(item.itemDiscount[index].discount.value);
                  }
                } else {
                  if (
                    (item.itemDiscount[index].discount.discountFrom &&
                      item.itemDiscount[index].discount.discountTo &&
                      item.itemDiscount[index].discount.applyToSpecificItems) ||
                    (item.itemDiscount[index].discount.discountFrom &&
                      item.itemDiscount[index].discount.discountTo &&
                      item.itemDiscount[index].discount.applyToAllGoods)
                  ) {
                    const discountFromDate = new Date(
                      item.itemDiscount[index].discount.discountFrom,
                    );
                    discountFromDate.setHours(0, 0, 1);
                    const discountToDate = new Date(
                      item.itemDiscount[index].discount.discountTo,
                    );
                    discountToDate.setHours(23, 59, 59);
                    const currentDate = new Date();
                    if (
                      currentDate >= discountFromDate &&
                      currentDate <= discountToDate
                    ) {
                      totalDiscountInDoller =
                        totalDiscountInDoller +
                        parseFloat(item.itemDiscount[index].discount.value);
                    }
                  } else if (
                    item.itemDiscount[index].discount.applyToSpecificItems
                  ) {
                    totalDiscountInDoller =
                      totalDiscountInDoller +
                      parseFloat(item.itemDiscount[index].discount.value);
                  } else if (
                    item.itemDiscount[index].discount.applyToAllGoods
                  ) {
                    totalDiscountInDoller =
                      totalDiscountInDoller +
                      parseFloat(item.itemDiscount[index].discount.value);
                  }
                }
              }
            }
            if (item.variants) {
              item.variants.forEach((variant: any) => {
                itemsAddedToOrder.push({
                  discountSelection: true,
                  variantId: variant.id!,
                  variantName: variant.name,
                  variantSku: variant.sku,
                  itemHasImage: item.hasImage,
                  itemImageSrc: item.imageSrc,
                  variantPrice: variant.price,
                  variantQuantity: 0,
                  itemDiscount: 0,
                  itemName: item.name,
                  itemType: item.itemType,
                  itemDescription: item.description,
                  itemManufacturer: item.manufacturer,
                  itemTaxes: item.taxes!,
                  itemId: item.id,
                  variantStock: variant.variantLocations[0].currentStock,
                  variantTracking:
                    variant.variantLocations[0].isInventoryTrackingEnabled,
                  variantUpc: variant.upc,
                  variantLocation: variant.variantLocations,
                  sellingVariant: variant.mostPurchasedItem,
                  variantDiscountedPrice:
                    variant.price * (totalDiscountInPercentage / 100) +
                    totalDiscountInDoller,
                  isLoading: false,
                });
              });
            }
          }
        });
        itemsAddedToOrder.map((itemm: any) => {
          if (item_import.includes(itemm.variantId)) {
            this.newOrderQuote.variantQuoteSnapshots.map((quoteItem: any) => {
              if (itemm.variantId === quoteItem.variantId) {
                itemm.variantQuantity = quoteItem.variantQuantity;
                itemm.variantPrice =
                  itemm.variantPrice - itemm.variantDiscountedPrice;
                itemm.itemImageSrc = itemm.itemHasImage
                  ? itemm.itemImageSrc
                  : '/image_placeholder.png';
                for (let i = 0; i < itemm.variantLocation.length; i++) {
                  if (
                    itemm.variantLocation[i].location.id === this.locationId
                  ) {
                    if (
                      (itemm.itemType === ItemType.Good ||
                        itemm.itemType === ItemType.Bundle) &&
                      itemm.variantLocation[i].currentStock <
                        itemm.variantQuantity &&
                      itemm.variantLocation[i].isInventoryTrackingEnabled
                    ) {
                      this.outOfStockList.push(
                        itemm.itemManufacturer +
                          ' ' +
                          itemm.itemName +
                          ' ' +
                          itemm.variantName,
                      );
                      itemm.outOfStock = true;
                      popupFlag = true;
                      this.newvariantlist.push(itemm);
                    } else {
                      itemm.outOfStock = false;
                      this.newvariantlist.push(itemm);
                    }
                  }
                }

                this.isProductLoading = false;
                this.showimport = false;
                this.loaderForQuote = false;
                this.showQuote = false;
                document.body.style.overflow = 'visible';
              }
            });
          }
          return itemm;
        });

        if (this.newOrderQuote.jsonQuoteImport != null) {
          this.newOrder = null;
          this.newOrder = JSON.parse(this.newOrderQuote.jsonQuoteImport);
          this.newOrder.variantSnapshots[0].variantDiscountedPrice = 0;
          this.discount =
            this.newOrder.discounts.length && this.newOrder.discounts.length > 0
              ? this.newOrder.discounts[0]
              : null;
          this.showDiscountButton =
            this.newOrder.discounts.length && this.newOrder.discounts.length > 0
              ? false
              : true;
          const variants = JSON.parse(
            this.newOrderQuote.jsonQuoteImport,
          ).variantSnapshots;

          this.updateCalcs();
          this.updateCalcs();

          this.newOrder.variantSnapshots[0].variantQuantity = 0;
          this.newOrder.variantSnapshots[0].variantStock++;

          // Update the quantity when quote is imported, we call twice the updateCalcs to make sure the calculations are correct
          this.newOrder.variantSnapshots.forEach((variant) => {
            if (variant.itemType === ItemType.Bundle) {
              const variantSnapshot = variants.find(
                (snapshot) => snapshot.itemId === variant.itemId,
              );

              if (variantSnapshot) {
                for (
                  let index = 0;
                  index < variantSnapshot.variantQuantity;
                  index++
                ) {
                  this.addProductItem(variant, 'plus');
                }
              }
            } else {
              const variantSnapshot = variants.find(
                (snapshot) => snapshot.variantId === variant.variantId,
              );

              if (variantSnapshot) {
                for (
                  let index = 0;
                  index < variantSnapshot.variantQuantity;
                  index++
                ) {
                  this.addProductItem(variant, 'importQuote');
                }
              }
            }
          });
        }

        if (popupFlag) {
          this.isAddToPopupBlock = true;
        }
      });

      this.newOrder.id = v4();
    }
  }

  getQuoteList(filter) {
    this.$store.dispatch('setQuotesFilter', filter).then(() => {
      this.$store.dispatch('getQuotesList').then(() => {
        this.quotesListCount =
          this.$store.getters.allQuotes_List[0]?.totalCount;
        this.quotes = [];
        for (let i = 0; i < this.$store.getters.allQuotes_List.length; i++) {
          const data = this.$store.getters.allQuotes_List[i];
          if (this.locationId == data.locationId || this.locationId == null) {
            this.quotes.push({
              text: data.name + '     ' + data.total,
              value: data.id,
            });
          }
        }
        this.quotes = sortBy(this.quotes, (o: any) => o.text);
      });
    });
  }

  /** function to show popover for tip functionality */
  public showtip() {
    document.body.style.overflow = 'hidden';
    this.popover_tip = true;
    this.myHeight = '75%';
  }

  /** function to hide popover for tip functionality */
  public hidetip() {
    this.calcResults.tip = this.getTipTotal();
    this.updateCalcs();
    this.popover_tip = false;
    document.body.style.overflow = 'visible';
  }

  public getPrice(price: any) {
    const parseValue = typeof price === 'undefined' ? 0 : price;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(parseValue);
  }

  public getcurrentdate() {
    return new Date().toJSON().slice(0, 10).replace(/-/g, '/');
  }

  public getCost(quantity: any, price: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(parseFloat(quantity) * parseFloat(price));
  }

  /** function to generate pdf by clicking on download button */
  public download_pdf() {
    this.iframeshow = true;
    this.newOrder.isBack = true;
    this.iframeshowurl =
      window.location.origin +
      '/quote/pdf?data=' +
      window.btoa(JSON.stringify(this.newOrder));
  }

  setQuotesFilter(filter: ApiFilter) {
    this.$store.commit('setQuotesFilter', filter);
  }

  async mounted() {
    const locationId = this.localStorageService.getItem('primary_location_id');
    const result = await this.complimentaryService.getAllComplementaryReasons(
      locationId,
      this.pages,
      this.pageCount,
    );

    this.compOptions = result.data.complimentaryReasons;
    this.loading = true;
    this.$store.dispatch('getLocationList').then(async () => {
      this.url_parameters.customerId = this.$route.query.customerId;
      this.url_parameters.locationId = this.$route.query.locationId;
      if (this.url_parameters.customerId && this.url_parameters.locationId) {
        const contact = await this.contactService.getContactById(
          this.url_parameters.customerId,
          this.url_parameters.locationId,
        );
        this.getSelectedCustomer(contact.data.customer);
      }
      this.URLcustomer = this.url_parameters.customerId;
      this.tearDownListeners();
      this.roleService.getRole();
      this.calcResults.subtotal = 0;
      this.calcResults.total = 0;
      this.calcResults.tax = 0;
      this.calcResults.tip = 0;
      this.calcResults.discountAmount = 0;
      this.newOrder.transactionType = TransactionType.Sale;
      this.newOrder.discountApplied = true;
      this.newOrder.product_transaction_id = null;
      this.locations = this.$store.getters.allLocationList;
      this.setLocationOptions();
      this.loading = false;
      this.disableLocationField();
      if (this.roleService.isPatient) {
        const locations = this.localStorageService.getItem('locations');
        if (locations && locations.length) {
          this.$store.dispatch(
            'setSelectedLocationId',
            JSON.parse(locations)[0],
          );

          this.newOrder.location = this.getSelectedLocation();
        }
      }
    });

    const itemsScroll = document.getElementById('itemsContainer');
    itemsScroll.addEventListener('scroll', this.handleScroll);
  }
  /** scrolling issue here */
  handleScroll() {
    const itemsScroll = document.getElementById('itemsContainer');
    
    if (
      Math.ceil(itemsScroll.scrollTop + itemsScroll.clientHeight) >=
      itemsScroll.scrollHeight
    ) {
      if (this.getRemainingItems > 0) {
        this.nextItems();
      }
    }
  }

  enterEvent(e: any) {
    const value = e.target.value;

    if (this.$store.getters.selectedLocationId && !this.roleService.isPatient) {
      if (this.$route.name !== 'items') {
        if (e.key !== 'Enter' && !isNaN(parseInt(e.key))) {
          this.barcodeValue = value + '' + e.key;
        } else if (
          e.key === 'Enter' &&
          value.length <= 13 &&
          value.length >= 12 &&
          !isNaN(Number(value))
        ) {
          this.barcodeSearch(value);
          this.barcodeValue = '';
        } else if (e.key === 'Enter') {
          this.barcodeValue = '';
        }
      }
    }
  }

  public getuuid() {
    if (this.showimport) {
      return this.newOrderQuote.id;
    } else if (this.newOrder.id && this.newOrder.id !== '') {
      return this.newOrder.id;
    } else {
      this.newOrder.id = v4();
      return this.newOrder.id;
    }
  }

  async downloadPdf() {
    if (this.showimport) {
      await html2pdf()
        .set({
          margin: 0.1,
          mode: 'legacy',
          filename: this.getuuid() + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(document.getElementById('element-to-show'))
        .save();
    }
    if (!this.showimport) {
      await html2pdf()
        .set({
          margin: 0.1,
          mode: 'legacy',
          filename: this.getuuid() + '.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(document.getElementById('element-to-print'))
        .save();
    }
  }

  getDueDate() {
    const dueDateFromOrder = this.newOrder?.dueDate
      ? this.newOrder?.dueDate
      : '';
    const dueDateFromQuote = this.newOrderQuote?.dueDate
      ? this.newOrderQuote?.dueDate
      : '';

    if (dueDateFromOrder) {
      return parseDueDate(dueDateFromOrder);
    }

    if (dueDateFromQuote) {
      return parseDueDate(dueDateFromQuote);
    }

    return '';
  }

  setSelectedDueDate(dueDate: string) {
    this.newOrder.dueDate = dueDate;
    this.newOrderQuote.dueDate = dueDate;
  }

  async saveQuote(quoteName, selectedDueDate, selectedCustomer) {
    let apiResponse;
    this.loaderForQuote = true;
    this.showimport = true;

    if (!selectedDueDate) {
      this.alertTitle = 'Alert';
      this.alertMessage = 'Due Date is required.';
      this.showConfirm = true;
      this.loaderForQuote = false;
      this.showimport = false;
      return;
    }
    if (selectedDueDate) {
      const currentDate = new Date();
      const selectedDate = new Date(selectedDueDate);
      const timeDifference = selectedDate.getTime() - currentDate.getTime();
      const differenceInDays = Math.floor(
        timeDifference / (1000 * 60 * 60 * 24),
      );

      if (!(differenceInDays >= 2)) {
        this.alertTitle = 'Alert';
        this.alertMessage =
          'Due Date Should be greater than 2 days from the current Date';
        this.showConfirm = true;
        this.loaderForQuote = false;
        this.showimport = false;
        return;
      }
    }

    this.newOrder.quote_name = quoteName;

    if (this.newOrder.quote_name.trim() === '') {
      this.alertTitle = 'Alert';
      this.alertMessage = 'Please enter a valid Quote Name.';
      this.showConfirm = true;
      return;
    }

    this.newOrder.customer = selectedCustomer;
    this.newOrder.customerId = selectedCustomer.id;
    this.newOrder.isPaid = true;
    this.newOrder.location = this.getSelectedLocation();
    this.newOrder.paymentType = 1;
    this.newOrder.total = this.calcResults.total;
    this.newOrder.dueDate = selectedDueDate;

    const variantSnapshots = this.newOrder.variantSnapshots;
    const jsonQuoteImport = JSON.stringify(this.newOrder);
    this.parseOrderRequestForBundles(this.newOrder);
    this.newOrder.jsonQuoteImport = jsonQuoteImport;

    try {
      this.newOrder.id = v4();

      apiResponse = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/quote`,
        this.newOrder,
      );
      this.newOrder.variantSnapshots = variantSnapshots;
      this.newOrderQuote = {};

      this.newOrder.id = apiResponse.data.quote.quote.id;
      this.getSelectedCustomer(apiResponse.data.quote.quote.customer);

      this.createNewQuote = false;
    } catch (err) {
      apiResponse = err.response;
      if (apiResponse.status === 409) {
        this.showOrderConflictMessage();
      }
    } finally {
      if (apiResponse.status === 200 && apiResponse.data.quote !== undefined) {
        this.markedAsPaid = true;
        this.alertTitle = 'Success!';
        this.alertMessage = 'Quote added successfully.';
        this.showConfirm = true;
      }
      this.loaderForQuote = false;
      this.showimport = false;
    }
  }

  public tearDownListeners() {
    window.removeEventListener('message', this.payformListener);
  }

  public editContact() {
    const contactId = this.url_parameters.customerId.toString();
    router.push(`/contacts/${contactId}`);
  }

  public updateCalcs() {
    let count = 0;
    this.newOrder.variantSnapshots.map((item) => {
      if (item.discountSelection) {
        count++;
      }
    });
    this.newOrder.discountApplied =
      count === this.newOrder.variantSnapshots.length;
    this.calcResults = this.calcService.calculateOrder(this.newOrder);
  }

  public async changeLocation() {
    this.localStorageService.store('primary_location_id', this.locationId);
    this.selectedQuoteId = '';
    this.$store.commit('resetItemsFilter');
    this.filterByGoods = false;
    this.filterByServices = false;
    this.filterByBundle = false;
    this.filterByToppick = false;
    this.filterByInStock = false;
    const vm = this;
    await this.$store
      .dispatch('setSelectedLocationId', this.locationId)
      .then(() => {
        this.$store.dispatch('getTaxesByLocation', this.locationId);
        // IVI-226: Filter selected location object to check if is_product_accountvault is true
        const locationObj = this.locations.filter(
          (location) => location.id === this.locationId,
        );
        if (locationObj) {
          this.is_product_accountvault = locationObj[0].is_product_accountvault;
        }

        this.calcResults.subtotal = 0;
        this.calcResults.total = 0;
        this.calcResults.tax = 0;
        this.calcResults.discountAmount = 0;
        this.newOrder.variantSnapshots = [];
        this.isSuperadminLoc = false;
        this.newOrder.location = this.getSelectedLocation();
        if (!this.url_parameters.customerId) this.clearCustomer();
        const locationOfSuperadmin =
          this.localStorageService.getItem('locations');
        if (
          locationOfSuperadmin &&
          locationOfSuperadmin.slice(2, -2) !==
            this.$store.getters.selectedLocationId &&
          this.roleService.isSuperadmin
        ) {
          this.isSuperadminLoc = true;
        } else if (this.roleService.isSupport) {
          this.isSuperadminLoc = true;
        }
        this.payformVisible = false;
        this.tearDownListeners();
        if (this.chargeInitiated) this.resetCharge();
        this.$store.dispatch('setSelectedCustomerId', null);
        this.$store.dispatch('setLocationDiscount').then(() => {
          this.setDepositeOptions();
        });
        this.show_payment_option = false;
        for (let i = this.newOrder.variantSnapshots.length - 1; i >= 0; i--) {
          this.newOrder.variantSnapshots.splice(i, 1);
          this.resetCharge();
          this.updateCalcs();
          this.$emit('remove-from-order', this.newOrder.variantSnapshots[i]);
        }
        this.selectedCol = null;
        const focusHolder: any = vm.$refs.focusHolder;
        focusHolder.focus();
        this.removeDiscount();

        this.searchTerm = '';
        this.filteredItems = [];
        this.page = 1;
        this.findItems();
      });
    this.newOrder.quote_name = '';
    this.newOrder.id = '';
    this.newOrderQuote = {};
    this.selectedQuoteId = '';
    this.quotes = [];
    const locationId = this.localStorageService.getItem('primary_location_id');
    const result = await this.complimentaryService.getAllComplementaryReasons(
      locationId,
      this.pages,
      this.pageCount,
    );

    this.compOptions = result.data.complimentaryReasons;
  }

  setLocationsDiscount() {
    this.$store.dispatch('setLocationDiscount').then(() => {
      this.setDepositeOptions();
    });
  }

  async confirmSelectedComp() {
    this.newOrder.variantSnapshots.forEach(async (element) => {
      if (element.variantId === this.selectedItemMenu.variantId) {
        element.complementaryReasonId = this.selectedReason?.id;
        element.complementaryReason = this.selectedReason?.reason;
        element.isCompItem = true;
        element.isOthersOptions = this.compReason === 'Others';
        if (element.bundleCustomPrice) {
          element.actualWithoutCompOff =
            this.selectedItemMenu.bundleCustomPrice;
          this.updateCalcs();
        } else {
          element.actualWithoutCompOff = this.selectedItemMenu.variantPrice;
          this.updateCalcs();
        }
        if (this.compReason !== 'Others' && this.compOptions.length !== 0) {
          this.reasonForComp = '';
        }
        if (this.reasonForComp) {
          element.complementaryReason = this.reasonForComp;
          element.complementaryReasonId =
            this.compReason === 'Others' ? '' : this.selectedReason.id;
        }
        if (this.useLater) {
          element.saveComplimentaryReason = true;
          element.complementaryReason = this.reasonForComp;
          const locationId = this.localStorageService.getItem(
            'primary_location_id',
          );
          const result =
            await this.complimentaryService.createComplementaryReason(
              locationId,
              { reason: element.complementaryReason },
            );
          if (result.status == 200) {
            this.compOptions.push(result.data.complementaryReason);
            const getResult =
              await this.complimentaryService.getAllComplementaryReasons(
                locationId,
                this.pages,
                this.pageCount,
              );
            this.compOptions = getResult.data.complimentaryReasons;
          }
        }
      }
    });
    let successMessage: string;
    successMessage = 'Success! Item marked as Complimentary';

    if (this.useLater) {
      successMessage += ' and reason saved for use later.';
    }

    this.displayNotification('success', successMessage);

    this.newOrder.variantSnapshots;
    this.showMarkComplimentary = false;
    this.menuOpen = false;
    this.compReason = '';
    this.useLater = false;
    this.reasonForComp = '';
  }

  triggerCompReason() {
    if (typeof this.compReason === 'object' && this.compReason !== null) {
      this.selectedReason = this.compReason as { id: string; reason: string };
    }
  }

  cancelSelectedComp() {
    this.showMarkComplimentary = false;
    this.selectedReason = { id: '', reason: '' };
    this.compReason = '';
    this.reasonForComp = '';
    this.useLater = false;
  }

  toggleMenu(item) {
    this.menuOpen = !this.menuOpen;

    if (item.itemType === ItemType.Bundle) {
      this.selectedMenuItem = item.itemId;
    } else {
      this.selectedMenuItem = item.variantId;
    }

    this.selectedItemMenu = item;
  }

  markComplimentary(item) {
    this.showMarkComplimentary = true;
    const locationId = this.localStorageService.getItem('primary_location_id');
    this.selectedItemMenu = item;
    // Retrive saved reason
    if (item.complementaryReasonId || item.complementaryReason) {
      this.compReason = {
        id: item.complementaryReasonId,
        reason: item.complementaryReason,
        locationId,
      };
    }
    if (
      item.complementaryReasonId == undefined ||
      item.complementaryReasonId === ''
    ) {
      if (item.complementaryReason) {
        this.compReason = 'Others';
        this.reasonForComp = item.complementaryReason;
      }
    }
    this.menuOpen = false;
  }

  clearCustomer() {
    this.customerValue = '';
    this.customerId = null;
    this.selectedCustomer = null;
    this.newOrder.customerId = null;
    this.newOrderQuote.customerId = null;
    delete this.newOrder.customer;
    delete this.newOrderQuote.customer;
  }

  showPopuMe() {
    this.displayNotification(
      'success',
      `<span class="font-weight-bolder">${this.popupProuductName}</span> added to cart`,
    );
  }

  errorTimeout() {
    setTimeout(() => {
      this.showErrorMsg = false;
      this.errorMsg = '';
      this.popupProuductName = undefined;
    }, 5000);
    this.isProductLoading = false;
  }

  showErrorMessage(productName) {
    this.displayNotification(
      'error',
      `Sorry, ${productName} is no longer in stock`,
      'error',
    );
  }

  setLoadingItem(isLoading: boolean, item: any) {
    const findIdx = this.filteredItems.findIndex(
      (fit) => fit.itemId === item.itemId,
    );
    if (findIdx >= 0) {
      this.filteredItems[findIdx].isLoading = isLoading;
    }
  }

  public async addToOrder(
    item: any,
    productName?: string,
    updatedVariant?: any,
  ) {
    if (updatedVariant.itemType !== ItemType.Bundle) {
      this.itemTaxDetails = item.itemTaxes ?? [];
    } else {
      this.itemTaxDetails = updatedVariant.itemTaxes ?? [];
    }
    this.setLoadingItem(true, updatedVariant);
    let isBundleAdded = false;
    item.tip = 0;
    item.tipAmount = 0;
    item.tipText = '';
    item.tipTextType = '';
    this.isProductLoading = true;
    this.newOrder.productName = '';
    this.newOrder.productType = '';
    this.popupProuductName = productName;
    item.discountSelection = true;
    let bundleCheckCounter = 0;
    let idExists;
    this.updatedVariant = { ...updatedVariant };

    // Checking if Ids exists in `itemsInCartForBundle`
    if (updatedVariant) {
      if (updatedVariant.itemType === ItemType.Bundle) {
        idExists = this.itemsInCartForBundle?.some(
          (id) =>
            id.bundleLeastStockVarId == updatedVariant.bundleLeastStockVarId ||
            id.variantId == updatedVariant.bundleLeastStockVarId,
        );
      } else if (updatedVariant.itemType === ItemType.Good) {
        idExists = this.itemsInCartForBundle?.some(
          (id) => id.bundleLeastStockVarId == updatedVariant.variantId,
        );
      }
      if (!idExists && updatedVariant.itemType !== ItemType.Service) {
        this.itemsInCartForBundle.push(updatedVariant);
      }

      for (const i of this.itemsInCartForBundle) {
        if (
          updatedVariant.itemType === ItemType.Good &&
          updatedVariant.variantStock <= 0 &&
          i.bundleCurrentStock == 0
        ) {
          this.showErrorMessage(productName);
          this.isProductLoading = false;
          return;
        } else if (
          (i.bundleLeastStockVarId == updatedVariant.bundleLeastStockVarId ||
            i.variantId == updatedVariant.bundleLeastStockVarId) &&
          updatedVariant.variantStock == 0 &&
          updatedVariant.itemType == ItemType.Bundle &&
          i.variantStock <= 0 &&
          i.variantTracking === true
        ) {
          this.showErrorMessage(productName);
          this.isProductLoading = false;
          return;
        }
      }
    }
    // Reseting `bundleCurrentStock` in itemsInCartForBundle
    if (updatedVariant) {
      this.itemsInCartForBundle.forEach((i: any) => {
        if (i.itemType === ItemType.Bundle) {
          if (
            (i.bundleLeastStockVarId == updatedVariant.bundleLeastStockVarId &&
              updatedVariant.variantStock == 0) ||
            updatedVariant.bundleCurrentStock === 0
          ) {
            i.bundleCurrentStock = 0;
          }
        }
        if (i.itemType === ItemType.Good) {
          if (updatedVariant.variantStock == 0) {
            i.bundleCurrentStock = 0;
          }
        }
      });
    }

    for (const i of item.length ? item : [item]) {
      const itemId = i.itemId;
      const locationId = this.$store.getters.selectedLocationId;
      const itemPromise = await this.itemsService.getItemById(
        itemId,
        locationId,
      );
      if (!item.itemType) {
        const isOutOfStock = itemPromise.data.variants.every((variant) =>
          variant.variantLocations.every(
            (location) =>
              location.currentStock < 1 || !location.isInventoryTrackingEnabled,
          ),
        );
        if (isOutOfStock) {
          bundleCheckCounter++;
        }
        if (bundleCheckCounter == item.length && item.variantTracking) {
          this.showErrorMessage(productName);
          await this.findItems();
          this.isProductLoading = false;
          return;
        }
      } else if (
        itemPromise.data.variants.every((variant) =>
          variant.variantLocations.every(
            (location) =>
              location.currentStock < 1 && location.isInventoryTrackingEnabled,
          ),
        ) &&
        item.itemType === ItemType.Good
      ) {
        this.showErrorMessage(productName);
        this.isProductLoading = false;
        return;
      } else {
        this.showErrorMsg = false;
      }
    }

    //Checking
    if (item && item[0] && item[0].itemType === ItemType.Bundle) {
      const variants: any = [];
      const bundleItems: any = [];
      for (let k = 0; k < item.length; k++) {
        variants.push(item[k].variantId);
      }
      await this.$store.dispatch('getSelectedItems', variants);
      this.$store.getters.selectedItemsById.map((item: any) => {
        let totalDiscountInPercentage = 0;
        let totalDiscountInDoller = 0;
        if (item && item.itemDiscount && item.itemDiscount.length > 0) {
          for (let index = 0; index < item.itemDiscount.length; index++) {
            if (
              item.itemDiscount[index].discount.discountType.data[0] ===
              DiscountType.Percentage
            ) {
              if (
                (item.itemDiscount[index].discount.discountFrom &&
                  item.itemDiscount[index].discount.discountTo &&
                  item.itemDiscount[index].discount.applyToAllGoods) ||
                (item.itemDiscount[index].discount.discountFrom &&
                  item.itemDiscount[index].discount.discountTo &&
                  item.itemDiscount[index].discount.applyToSpecificItems)
              ) {
                const discountFromDate = new Date(
                  item.itemDiscount[index].discount.discountFrom,
                );
                discountFromDate.setHours(0, 0, 1);
                const discountToDate = new Date(
                  item.itemDiscount[index].discount.discountTo,
                );
                discountToDate.setHours(23, 59, 59);
                const currentDate = new Date();
                if (
                  currentDate >= discountFromDate &&
                  currentDate <= discountToDate
                ) {
                  totalDiscountInPercentage =
                    totalDiscountInPercentage +
                    parseFloat(item.itemDiscount[index].discount.value);
                }
              } else if (
                item.itemDiscount[index].discount.applyToSpecificItems
              ) {
                totalDiscountInPercentage =
                  totalDiscountInPercentage +
                  parseFloat(item.itemDiscount[index].discount.value);
              } else if (item.itemDiscount[index].discount.applyToAllGoods) {
                totalDiscountInPercentage =
                  totalDiscountInPercentage +
                  parseFloat(item.itemDiscount[index].discount.value);
              }
            } else {
              if (
                (item.itemDiscount[index].discount.discountFrom &&
                  item.itemDiscount[index].discount.discountTo &&
                  item.itemDiscount[index].discount.applyToAllGoods) ||
                (item.itemDiscount[index].discount.discountFrom &&
                  item.itemDiscount[index].discount.discountTo &&
                  item.itemDiscount[index].discount.applyToSpecificItems)
              ) {
                const discountFromDate = new Date(
                  item.itemDiscount[index].discount.discountFrom,
                );
                discountFromDate.setHours(0, 0, 1);
                const discountToDate = new Date(
                  item.itemDiscount[index].discount.discountTo,
                );
                discountToDate.setHours(23, 59, 59);
                const currentDate = new Date();
                if (
                  currentDate >= discountFromDate &&
                  currentDate <= discountToDate
                ) {
                  totalDiscountInDoller =
                    totalDiscountInDoller +
                    parseFloat(item.itemDiscount[index].discount.value);
                }
              } else if (
                item.itemDiscount[index].discount.applyToSpecificItems
              ) {
                totalDiscountInDoller =
                  totalDiscountInDoller +
                  parseFloat(item.itemDiscount[index].discount.value);
              } else if (item.itemDiscount[index].discount.applyToAllGoods) {
                totalDiscountInDoller =
                  totalDiscountInDoller +
                  parseFloat(item.itemDiscount[index].discount.value);
              }
            }
          }
        }
        if (item.variants) {
          item.variants.forEach((variant: any) => {
            bundleItems.push({
              discountSelection: true,
              variantId: variant.id!,
              variantName: variant.name,
              variantSku: variant.sku,
              fromBundle: true,
              productName: productName,
              itemHasImage: item.hasImage,
              itemImageSrc: item.imageSrc,
              variantPrice: variant.price,
              variantQuantity: 0,
              itemDiscount: 0,
              itemName: item.name,
              itemType: item.itemType,
              itemDescription: item.description,
              itemManufacturer: item.manufacturer,
              itemTaxes: item.taxes!,
              itemId: item.id,
              variantStock:
                variant.variantLocations.length > 0
                  ? variant.variantLocations[0].currentStock
                  : 0,
              variantTracking:
                variant.variantLocations.length > 0
                  ? variant.variantLocations[0].isInventoryTrackingEnabled
                  : false,
              variantUpc: variant.upc,
              sellingVariant: variant.mostPurchasedItem,
              variantLocation: variant.variantLocations,
              variantDiscountedPrice:
                variant.price * (totalDiscountInPercentage / 100) +
                totalDiscountInDoller,
              isLoading: false,
            });
          });
        }
      });
      this.outOfStockList = [];
      let popupFlag = true;
      this.newvariantlist = [];
      this.bundleCount = 0;
      const isAdded1 = this.checkItemExist(updatedVariant);
      item.map((item: any) => {
        for (let i = 0; i < bundleItems.length; i++) {
          if (item.variantId === bundleItems[i].variantId) {
            const new_item: any = bundleItems[i];
            if (this.chargeInitiated) this.resetCharge();
            if (!isAdded1) {
              const itemModified = JSON.parse(JSON.stringify(new_item));
              itemModified.itemImageSrc = new_item.itemHasImage
                ? new_item.itemImageSrc
                : '/image_placeholder.png';
              if (new_item.variantDiscountedPrice) {
                itemModified.variantPrice =
                  new_item.variantPrice - new_item.variantDiscountedPrice;
              }
              for (let i = 0; i < itemModified.variantLocation.length; i++) {
                const variant = itemModified.variantLocation[i];
                if (variant.location.id === this.locationId) {
                  this.bundleCount++;
                  if (
                    variant.currentStock <= 0 &&
                    variant.isInventoryTrackingEnabled
                  ) {
                    this.outOfStockList.push(
                      itemModified.itemManufacturer +
                        ' ' +
                        itemModified.itemName +
                        ' ' +
                        itemModified.variantName,
                    );
                    popupFlag = false;
                    itemModified.outOfStock = true;
                    this.newvariantlist.push(itemModified);
                  } else {
                    itemModified.outOfStock = false;
                    this.newvariantlist.push(itemModified);
                  }
                }
              }
              if (!popupFlag) {
                this.isAddToPopupBlock = true;
              }
            } else {
              isBundleAdded = true;
            }
          }
        }
        return item;
      });
      if (popupFlag) {
        this.showPopuMe();
        if (!isBundleAdded && this.newvariantlist.length === 0) {
          this.displayNotification(
            'error',
            'This item cannot be added due to some technical error',
            'error',
          );
          this.popupProuductName = undefined;
        }

        const found = this.newOrder.variantSnapshots?.some(
          (snapshot) => snapshot.itemId === updatedVariant.itemId,
        );

        if (!found) {
          // Push Bundle in `newOrder.variantSnapshots` that displays items in Order Summary
          this.newOrder.variantSnapshots.push(updatedVariant);
          this.updateCalcs();
        }
        /*
         * `updateQuantity` needs to be called after pushing the bundle into `newOrder.variantSnapshots`
         * so we can update quantity and stock of added item in the Order summary list
         */
        this.updateQuantity(updatedVariant, 'plus');
      }
    } else {
      const isAdded = this.checkItemExist(item);
      this.showPopuMe();
      if (this.chargeInitiated) this.resetCharge();
      // Checking if item exists in `newOrder.variantSnapshots` (Order Summary)
      if (!isAdded) {
        item.variantTotalWholesalePrice =
          item.variantWholesalePrice * item.variantQuantity;

        // Set initial values for discounts
        // Push Item in `newOrder.variantSnapshots`
        this.newOrder.variantSnapshots.push({
          ...item,
          variantId: updatedVariant.variantId,
          variantMaxStock: updatedVariant.variantMaxStock,
          variantQuantity: updatedVariant.variantQuantity,
          variantStock: updatedVariant.variantStock,
          isLoading: updatedVariant.isLoading,
          discountId: updatedVariant.discountId,
        });
        this.updateCalcs();
      }
      /*
       * `updateQuantity` needs to be called after the if because is already added into
       * `newOrder.variantSnapshots` and we can update added item quantity in the Order summary list
       */
      this.updateQuantity(
        {
          ...item,
          variantId: updatedVariant.variantId,
          discountSelection: false,
          itemDiscount: 0,
          variantMaxStock: updatedVariant.variantMaxStock,
          variantQuantity: updatedVariant.variantQuantity,
          variantStock: updatedVariant.variantStock,
          isLoading: updatedVariant.isLoading,
          discountId: updatedVariant.discountId,
        },
        'plus',
      );
    }
    this.isProductLoading = false;
  }

  public AddToCartContinue() {
    for (let i = 0; i < this.newvariantlist.length; i++) {
      if (
        this.popupProuductName === undefined ||
        this.popupProuductName === ''
      ) {
        this.popupProuductName = this.newvariantlist[i].variantName;
      }
      this.addToOrder(this.newvariantlist[i], this.popupProuductName);
    }
    this.isProductLoading = false;
    this.isAddToPopupBlock = false;
  }

  public AddToCartCancel() {
    this.isProductLoading = false;
    this.isAddToPopupBlock = false;
  }

  public AddToCartRemove() {
    for (let i = 0; i < this.newvariantlist.length; i++) {
      if (!this.newvariantlist[i].outOfStock) {
        if (
          this.popupProuductName === undefined ||
          this.popupProuductName === ''
        ) {
          this.popupProuductName = this.newvariantlist[i].variantName;
        }
        this.addToOrder(this.newvariantlist[i], this.popupProuductName);
      }
    }
    this.newvariantlist = [];
    this.isProductLoading = false;
    this.isAddToPopupBlock = false;
    this.showPopuMe();
  }

  checkItemExist(item: any) {
    for (let i = 0; i < this.newOrder.variantSnapshots.length; i++) {
      if (item.itemType == ItemType.Good || item.itemType == ItemType.Service) {
        if (this.newOrder.variantSnapshots[i].variantId === item.variantId) {
          this.newOrder.variantSnapshots[i].variantTotalWholesalePrice =
            Number(this.newOrder.variantSnapshots[i].variantWholesalePrice) *
            Number(this.newOrder.variantSnapshots[i].variantQuantity);
          this.updateCalcs();
          return true;
        }
      }
      if (item.itemType == 3) {
        if (this.newOrder.variantSnapshots[i].itemId === item.itemId) {
          this.newOrder.variantSnapshots[i].variantTotalWholesalePrice =
            Number(this.newOrder.variantSnapshots[i].variantWholesalePrice) *
            Number(this.newOrder.variantSnapshots[i].variantQuantity);
          this.updateCalcs();
          return true;
        }
      }
    }
    return false;
  }

  public removeFromOrder(item: any) {
    if (this.newOrder.variantSnapshots.length > 1) {
      this.removeFromOrderAfterConfirmation(item);
    } else {
      this.checkoutItemToRemove = { ...item };
      this.alertTitle = 'Confirmation';
      this.alertMessage =
        'No products in the cart or cart is empty and close that window?';
      this.showConfirm = true;
    }
  }

  public async removeFromOrderAfterConfirmation(item: any) {
    for (let i = this.newOrder.variantSnapshots.length - 1; i >= 0; i--) {
      let findItem = false;

      if (item.itemType !== ItemType.Good) {
        findItem = this.newOrder.variantSnapshots[i].itemId === item.itemId;
      } else {
        findItem =
          this.newOrder.variantSnapshots[i].variantId === item.variantId;
      }

      if (findItem) {
        this.newOrder.variantSnapshots[i].isCompItem = false;
        this.newOrder.variantSnapshots[i].isOthersOptions = false;
        this.newOrder.variantSnapshots[i].complementaryReason = '';
        this.newOrder.variantSnapshots[i].complementaryReasonId = '';
        this.menuOpen = false;
        //Clearing Tax from variantSnapshots when Deleting the item from Cart
        if (
          this.newOrder.variantSnapshots[i].addedFromCreateOrder &&
          !item.itemTaxes
        ) {
          this.newOrder.variantSnapshots[i].itemTaxes = [];
          this.newOrder.variantSnapshots[i].addedFromCreateOrder = false;
        } else if (
          this.newOrder.variantSnapshots[i].addedFromCreateOrder &&
          item.itemTaxes
        ) {
          this.newOrder.variantSnapshots[i].itemTaxes = item.itemTaxes;
        }
        this.updateCalcs();
        this.resetCharge();
        this.updateQuantity(item, 'remove');
        this.newOrder.variantSnapshots.splice(i, 1);
      }
    }
  }

  /**
   * resetOrder Reset added order
   */
  public resetOrder() {
    this.filterByToppick = false;
    this.filterByInStock = false;
    this.filterByGoods = false;
    this.filterByServices = false;
    this.filterByBundle = false;
    this.newOrder.quote_name = '';
    this.newOrder.id = '';
    this.newOrderQuote = {};
    this.itemsInCartForBundle = [];
    this.selectedQuoteId = '';
    this.quotes = [];

    this.$store.commit('resetItemsFilter');
    this.show_payment_option = false;
    for (let i = this.newOrder.variantSnapshots.length - 1; i >= 0; i--) {
      this.newOrder.variantSnapshots.splice(i, 1);
      this.resetCharge();
      this.updateCalcs();
      this.$emit('remove-from-order', this.newOrder.variantSnapshots[i]);
    }
    document.body.style.overflow = 'auto';
    this.filterByGoods = false;
    this.filterByServices = false;
    this.filterByBundle = false;
    this.filterByToppick = false;
    this.filterByInStock = false;
    this.selectedCol = null; // Reset selected customer
    this.customerValue = '';
    this.selectedCustomer = null;
    this.newOrder.customer = null;

    this.findItems();
    this.removeDiscount();
  }

  public updateQuantity(item: any, type: string) {
    // IVI-953
    if (
      item.variantTracking &&
      item.variantStock === 0 &&
      type == 'plus' &&
      item.itemType != ItemType.Service &&
      (item.itemType !== ItemType.Bundle ||
        (item.itemType === ItemType.Bundle &&
          item.bundle_out_of_stock === true))
    ) {
      this.displayNotification(
        'error',
        `Sorry ${item.itemName} is out of stock.`,
        'error',
      );
    } else {
      this.itemPluseFlag = false;
      this.addedItem = { ...item };
      this.addedType = type;
      this.addItemProductName = item.variantName;
      if (type == 'plus') {
        this.popupProuductName = item.itemName;
        this.showPopuMe();
      }
      if (!this.itemPluseFlag) {
        this.addProductItem(this.addedItem, this.addedType);
      }
    }
  }

  addCartItemCanel() {
    this.itemPluseFlag = false;
  }

  addCartItemCantinue() {
    this.addProductItem(this.addedItem, this.addedType);
    this.itemPluseFlag = false;
  }

  get processedFilteredItems() {
    return this.$store.getters.getProcessedFilteredItems;
  }

  addProductItem(item: any, type: string) {
    /*
     * Bundles don't have variantId so we only check if any variantId from
     * its bundle items match some from the filtered items in the UI
     */
    if (item.itemType !== ItemType.Service) {
      for (let i = 0; i < this.filteredItems.length; i++) {
        const bundleContainsVariant =
          item.itemType === ItemType.Bundle &&
          item.bundleVariantsIds &&
          item.bundleVariantsIds.includes(this.filteredItems[i].variantId);
        const anotherBundleContainsVariant =
          item.itemType === ItemType.Bundle &&
          this.filteredItems[i].itemType == ItemType.Bundle &&
          this.filteredItems[i].bundleVariantsIds &&
          item.bundleVariantsIds &&
          this.filteredItems[i].bundleVariantsIds.filter((id) =>
            item.bundleVariantsIds.includes(id),
          );
        const isBundleItem =
          item.itemType === ItemType.Good &&
          this.filteredItems[i].itemType === ItemType.Bundle &&
          this.filteredItems[i].bundleVariantsIds?.some(
            (id) => item.variantId === id,
          );
        const matchVariantId =
          item.itemType === ItemType.Good &&
          this.filteredItems[i].itemType === ItemType.Good &&
          item.variantId.trim() !== '' &&
          this.filteredItems[i].variantId === item.variantId;

        if (
          bundleContainsVariant ||
          (anotherBundleContainsVariant &&
            anotherBundleContainsVariant.length) ||
          isBundleItem ||
          matchVariantId
        ) {
          if (type == 'plus') {
            if (isBundleItem) {
              if (item.variantStock <= this.filteredItems[i].variantStock) {
                this.filteredItems[i].variantStock--;
              }
            } else {
              if (
                this.filteredItems[i].variantId === item.bundleLeastStockVarId
              ) {
                this.filteredItems[i].variantStock--;
              }
              if (
                this.filteredItems[i].bundleLeastStockVarId ===
                item.bundleLeastStockVarId
              ) {
                this.filteredItems[i].variantStock--;
              }

              if (this.filteredItems[i].variantStockList) {
                this.filteredItems[i].variantStockList.forEach((element) => {
                  if (element.id === item.bundleLeastStockVarId) {
                    element.stock--;
                    if (element.stock == 0) {
                      this.filteredItems[i].variantStock--;
                    }
                  }
                });
              }
            }
          }
          if (type == 'minus') {
            if (isBundleItem) {
              if (item.variantStock >= this.filteredItems[i].variantStock) {
                if (
                  this.filteredItems[i].bundleVariantsIds.includes(
                    item.variantId,
                  )
                ) {
                  this.filteredItems[i].variantStock++;
                }
              }
            } else {
              if (
                this.filteredItems[i].variantId === item.bundleLeastStockVarId
              ) {
                this.filteredItems[i].variantStock++;
              }
              if (
                this.filteredItems[i].bundleLeastStockVarId ===
                item.bundleLeastStockVarId
              ) {
                this.filteredItems[i].variantStock++;
              }

              if (this.filteredItems[i].variantStockList) {
                this.filteredItems[i].variantStockList.forEach((element) => {
                  if (element.id === item.bundleLeastStockVarId) {
                    element.stock++;
                    this.filteredItems[i].variantStock++;
                  }
                });
              }
            }
          }

          if (type == 'remove') {
            // If `item` is removed we make sure to return only the amount we take to
            // `variantStock` of a related item

            if (isBundleItem && this.filteredItems[i].variantTracking) {
              if (
                this.filteredItems[i].bundleLeastStockVarId == item.variantId
              ) {
                this.filteredItems[i].variantStock = item.variantQuantity;
              }
            } else {
              if (item.variantStock == 0) {
                this.filteredItems[i].variantStock =
                  this.filteredItems[i].variantStock + item.variantQuantity;
              }
            }
            if (this.newOrder.variantSnapshots.length == 1) {
              this.resetOrder();
            }
          }

          if (type == 'importQuote') {
            if (isBundleItem) {
              if (item.variantStock <= this.filteredItems[i].variantStock) {
                this.filteredItems[i].variantStock--;
              }
            } else {
              if (
                this.filteredItems[i].variantId === item.bundleLeastStockVarId
              ) {
                this.filteredItems[i].variantStock--;
              }
              if (
                this.filteredItems[i].bundleLeastStockVarId ===
                item.bundleLeastStockVarId
              ) {
                this.filteredItems[i].variantStock--;
              }
              if (this.filteredItems[i].variantStockList) {
                this.filteredItems[i].variantStockList.forEach((element) => {
                  if (element.id === item.bundleLeastStockVarId) {
                    element.stock--;
                    if (element.stock == 0) {
                      this.filteredItems[i].variantStock--;
                    }
                  }
                });
              }
            }
          }
        }
      }
    }

    // Change values in added or removed item
    if (type === 'plus') {
      item.variantStock--;
      item.variantQuantity++;
    } else if (type === 'minus') {
      item.variantQuantity--;
      item.variantStock++;
    } else if (type === 'importQuote') {
      item.variantStock--;
      item.variantQuantity++;
    } else {
      item.variantQuantity = 0;

      let hasRelatedItemInSummaryOrder = false;
      // Assign available stock to removed Good or Bundle
      if (
        item.itemType !== ItemType.Service &&
        this.newOrder.variantSnapshots.length
      ) {
        for (let i = 0; i < this.newOrder.variantSnapshots.length; i++) {
          const itemBundleHasVariantFromAnotherBundle =
            item.itemType === ItemType.Bundle &&
            this.newOrder.variantSnapshots[i].itemType === ItemType.Bundle &&
            this.newOrder.variantSnapshots[i].bundleVariantsIds.filter((id) =>
              item.bundleVariantsIds.includes(id),
            );
          const itemBundleHasVariant =
            item.itemType === ItemType.Bundle &&
            this.newOrder.variantSnapshots[i].itemType === ItemType.Good &&
            item.variantId === this.newOrder.variantSnapshots[i].variantId;
          const itemGoodIsVariantFromBundle =
            item.itemType === ItemType.Good &&
            this.newOrder.variantSnapshots[i].itemType === ItemType.Bundle &&
            this.newOrder.variantSnapshots[i].bundleVariantsIds.includes(
              item.variantId,
            );
          if (
            itemBundleHasVariantFromAnotherBundle ||
            itemBundleHasVariant ||
            itemGoodIsVariantFromBundle
          ) {
            // Find currently updated `filteredItem` to get its stock
            const findFilteredItem = this.filteredItems.find((fit) => {
              if (fit.itemType !== ItemType.Good) {
                return fit.itemId === item.itemId;
              } else {
                return fit.variantId === item.variantId;
              }
            });
            hasRelatedItemInSummaryOrder = true;

            item.variantStock = findFilteredItem
              ? findFilteredItem.variantStock
              : item.variantMaxStock;

            break;
          }
        }
      }
      if (!hasRelatedItemInSummaryOrder) {
        item.variantStock = item.variantMaxStock;
      }
    }

    // Update quantity and stock of added item in `filteredItems`
    // that displays all items in UI
    const findIdx = this.filteredItems.findIndex((i) => {
      if (i.itemType !== ItemType.Good) {
        return i.itemId === item.itemId;
      } else {
        return i.variantId === item.variantId;
      }
    });
    if (findIdx >= 0) {
      this.filteredItems[findIdx] = {
        ...item,
      };
    }
    // Update quantity and stock of Order Sumary items
    if (this.newOrder.variantSnapshots.length) {
      for (let i = 0; i < this.newOrder.variantSnapshots.length; i++) {
        const findIt = this.filteredItems.find((fit) => {
          if (fit.itemType !== ItemType.Good) {
            return fit.itemId === this.newOrder.variantSnapshots[i].itemId;
          } else {
            return (
              fit.variantId === this.newOrder.variantSnapshots[i].variantId
            );
          }
        });

        if (findIt) {
          if (type === 'remove' && findIt.addedFromCreateOrder) {
            findIt.itemTaxes = this.newOrder.variantSnapshots[i].itemTaxes
              ? this.itemTaxDetails
              : [];
            findIt.addedFromCreateOrder = false;
          }
          // Since data is re-rendered from the filteredItem Tax is getitng cleared.
          // Adding itemTaxes will fix the issue.
          this.newOrder.variantSnapshots[i] = {
            ...findIt,
            isLoading: false,
            itemTaxes: this.newOrder.variantSnapshots[i].itemTaxes,
            addedFromCreateOrder: false,
            discountId: this.newOrder.variantSnapshots[i].discountId,
            complementaryReason:
              this.newOrder.variantSnapshots[i]?.complementaryReason,
            isCompItem: this.newOrder.variantSnapshots[i]?.isCompItem,
            isOthersOptions: this.newOrder.variantSnapshots[i]?.isOthersOptions,
            complementaryReasonId:
              this.newOrder.variantSnapshots[i]?.complementaryReasonId,
            actualWithoutCompOff:
              this.newOrder.variantSnapshots[i]?.actualWithoutCompOff,
          };
        }
      }
      let itemIndex = null;
      const itemAdded = this.newOrder.variantSnapshots.find(
        (variant, index) => {
          if (variant.itemType !== ItemType.Good) {
            itemIndex = index;
            return variant.itemId === item.itemId;
          } else {
            itemIndex = index;
            return variant.variantId === item.variantId;
          }
        },
      );

      if (itemAdded) {
        if (type === 'remove' && itemAdded.addedFromCreateOrder) {
          itemAdded.itemTaxes = this.newOrder.variantSnapshots[itemIndex]
            .itemTaxes
            ? this.itemTaxDetails
            : [];
          itemAdded.addedFromCreateOrder = false;
        }

        this.newOrder.variantSnapshots[itemIndex] = {
          ...itemAdded,
          variantStock: item.variantStock,
          variantQuantity: item.variantQuantity,
          isLoading: false,
          itemTaxes: this.newOrder.variantSnapshots[itemIndex].itemTaxes,
          addedFromCreateOrder: false,
          complementaryReason:
            this.newOrder.variantSnapshots[itemIndex]?.complementaryReason,
          isCompItem: this.newOrder.variantSnapshots[itemIndex]?.isCompItem,
          isOthersOptions:
            this.newOrder.variantSnapshots[itemIndex]?.isOthersOptions,
          complementaryReasonId:
            this.newOrder.variantSnapshots[itemIndex]?.complementaryReasonId,
          actualWithoutCompOff:
            this.newOrder.variantSnapshots[itemIndex]?.actualWithoutCompOff,
        };
      }
    }

    /*
     * Check if some ids exists in addToOrder function
     * It doesn't affect item individual quantity
     */
    this.itemsInCartForBundle.forEach((i: any) => {
      if (i.itemType === ItemType.Bundle) {
        if (i.itemId == item.itemId && item.variantStock != 0) {
          i.bundleCurrentStock = 1;
          this.isProductLoading = false;
          this.itemsInCartForBundle = this.itemsInCartForBundle.filter(
            (number) => number.itemId != item.itemId,
          );
        }
      }
      if (i.itemType === ItemType.Good) {
        if (item.variantId == i.variantId && item.variantStock != 0) {
          i.bundleCurrentStock = 1;
          this.isProductLoading = false;
          this.itemsInCartForBundle = this.itemsInCartForBundle.filter(
            (number) => number.variantId != item.variantId,
          );
        }
      }
    });

    this.setLoadingItem(false, item);
    this.updateCalcs();
  }

  showCheckoutProcess() {
    this.paymentDetailsloading = true;
    this.show_payment_option = true;
    this.clickoncharger = true;
    this.isCardPaymentProgress = true;
    document.body.style.overflow = 'hidden';
    this.tearDownListeners();
    this.chargeInitiated = true;
    this.isCardPaymentProgress = false;
    this.paymentDetailsloading = false;
  }

  public async initiateCharge(
    numberOfSplits?: number,
    currentPaymentIndex?: number,
  ) {
    this.splitPaymentsNumber = 0;
    this.currentPaymentSplit = 0;
    if (numberOfSplits !== 0 && numberOfSplits !== undefined) {
      this.splitPaymentsNumber = numberOfSplits;
      this.currentPaymentSplit = currentPaymentIndex;
    }

    this.paymentDetailsloading = true;
    this.show_payment_option = true;
    this.clickoncharger = true;
    this.isCardPaymentProgress = true;
    document.body.style.overflow = 'hidden';
    this.tearDownListeners();
    this.chargeInitiated = true;
    await this.determineCardOptions();
    this.isCardPaymentProgress = false;
    this.paymentDetailsloading = false;
  }

  public async determineCardOptions() {
    if (this.newOrder.isAchPaymentMethod) {
      await this.createOrder(3);
    } else {
      await this.createOrder(1);
    }
    if (this.savedPaymentMethods.length > 0 && this.splitPaymentsNumber === 0) {
      this.showCardOptions = true;
      this.payformVisible = false;
      this.savedPaymentFormVisible = false;
    } else {
      this.usePayform();
    }
  }

  public useSavedCard(currentPaymentIndex?: number) {
    if (currentPaymentIndex !== 0 && currentPaymentIndex !== undefined) {
      this.currentPaymentSplit = currentPaymentIndex;
    }
    this.tearDownListeners();

    this.payformVisible = false;
    this.savedPaymentFormVisible = true;
  }

  public usePayform(currentPaymentIndex?: number) {
    if (currentPaymentIndex !== 0 && currentPaymentIndex !== undefined) {
      this.currentPaymentSplit = currentPaymentIndex;
    }

    this.payformVisible = true;
    this.savedPaymentFormVisible = false;
  }

  public async createOrder(
    paymentType: PaymentType,
    splitPaymentNumber?: number,
    currentPaymentIndex?: number,
  ) {
    this.newOrder.isPaid = true;
    this.newOrder.location = this.getSelectedLocation();
    this.newOrder.paymentType = paymentType;
    this.newOrder.tax = this.calcResults.tax;
    this.newOrder.total = this.calcResults.total;
    this.newOrder.subtotal = this.calcResults.subtotal;
    this.newOrder.tip = this.calcResults.tip;

    if (splitPaymentNumber !== 0 && splitPaymentNumber !== undefined) {
      this.splitPaymentsNumber = splitPaymentNumber;
    }

    if (currentPaymentIndex !== 0 && currentPaymentIndex !== undefined) {
      this.currentPaymentSplit = currentPaymentIndex;
    }

    if (this.splitPaymentsNumber !== 0 && this.currentPaymentSplit >= 1) {
      let divisionResultSubtotal = (
        this.calcResults.subtotal / this.splitPaymentsNumber
      ).toFixed(2);

      let divisionTax = (
        this.calcResults.tax / this.splitPaymentsNumber
      ).toFixed(2);

      let divisionTip = (
        this.calcResults.tip / this.splitPaymentsNumber
      ).toFixed(2);

      // Remediate calculations
      if (
        Number(this.currentPaymentSplit) === Number(this.splitPaymentsNumber)
      ) {
        const orderPayments = await this.getOrderTransactions(this.theOrderId);
        const subTotalAmount = orderPayments.data.orderPayments
          .map((payment) => {
            const paymentTax = Number(payment.transaction.tax);
            const paymentTip = Number(payment.transaction.tip_amount);
            return Number(payment.paymentAmount) - (paymentTax + paymentTip);
          })
          .reduce((a: number, b: number) => a + b, 0);
        const paymentTax = orderPayments.data.orderPayments
          .map((payment) => Number(payment.transaction.tax))
          .reduce((a: number, b: number) => a + b, 0);
        const paymentTip = orderPayments.data.orderPayments
          .map((payment) => Number(payment.transaction.tip_amount))
          .reduce((a: number, b: number) => a + b, 0);

        // Subtotal
        divisionResultSubtotal = (
          this.calcResults.subtotal - Number(subTotalAmount)
        ).toFixed(2);
        // Tax
        divisionTax = (this.calcResults.tax - Number(paymentTax)).toFixed(2);
        // Tip
        divisionTip = (this.calcResults.tip - Number(paymentTip)).toFixed(2);
      }

      this.newOrder.tip = parseFloat(divisionTip);
      this.newOrder.tax = parseFloat(divisionTax);
      this.newOrder.subtotal = parseFloat(divisionResultSubtotal);
      this.newOrder.total = parseFloat(
        Number(
          this.newOrder.subtotal + this.newOrder.tax + this.newOrder.tip,
        ).toFixed(2),
      );
    }

    let apiResponse;
    this.isMarkAsPaidClicked = true;

    if (paymentType === PaymentType.Cash) {
      this.loading = true;
      apiResponse = await this.processCashOrder();
      this.loading = false;
    } else if (
      paymentType === PaymentType.CreditCard ||
      paymentType === PaymentType.Ach
    ) {
      apiResponse = await this.processPaymentOrder();
      if (apiResponse.status === 201) {
        document.body.style.overflow = 'auto';
        this.orderId = apiResponse.data.response.order;
      }
    }
  }

  async finishTransaction(data: any) {
    data.isPaid = true;
    data.transactionType = 1;
    data.paymentType = 0;
    data.accountType = null;
    data.id = uuidv4();

    const newOrder = this.parseOrderRequestForBundles(data);
    this.loading = true;
    let apiResponse: any = {};
    apiResponse = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/order`,
      newOrder,
    );

    if (apiResponse.status === 200) {
      this.loading = false;
      this.displayNotification(
        'success',
        'Your payment has been successful',
        'success',
      );
      setTimeout(() => {
        router.replace({
          path: `/orders/${newOrder.id}`,
          query: { locationId: this.locationId },
        });
      }, 2000);
    }
  }

  async payformListener(event: any) {
    this.splitPaymentsNumber = +this.splitPaymentsNumber;
    if (event && event.type === 'message') {
      const response = JSON.parse(event.data);
      this.checkForDecline(response);
      const transaction: any = {
        id: response.id,
        transaction_api_id: response.transaction_api_id,
        fortisTransactionId: response.id,
        orderId: this.orderId!,
        save_account: this.newOrder.save_account,
        depositAccountId: this.newOrder.depositAccountId,
        depositAccountTitle: this.newOrder.depositAccountTitle,
      };
      this.loading = true;
      this.newOrder.accountType = response.account_type;
      try {
        const newOrder = this.parseOrderRequestForBundles(this.newOrder);
        let apiResponse: any = {};

        if (this.currentPaymentSplit <= 1) {
          newOrder.total = this.calcResults.total;
          apiResponse = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/order`,
            { ...newOrder, splitPayment: this.splitPaymentsNumber >= 1 },
          );
        } else {
          apiResponse = this.primaryOrderResponse;
        }

        if (
          apiResponse.status === 200 &&
          apiResponse.data.order !== undefined
        ) {
          const orderId2 = apiResponse.data.order.order.id;
          transaction.orderId = orderId2;

          if (this.currentPaymentSplit === 1) {
            this.primaryOrderResponse = apiResponse;
          }

          transaction.order = apiResponse.data.order.order;
          if (this.splitPaymentsNumber !== 0 && this.currentPaymentSplit <= 1) {
            transaction.order = await this.splitPaymentValues(
              transaction.order,
            );
          }
          this.orderId = orderId2;

          const transactionResponse: AxiosResponse<any> = await axios.put(
            `${process.env.VUE_APP_BASE_URL}/transaction/${transaction.transaction_api_id}`,
            transaction,
          );
          const checkoutComponent = this.$refs.checkoutComponent as any;
          if (transactionResponse.status === 200) {
            if (this.splitPaymentsNumber === 0) {
              this.displayNotification(
                'success',
                'Your payment has been successful',
                'success',
              );
              this.tearDownListeners();
              setTimeout(() => {
                router.replace({
                  path: `/orders/${this.theOrderId}`,
                  query: { locationId: this.locationId },
                });
              }, 2000);
            } else {
              if (this.currentPaymentSplit < this.splitPaymentsNumber) {
                this.displayNotification(
                  'success',
                  'Your payment has been successful, procceding to next payment!',
                  'success',
                );
                await this.addSplitPayment(
                  transaction.order,
                  transaction,
                  response,
                );
                checkoutComponent.nextPayment();
              } else if (
                this.currentPaymentSplit !== 0 &&
                this.currentPaymentSplit === this.splitPaymentsNumber
              ) {
                this.displayNotification(
                  'success',
                  'Your payment has been successful',
                  'success',
                );
                await this.addSplitPayment(
                  transaction.order,
                  transaction,
                  response,
                );
                this.tearDownListeners();
                await this.getOrderTransactions(this.theOrderId);
                checkoutComponent.nextclick();
              } else {
                this.displayNotification(
                  'success',
                  'Your payment has been successful',
                  'success',
                );

                this.tearDownListeners();
                setTimeout(() => {
                  router.replace({
                    path: `/orders/${this.theOrderId}`,
                    query: { locationId: this.locationId },
                  });
                }, 2000);
              }
            }
          }
        }
      } catch (err) {
        if (
          err.message &&
          err.message.includes('Request failed with status code 409')
        ) {
          this.displayNotification(
            'error',
            'Item is no longer in stock',
            'error',
          );
        } else {
          this.displayNotification('error', 'Transaction failed', 'error');
        }
      }
      this.loading = false;
    }
  }

  public splitPaymentValues(transaction) {
    transaction.discountAmount =
      parseFloat(transaction.discountAmount) !== 0
        ? (
            parseFloat(transaction.discountAmount) / this.splitPaymentsNumber
          ).toFixed(2)
        : 0;

    transaction.grossSale =
      parseFloat(transaction.grossSale) !== 0
        ? (
            parseFloat(transaction.grossSale) / this.splitPaymentsNumber
          ).toFixed(2)
        : 0;

    transaction.subtotal =
      parseFloat(transaction.subtotal) !== 0
        ? (parseFloat(transaction.subtotal) / this.splitPaymentsNumber).toFixed(
            2,
          )
        : 0;

    transaction.tax =
      parseFloat(transaction.tax) !== 0
        ? (parseFloat(transaction.tax) / this.splitPaymentsNumber).toFixed(2)
        : 0;

    transaction.tip =
      parseFloat(transaction.tip) !== 0
        ? (parseFloat(transaction.tip) / this.splitPaymentsNumber).toFixed(2)
        : 0;

    transaction.profit =
      parseFloat(transaction.profit) !== 0
        ? (parseFloat(transaction.profit) / this.splitPaymentsNumber).toFixed(2)
        : 0;

    return transaction;
  }

  public async addSplitPayment(data, transaction, response?: any) {
    const paymentType =
      response.payment_method === 'cc'
        ? PaymentType.CreditCard
        : response.payment_method === 'cash'
        ? PaymentType.Cash
        : PaymentType.Ach;

    let depositId;
    let depositTitle;

    if (transaction.depositAccountId) {
      depositId = transaction.depositAccountId;
      depositTitle = transaction.depositAccountTitle;
    } else {
      depositId = data.depositAccountId;
      depositTitle = data.depositAccountTitle;
    }

    const splitPayment: any = {
      paymentType: paymentType,
      transactionType: data.transactionType,
      accountType:
        response && response.account_type
          ? response.account_type
          : data.account_type || 'cash',
      paymentAmount:
        response !== undefined
          ? parseFloat(response.transaction_amount)
          : data.total,
      transactionApiId:
        response !== undefined ? response.transaction_api_id : '',
      depositAccountId: depositId,
      depositAccountTitle: depositTitle,
    };
    const transactionResponse: AxiosResponse<any> = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/transaction/order-payment/${data.id}`,
      splitPayment,
    );
  }

  public resetCharge() {
    this.newTransaction = undefined;
    this.savedPaymentMethods = [];
    this.showCardOptions = false;
    this.showPayform = false;
    this.payformVisible = false;
    this.savedPaymentFormVisible = false;
    this.chargeInitiated = false;
    this.isCardPaymentProgress = false;
    this.primaryOrderResponse = undefined;
    this.splitPaymentsNumber = 0;
    this.currentPaymentSplit = 0;
    this.tearDownListeners();
  }

  syncOrderSummaryAndFilteredItems() {
    if (this.newOrder.variantSnapshots.length) {
      for (let i = 0; i < this.newOrder.variantSnapshots.length; i++) {
        const findIt = this.filteredItems.findIndex(
          (fit) => fit.itemId === this.newOrder.variantSnapshots[i].itemId,
        );
        if (findIt >= 0) {
          this.filteredItems[findIt] = {
            ...this.newOrder.variantSnapshots[i],
          };
        }
      }
    }
  }

  changedValue() {
    this.textCount++;
    this.page = 1;
    const temp = this.textCount;
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      this.findItems('', temp);
    }, 1000);
  }

  public async findItems(barcode = '', searchNumber?: number) {
    this.isProductLoading = true;
    this.selectableItems = [];
    this.page = 1;
    let itemType = '0';
    const topPicks = this.filterByToppick ? 1 : 0;
    const outOfStock = this.filterByInStock ? 1 : 0;

    if (this.filterByGoods) {
      itemType += ',1,';
    }

    if (this.filterByBundle) {
      itemType += ',3,';
    }

    if (this.filterByServices) {
      itemType += ',2,';
    }

    if (itemType !== '0') {
      itemType = itemType.substring(2);
      itemType = itemType.substring(0, itemType.length - 1).replace(',,', ',');
    }
    this.$store.commit('setItemFilter', {
      location: this.$store.getters.selectedLocationId,
      locationId: '',
      locationIds: '',
      page: this.page,
      pageSize: this.pageSize,
      search: this.searchTerm,
      itemType: itemType,
      topPicks: topPicks,
      outofStock: outOfStock,
      pageType: 'create_order',
      categoryId: this.selectedCategoryId || 0,
    });
    await this.$store.dispatch('getItemList').then(async () => {
      if (!searchNumber || this.textCount == searchNumber) {
        await this.$store.getters.allItemList.forEach((item: any) => {
          this.processItem(item);
        });
        this.reOrderProcessedItems();
        if (!this.selectedCategoryId) {
          this.filteredItems = this.selectableItems;
          this.categoryFilteredItems = [];
        } else {
          this.categoryFilteredItems = this.selectableItems;
          this.filteredItems = this.selectableItems;
        }

        this.$store.commit('setFilteredItems', this.filteredItems);
        this.syncOrderSummaryAndFilteredItems();
      }
    });

    if (this.filterByInStock) {
      this.filteredItems = this.filteredItems.filter(
        (filteredItem) => filteredItem.bundleOutOfStock !== true,
      );
    }

    if (barcode) {
      if (this.filteredItems.length === 1) {
        this.barcodeValue = '';
        const variant = this.filteredItems[0];
        const newItem: any = {
          variantId: variant.variantId,
          itemId: variant.itemId,
          itemHasImage: variant.itemHasImage,
          itemImageSrc: variant.itemHasImage
            ? variant.itemImageSrc
            : '/image_placeholder.png',
          itemName: variant.itemName,
          itemType: variant.itemType,
          itemDiscount: 0,
          itemDescription: variant.itemDescription,
          itemManufacturer: variant.itemManufacturer,
          variantName: variant.variantName,
          variantSku: variant.variantSku,
          variantPrice: variant.variantPrice,
          variantWholesalePrice: variant.variantWholesalePrice,
          variantQuantity: variant.variantQuantity,
          itemTaxes: variant.itemTaxes,
          variantStock: variant.variantStock,
          variantTracking: variant.variantTracking,
          variantUpc: variant.variantUpc,
          variantLocation: variant.variantLocation,
        };

        if (variant.variantDiscountedPrice) {
          newItem.variantPrice =
            Number(variant.variantPrice) - variant.variantDiscountedPrice;
        }

        this.addToOrder(
          newItem,
          this.filteredItems[0].variantName,
          this.filteredItems[0],
        ).then(() => {
          this.resetSearch();
        });
      }

      if (this.filteredItems.length === 0) {
        this.alertTitle = 'Alert';
        this.alertMessage = 'No product found with upc ' + barcode;
        this.showConfirm = true;
        this.resetSearch();
      }
    }
    this.isProductLoading = false;
  }

  public resetSearch() {
    this.searchTerm = '';
    this.selectedCol = null;
    this.page = 1;
    this.filteredItems = sortBy(this.selectableItems, (o: any) => o.itemName);
    this.findItems();
  }

  public async barcodeSearch(barcode: string) {
    this.searchTerm = barcode;
    this.page = 1;
    await this.findItems(barcode);
  }
  public async changeCustomer(customer: any) {
    if (customer === null) {
      this.clearCustomer();
    } else {
      this.getSelectedCustomer(customer);
    }
  }

  public customerAddress() {
    return this.showimport
      ? this.newOrderQuote?.customer?.address
        ? this.newOrderQuote.customer.address
        : ''
      : this.newOrder?.customer?.address
      ? this.newOrder.customer.address
      : '';
  }

  public customerName() {
    const firstNameFromQuote = this.newOrderQuote?.customer?.first_name
      ? this.newOrderQuote.customer.first_name
      : '';
    const lasteNameFromQuote = this.newOrderQuote?.customer?.last_name
      ? this.newOrderQuote.customer.last_name
      : '';
    const firstNameFromOrder = this.newOrder?.customer?.first_name
      ? this.newOrder.customer.first_name
      : '';
    const lasteNameFromOrder = this.newOrder?.customer?.last_name
      ? this.newOrder.customer.last_name
      : '';

    return this.showimport
      ? firstNameFromQuote + ' ' + lasteNameFromQuote
      : firstNameFromOrder + ' ' + lasteNameFromOrder;
  }

  public customerEmail() {
    return this.showimport
      ? this.newOrderQuote?.customer?.email
        ? this.newOrderQuote.customer.email
        : ''
      : this.newOrder?.customer?.email
      ? this.newOrder.customer.email
      : '';
  }

  public locationAddress() {
    return this.showimport
      ? this.newOrderQuote.location &&
        this.newOrderQuote.location.address1 &&
        this.newOrderQuote.location.address2 &&
        this.newOrderQuote.location.city
        ? this.newOrderQuote.location.address1 +
          ',' +
          this.newOrderQuote.location.address2 +
          ',' +
          this.newOrderQuote.location.city
        : ''
      : this.newOrder.location &&
        this.newOrder.location.address1 &&
        this.newOrder.location.address2 &&
        this.newOrder.location.city
      ? this.newOrder.location.address1 +
        ',' +
        this.newOrder.location.address2 +
        ',' +
        this.newOrder.location.city
      : '';
  }

  public locationName() {
    return this.showimport
      ? this.newOrderQuote.location
        ? this.newOrderQuote.location.name
        : ''
      : this.newOrder.location
      ? this.newOrder.location.name
      : '';
  }

  public locationEmail() {
    return this.showimport
      ? this.newOrderQuote.location
        ? this.newOrderQuote.location.email
        : ''
      : this.newOrder.location
      ? this.newOrder.location.email
      : '';
  }

  async submitPaymentWithSavedCard(accountVault: any) {
    // Transaction begins
    this.isProcessingSavedCard = true;
    let tip = 0;
    this.newOrder.variantSnapshots.map((item: any) => {
      tip = tip + parseFloat(item.tip);
    });
    const fortisTransaction: any = {
      transaction_api_id: this.newTransaction,
      transaction_amount: this.newOrder.total,
      account_vault_id: accountVault.id,
      location_id: this.$store.getters.selectedLocationId,
      tip_amount: tip,
      product_transaction_id: this.newOrder.product_transaction_id,
      payment_method: accountVault.payment_method,
    };
    this.splitPaymentsNumber = +this.splitPaymentsNumber;

    try {
      const resFortisTrans = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/fortis/transaction`,
        fortisTransaction,
      );
      if (resFortisTrans && resFortisTrans.status === 201) {
        this.checkForDecline(resFortisTrans.data.transaction);
        const transaction: any = {
          id: resFortisTrans.data.transaction.id,
          transaction_api_id:
            resFortisTrans.data.transaction.transaction_api_id,
          fortisTransactionId: resFortisTrans.data.transaction.id,
          orderId: this.orderId!,
          save_account: this.newOrder.save_account,
          depositAccountId: this.newOrder.depositAccountId,
          depositAccountTitle: this.newOrder.depositAccountTitle,
        };
        this.newOrder.accountType =
          resFortisTrans.data.transaction.account_type;
        const newOrder = this.parseOrderRequestForBundles(this.newOrder);

        let orderResult: any = {};
        if (this.currentPaymentSplit <= 1) {
          orderResult = await axios
            .post(`${process.env.VUE_APP_BASE_URL}/order`, {
              ...newOrder,
              splitPayment: this.splitPaymentsNumber >= 1,
            })
            .catch((err) => {
              if (err.response.status === 409) {
                this.showOrderConflictMessage();
              }
              return Promise.reject(err);
            });
          this.orderId = orderResult.data.order.order.id;
        } else {
          orderResult = this.primaryOrderResponse;
        }

        if (
          orderResult.status === 200 &&
          orderResult.data.order !== undefined
        ) {
          if (this.currentPaymentSplit === 1) {
            this.primaryOrderResponse = orderResult;
          }

          transaction.order = orderResult.data.order.order;
          if (this.splitPaymentsNumber !== 0 && this.currentPaymentSplit <= 1) {
            transaction.order = await this.splitPaymentValues(
              transaction.order,
            );
          }
          const orderId = orderResult.data.order.order.id;
          transaction.orderId = orderId;
          const trxResult = await axios.put(
            `${process.env.VUE_APP_BASE_URL}/transaction/${transaction.transaction_api_id}`,
            transaction,
          );
          if (trxResult && trxResult.status === 200) {
            const checkoutComponent = this.$refs.checkoutComponent as any;
            if (this.currentPaymentSplit < this.splitPaymentsNumber) {
              this.displayNotification(
                'success',
                'Your payment has been successful, procceding to next payment!',
                'success',
              );
              await this.addSplitPayment(
                transaction.order,
                transaction,
                fortisTransaction,
              );
              checkoutComponent.nextPayment();
            } else if (
              this.currentPaymentSplit !== 0 &&
              this.currentPaymentSplit === this.splitPaymentsNumber
            ) {
              await this.addSplitPayment(
                transaction.order,
                transaction,
                fortisTransaction,
              );
              this.displayNotification(
                'success',
                'Your payment has been successful',
                'success',
              );
              this.tearDownListeners();
              this.orderId = transaction.orderId;
              await this.getOrderTransactions(transaction.orderId);
              checkoutComponent.nextclick();
            } else {
              this.displayNotification(
                'success',
                'Your payment has been successful',
                'success',
              );
              this.tearDownListeners();
              setTimeout(() => {
                router.replace({
                  path: `/orders/${this.theOrderId}`,
                  query: { locationId: this.locationId },
                });
              }, 2000);
            }
          }
        }
      }
    } catch (error) {
      // type AxiosError
      this.displayNotification(
        'error',
        `Communication Error \n Status Code: ${error.response.status}`,
        'error',
      );
    } finally {
      this.isProcessingSavedCard = false;
      this.$store.commit('setTransactionInProgress', false);
      // Transaction finished
    }
  }

  public async getOrderTransactions(orderId: string) {
    try {
      const orderResult = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/transaction/order-payment/${orderId}`,
      );
      this.orderTransactions = orderResult.data.orderPayments;
      return orderResult;
    } catch (error) {
      console.error(error);
    }
  }

  public checkForDecline(transaction: any) {
    const fortisDeclinedStatusId = 301;
    if (transaction.status_id === fortisDeclinedStatusId) {
      this.newOrder.transactionType = TransactionType.Declined;
    }
  }

  public showOrderConflictMessage(): void {
    this.payformVisible = false;
    this.savedPaymentFormVisible = false;
    this.showConflictErrorMessage = true;
  }

  public addDiscount(discount: any) {
    if (discount.discountType == 1 && this.calcResults.total < discount.value) {
      this.popoverShow = false;
      this.showErrorMsg = true;
      this.errorMsg = `Discount Amount cannot be greater than total amount`;
      this.errorTimeout();
      return;
    }
    if (this.chargeInitiated) this.resetCharge();
    this.popoverShow = false;
    this.showDiscountButton = false;
    const newDiscount: any = {
      name: discount.name,
      value: discount.value,
      discountType: discount.discountType,
      id: discount.id,
      orderId: discount.orderId,
      friendlyDisplay: '',
    };
    if (discount.discountType === DiscountType.Percentage) {
      newDiscount.friendlyDisplay = `${discount.value}% off all items`;
    } else if (discount.discountType === DiscountType.Monetary) {
      newDiscount.friendlyDisplay = `$${discount.value} off all items`;
    }
    this.discount = newDiscount;
    this.newOrder.discounts!.push(newDiscount);
    this.updateCalcs();
    this.setLocationsDiscount();
    this.popoverShow = false;
  }

  public closeDiscountPopover() {
    this.popoverShow = false;
  }

  public removeDiscount() {
    if (this.chargeInitiated) this.resetCharge();
    this.discount = undefined;
    this.showDiscountButton = true;
    this.newOrder.discounts = [];
    this.newOrder.discountApplied = false;
    if (this.newOrder?.variantSnapshots) {
      this.newOrder.variantSnapshots.forEach((snapshot) => {
        snapshot.discountSelection = false;
        snapshot.itemDiscount = 0;
      });
    }

    this.newOrder.discounts!.pop();
    if (this.newOrder.variantSnapshots[0]) {
      this.newOrder.variantSnapshots[0].itemDiscount = 0;
    }
    this.updateCalcs();
  }

  public handleFilterByGoods() {
    this.filterByGoods = !this.filterByGoods;
    this.page = 1;
    this.findItems();
  }

  public handleFilterByServices() {
    this.filterByServices = !this.filterByServices;
    this.page = 1;
    this.findItems();
  }

  public handleFilterByBundle() {
    this.filterByBundle = !this.filterByBundle;
    this.page = 1;
    this.findItems();
  }

  public handleFilterByToppick() {
    this.filterByToppick = !this.filterByToppick;
    this.page = 1;
    this.findItems();
  }

  public handleFilterByInStock() {
    this.filterByInStock = !this.filterByInStock;
    this.page = 1;
    this.findItems();
  }

  public async sortCol(event: any) {
    this.isProductLoading = true;
    this.loading = true;
    let isDesc = false;
    let sortBy;
    let sortType;
    if (event == null) {
      this.$store.commit('setItemFilter', { sortBy: '', sortType: '' });
    } else {
      isDesc = event.includes('Desc');
      sortBy = isDesc ? event.replace('Desc', '') : event;
      sortType = isDesc ? 'DESC' : 'ASC';
      this.$store.commit('setItemFilter', { sortBy, sortType });
    }
    try {
      await this.$store.dispatch('getItemList');
      const items = this.$store.getters.allItemList;

      this.filteredItems = [];
      this.selectableItems = [];
      items.forEach((item: any) => this.processItem(item));
      this.reOrderProcessedItems();
      this.filteredItems = this.selectableItems;
      this.syncOrderSummaryAndFilteredItems();
    } catch (error) {
      console.error(error);
    } finally {
      this.isProductLoading = false;
      this.loading = false;
    }
  }

  private async disableLocationField() {
    if (this.url_parameters.locationId) {
      this.locationId =
        this.url_parameters.locationId.toString() ||
        this.localStorageService.getItem('primary_location_id');
      this.$store
        .dispatch(
          'setSelectedLocationId',
          this.url_parameters.locationId.toString(),
        )
        .then(() => {
          this.newOrder.location = this.getSelectedLocation();
          this.changeLocation();
          if (this.url_parameters.customerId) {
            this.isEditContactShow = true;
            this.$store.dispatch('getCustomerList').then(() => {
              this.customerId = this.url_parameters.customerId.toString();
              this.$store
                .dispatch(
                  'setSelectedCustomerId',
                  this.url_parameters.customerId.toString(),
                )
                .then(() => {
                  this.disableCustomerField();
                });
            });
          }
        });
    } else {
      let primary_location_id = null;
      if (this.locations.length > 1) {
        primary_location_id = this.localStorageService.getItem(
          'primary_location_id',
        );
      } else {
        primary_location_id = this.locations[0].id;
      }

      this.$store.dispatch('setSelectedLocationId', primary_location_id);
      this.newOrder.location = this.getSelectedLocation();
      this.changeLocation();
    }

    if (this.locations.length === 1) {
      this.disableLocationSelect = true;
    }
  }

  private disableCustomerField(): void {
    if (
      this.url_parameters.locationId &&
      this.url_parameters.locationId !== 'null'
    ) {
      this.disableLocationSelect = true;
    }
  }

  private async processPaymentOrder(): Promise<any> {
    this.isCardPaymentProgress = true;
    if (!this.hasCartTotal()) {
      return;
    }
    let apiResponse;
    try {
      apiResponse = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/fortis/payform`,
        this.newOrder,
      );

      this.paymentDetailsloading = true;
      if (
        apiResponse.status === 201 &&
        apiResponse.data.response.payformUrl !== undefined
      ) {
        this.newOrder.transaction_api_id =
          apiResponse.data.response.transaction;
        this.is_product_accountvault =
          apiResponse.data.response.hasStoredPaymentMethods;
        if (apiResponse.data.response.redirectUrl) {
          this.iframeUrl = apiResponse.data.response.redirectUrl;
          this.paymentDetailsloading = false;
        } else if (apiResponse.data.response.payformUrl) {
          const content: any = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/fortis/payformiframe`,
            {
              url: apiResponse.data.response.payformUrl,
            },
          );
          if (content.data.errors) {
            this.iframeUrl = null;
            let errors = [];
            for (const key in content.data.errors) {
              errors = [...errors, ...content.data.errors[key]];
            }
            this.iframeUrlError = errors;
          } else {
            this.iframeUrlError = null;
            this.iframeUrl =
              'data:text/html,' + encodeURIComponent(content.data);
          }
          this.isCardPaymentProgress = false;
          this.orderId = apiResponse.data.response.order;
          this.newTransaction = apiResponse.data.response.transaction;
          this.newOrder.id = apiResponse.data.response.order;
          if (apiResponse.data.response.hasStoredPaymentMethods) {
            this.savedPaymentMethods = apiResponse.data.response.accountVaults;
          } else {
            this.savedPaymentMethods = [];
          }
          this.paymentDetailsloading = false;
        } else {
          this.isChargeDisabledNew = false;
          this.paymentDetailsloading = false;
        }
        return apiResponse;
      }
    } catch (err) {
      apiResponse = err.response;
    } finally {
      this.tearDownListeners();
    }
  }

  private hasCartTotal() {
    if (this.calcResults.total === 0.0) {
      this.payformVisible = false;
      this.savedPaymentFormVisible = false;
      this.showZeroDollarErrorMessage = true;
      return false;
    } else {
      this.showZeroDollarErrorMessage = false;
    }
    return true;
  }

  private async processCashOrder(): Promise<any> {
    let apiResponse;
    const newOrder = this.parseOrderRequestForBundles(this.newOrder);
    try {
      if (this.currentPaymentSplit <= 1) {
        newOrder.total = this.calcResults.total;
        apiResponse = await axios.post(
          `${process.env.VUE_APP_BASE_URL}/order`,
          { ...newOrder, splitPayment: this.splitPaymentsNumber >= 1 },
        );
        this.orderId = apiResponse.data.order.order.id;
      } else {
        apiResponse = this.primaryOrderResponse;
      }
    } catch (err) {
      apiResponse = err.response;
      if (apiResponse.status === 409) {
        this.displayNotification('error', apiResponse.data.message, 'error');
      }
    } finally {
      if (apiResponse.status === 200 && apiResponse.data.order !== undefined) {
        let total = Number(
          (this.calcResults.total / this.splitPaymentsNumber).toFixed(2),
        );

        if (this.currentPaymentSplit === this.splitPaymentsNumber) {
          total = Number(this.newOrder.total);
        }

        const transaction = {
          product_transaction_id: this.newOrder.product_transaction_id,
          description: '',
          transaction_amount: total,
          action: 'sale',
          payment_method: 'cash',
          contact_Id: apiResponse.data.order.order.customerId || '',
          transaction_api_id: this.newOrder.transaction_api_id,
          tax: Number(this.newOrder.tax),
          tip_amount: Number(this.newOrder.tip),
          discount_amount: parseFloat(this.calcResults.discount) || 0,
          account_type: 'cash',
          locationId: this.newOrder.location.id,
        };
        let trxResult;
        try {
          trxResult = await axios.post(
            `${process.env.VUE_APP_BASE_URL}/transaction/cash-payment-method/${apiResponse.data.order.order.id}`,
            transaction,
          );
        } catch (err) {
          this.displayNotification('error', err.response.data.message, 'error');
          this.resetOrder();
        } finally {
          if (trxResult && trxResult.status === 200) {
            this.splitPaymentsNumber = +this.splitPaymentsNumber;
            this.primaryOrderResponse = apiResponse;
            const checkoutComponent = this.$refs.checkoutComponent as any;

            if (this.currentPaymentSplit < this.splitPaymentsNumber) {
              this.displayNotification(
                'success',
                'Your payment has been successful, procceding to next payment!',
                'success',
              );
              await this.addSplitPayment(
                apiResponse.data.order.order,
                newOrder,
                transaction,
              );
              checkoutComponent.nextPayment();
            } else if (
              this.currentPaymentSplit !== 0 &&
              this.currentPaymentSplit === this.splitPaymentsNumber
            ) {
              await this.addSplitPayment(
                apiResponse.data.order.order,
                newOrder,
                transaction,
              );
              this.displayNotification(
                'success',
                'Your payment has been successful',
                'success',
              );
              const orderId = apiResponse.data.order.order.id;
              document.body.style.overflow = 'auto';
              this.orderId = orderId;
              await this.getOrderTransactions(orderId);
              checkoutComponent.nextclick();
            } else {
              this.displayNotification(
                'success',
                'Your payment has been successful',
                'success',
              );
              const orderId = apiResponse.data.order.order.id;
              this.markedAsPaid = true;
              document.body.style.overflow = 'auto';
              setTimeout(
                () =>
                  router.push({
                    path: `/orders/${orderId}`,
                    query: { locationId: this.locationId },
                  }),
                2000,
              );
            }
          }
        }
      }
    }

    return apiResponse;
  }

  private getSelectedLocation() {
    const locationId = this.$store.getters.selectedLocationId;
    return this.locations.filter((l: any) => l.id === locationId)[0];
  }

  private getSelectedCustomer(customer: any) {
    this.selectedCustomer = customer;
    this.newOrder.customer = customer;
    this.newOrder.customerId = this.newOrder.customer
      ? this.newOrder.customer.id
      : null;
    this.customerValue =
      (customer.first_name ?? '') +
      ' ' +
      (customer.last_name ?? '') +
      ' - Acc #:' +
      (customer.account_number ?? '');
    this.customerId = this.newOrder.customerId;
    this.$forceUpdate();
    return customer;
  }

  private async setDepositeOptions() {
    await this.$store.dispatch('getDepositeAccounts').then(() => {
      this.newOrder.product_transaction_id =
        this.$store.getters.defaultDepositAccount;
    });
  }

  private async setLocationOptions() {
    let locationOptions = this.$store.getters.locationOptions;
    const locationOfSuperadmin = this.localStorageService.getItem('locations');
    if (locationOfSuperadmin && this.roleService.isSuperadmin) {
      locationOptions = [
        ...locationOptions.filter(
          (l: any) =>
            l.value === locationOfSuperadmin.slice(2, -2) || l.value === null,
        ),
      ];
      await this.$store.dispatch('setLocationOptions', locationOptions);
      if (locationOptions.length === 2 && locationOptions[0].value === null) {
        this.locationId =
          locationOfSuperadmin.slice(2, -2) ||
          this.localStorageService.getItem('primary_location_id');
        this.changeLocation();
      }
    }
  }

  get disableTip() {
    const order: any = this.newOrder;
    let flag = true;
    order.variantSnapshots.filter((item: any) => {
      if (item.itemType === ItemType.Service) {
        flag = false;
      }
    });
    return flag;
  }

  selectAllItems() {
    this.newOrder.variantSnapshots.map((item) => {
      item.discountSelection = this.newOrder.discountApplied;
      return item;
    });
    this.updateCalcs();
  }
  positiveButton() {
    this.showConfirm = false;
    if (this.checkoutItemToRemove) {
      document.body.style.overflow = 'auto';
      this.removeFromOrderAfterConfirmation(this.checkoutItemToRemove);
      this.show_payment_option = false;
    }
  }
  negativeButton() {
    this.showConfirm = false;
  }

  // Reset customer if customer field is empty
  checkIsEmpty() {}

  // Process bundle and variants items
  processItem(item: any) {
    if (item.itemType === ItemType.Bundle) {
      if (item.bundles && item.bundles.length > 0) {
        let price = 0;
        let updatedPrice = 0;
        const bundleVariantsIds = [];
        const listOfVariantStock = [];
        item.bundles.forEach((bundle: any) => {
          price += parseFloat(bundle.variant.price.toString());
          bundleVariantsIds.push(bundle.variant.id);
          listOfVariantStock.push({
            id: bundle.variant.id,
            stock: bundle.variant.variantLocations[0].currentStock,
          });
        });
        if (item.bundleCustomPrice !== '0.00') {
          updatedPrice = item.bundleCustomPrice;
        }
        if (item.bundleDiscount) {
          updatedPrice = price - (item.bundleDiscount / 100) * price;
        }
        if (!item.bundleCustomPrice && !item.bundleDiscount) {
          updatedPrice = price;
        }
        this.selectableItems.push({
          discountSelection: true,
          variantId: '',
          variantName: '',
          variantSku: item.bundles ? item.bundles[0].bundleSKU : '',
          itemHasImage: item.hasImage,
          itemImageSrc: item.imageSrc,
          // variantPrice: price,
          variantPrice: updatedPrice,
          variantQuantity: 0,
          itemDiscount: 0,
          itemName: item.name,
          itemType: item.itemType,
          itemDescription: item.description,
          itemManufacturer: item.manufacturer,
          itemTaxes: item.taxes!,
          itemId: item.id,
          bundles: item.bundles,
          bundleOutOfStock: item.bundle_out_of_stock,
          bundleTotalStock: item.bundle_total_stock,
          variantStockList: listOfVariantStock,
          bundleLeastStock: item.bundle_least_stock?.least_stock,
          bundleLeastStockVarId: item.bundle_least_stock?.least_variant_id,
          bundleDiscount: item.bundleDiscount ?? null,
          bundleCustomPrice:
            item.bundleCustomPrice !== '0.00' ? item.bundleCustomPrice : null,
          bundleDiscountFinal: item.bundleDiscount
            ? price - (item.bundleDiscount / 100) * price
            : null,
          sellingVariant:
            item.bundles && item.bundles.length > 0
              ? item.bundles[0].variant.mostPurchasedItem
              : 0,
          variantStock: item.bundle_least_stock?.least_stock,
          variantMaxStock: item.bundle_least_stock?.least_stock,
          variantTracking:
            item.bundle_least_stock?.least_stock !== null ? true : false,
          variantUpc: item.bundles ? item.bundles[0].bundleSKU : '',
          variantLocation:
            item.bundles && item.bundles.length > 0
              ? item.bundles[0].variant.variantLocations
              : [],
          bundleVariantsIds,
          isLoading: false,
        });
      }
    } else {
      let totalDiscountInPercentage = 0;
      let totalDiscountInDoller = 0;

      if (item && item.itemDiscount && item.itemDiscount.length > 0) {
        for (let index = 0; index < item.itemDiscount.length; index++) {
          if (
            item.itemDiscount[index].discount.discountType.data[0] ===
            DiscountType.Percentage
          ) {
            if (
              (item.itemDiscount[index].discount.discountFrom &&
                item.itemDiscount[index].discount.discountTo &&
                item.itemDiscount[index].discount.applyToAllGoods) ||
              (item.itemDiscount[index].discount.discountFrom &&
                item.itemDiscount[index].discount.discountTo &&
                item.itemDiscount[index].discount.applyToSpecificItems)
            ) {
              const discountFromDate = new Date(
                item.itemDiscount[index].discount.discountFrom,
              );
              discountFromDate.setHours(0, 0, 1);
              const discountToDate = new Date(
                item.itemDiscount[index].discount.discountTo,
              );
              discountToDate.setHours(23, 59, 59);
              const currentDate = new Date();
              if (
                currentDate >= discountFromDate &&
                currentDate <= discountToDate
              ) {
                totalDiscountInPercentage =
                  totalDiscountInPercentage +
                  parseFloat(item.itemDiscount[index].discount.value);
              }
            } else if (item.itemDiscount[index].discount.applyToSpecificItems) {
              totalDiscountInPercentage =
                totalDiscountInPercentage +
                parseFloat(item.itemDiscount[index].discount.value);
            } else if (item.itemDiscount[index].discount.applyToAllGoods) {
              totalDiscountInPercentage =
                totalDiscountInPercentage +
                parseFloat(item.itemDiscount[index].discount.value);
            }
          } else {
            if (
              (item.itemDiscount[index].discount.discountFrom &&
                item.itemDiscount[index].discount.discountTo &&
                item.itemDiscount[index].discount.applyToAllGoods) ||
              (item.itemDiscount[index].discount.discountFrom &&
                item.itemDiscount[index].discount.discountTo &&
                item.itemDiscount[index].discount.applyToSpecificItems)
            ) {
              const discountFromDate = new Date(
                item.itemDiscount[index].discount.discountFrom,
              );
              discountFromDate.setHours(0, 0, 1);
              const discountToDate = new Date(
                item.itemDiscount[index].discount.discountTo,
              );
              discountToDate.setHours(23, 59, 59);
              const currentDate = new Date();
              if (
                currentDate >= discountFromDate &&
                currentDate <= discountToDate
              ) {
                totalDiscountInDoller =
                  totalDiscountInDoller +
                  parseFloat(item.itemDiscount[index].discount.value);
              }
            } else if (item.itemDiscount[index].discount.applyToSpecificItems) {
              totalDiscountInDoller =
                totalDiscountInDoller +
                parseFloat(item.itemDiscount[index].discount.value);
            } else if (item.itemDiscount[index].discount.applyToAllGoods) {
              totalDiscountInDoller =
                totalDiscountInDoller +
                parseFloat(item.itemDiscount[index].discount.value);
            }
          }
        }
      }
      if (item.variants) {
        item.variants.forEach((variant: any) => {
          // code for excluding out of stock items
          const isVariantTrackingEnable =
            variant.variantLocations.length > 0
              ? variant.variantLocations[0].isInventoryTrackingEnabled
              : false;

          if (!isVariantTrackingEnable) {
            variant.variantLocations[0].currentStock = Number.POSITIVE_INFINITY;
          }
          if (this.filterByInStock) {
            const inStock =
              variant.variantLocations.length > 0
                ? variant.variantLocations[0].currentStock > 0
                  ? true
                  : false
                : false;
            if (inStock || item.itemType === 2) {
              const variantIdExist = this.selectableItems.some(
                (item) => item.variantId === variant.id,
              );

              if (!variantIdExist) {
                this.selectableItems.push({
                  discountSelection: true,
                  variantId: variant.id!,
                  variantName: variant.name,
                  variantSku: variant.sku,
                  itemHasImage: item.hasImage,
                  itemImageSrc: item.imageSrc,
                  variantPrice: variant.price,
                  variantWholesalePrice: Number(variant.wholesalePrice),
                  variantTotalWholesalePrice: Number(variant.wholesalePrice),
                  variantQuantity: 0,
                  itemDiscount: 0,
                  itemName: item.name,
                  itemType: item.itemType,
                  itemDescription: item.description,
                  itemManufacturer: item.manufacturer,
                  itemTaxes: item.taxes!,
                  itemId: item.id,
                  variantStock:
                    variant.variantLocations.length > 0
                      ? variant.variantLocations[0].currentStock
                      : 0,
                  variantMaxStock:
                    variant.variantLocations.length > 0
                      ? variant.variantLocations[0].currentStock
                      : 0,
                  variantTracking:
                    variant.variantLocations.length > 0
                      ? variant.variantLocations[0].isInventoryTrackingEnabled
                      : false,
                  variantUpc: variant.upc,
                  sellingVariant: variant.mostPurchasedItem,
                  variantLocation: variant.variantLocations,
                  variantDiscountedPrice:
                    variant.price * (totalDiscountInPercentage / 100) +
                    totalDiscountInDoller,
                  isLoading: false,
                });
              }
            }
          } else {
            const variantIdExist = this.selectableItems.some(
              (item) => item.variantId === variant.id,
            );

            if (!variantIdExist) {
              this.selectableItems.push({
                discountSelection: true,
                variantId: variant.id!,
                variantName: variant.name,
                variantSku: variant.sku,
                itemHasImage: item.hasImage,
                itemImageSrc: item.imageSrc,
                variantPrice: variant.price,
                variantWholesalePrice: Number(variant.wholesalePrice),
                variantTotalWholesalePrice: Number(variant.wholesalePrice),
                variantQuantity: 0,
                itemDiscount: 0,
                itemName: item.name,
                itemType: item.itemType,
                itemDescription: item.description,
                itemManufacturer: item.manufacturer,
                itemTaxes: item.taxes!,
                itemId: item.id,
                variantStock:
                  variant.variantLocations.length > 0
                    ? variant.variantLocations[0].currentStock
                    : 0,
                variantMaxStock:
                  variant.variantLocations.length > 0
                    ? variant.variantLocations[0].currentStock
                    : 0,
                variantTracking:
                  variant.variantLocations.length > 0
                    ? variant.variantLocations[0].isInventoryTrackingEnabled
                    : false,
                variantUpc: variant.upc,
                sellingVariant: variant.mostPurchasedItem,
                variantLocation: variant.variantLocations,
                variantDiscountedPrice:
                  variant.price * (totalDiscountInPercentage / 100) +
                  totalDiscountInDoller,
                isLoading: false,
              });
            }
          }
        });
      }
    }
  }

  reOrderProcessedItems() {
    const { sortBy, sortType } = this.$store.getters.itemFilter;
    if (sortBy.includes('name')) {
      this.selectableItems = orderBy(
        this.selectableItems,
        (o: any) => o.itemName,
        [sortType.toLowerCase()],
      );
    } else if (sortBy.includes('price')) {
      this.selectableItems = orderBy(
        this.selectableItems,
        (o: any) => Number(o.variantPrice),
        [sortType.toLowerCase()],
      );
    } else if (sortBy.includes('mostPurchased')) {
      this.selectableItems = orderBy(
        this.selectableItems,
        (o: any) => Number(o.sellingVariant),
        [sortType.toLowerCase()],
      );
    }
  }

  isInputNumber(event: any) {
    const char = String.fromCharCode(event.which);
    if (!/^[0-9]*\.?[0-9]*$/.test(char)) {
      event.preventDefault();
    }
  }

  displayNotification(type: 'success' | 'error', msg: string, title = '') {
    this.activateNotification = true;
    this.typeNotification = type;
    this.titleNotification = title;
    this.messageNotification = msg;
  }

  async changeView() {
    if (this.pageView) {
      this.pageView = false;
    } else {
      this.pageView = true;
    }
  }

  applyTax(event) {
    this.newOrder.variantSnapshots = [...event.newItems];
    this.updateCalcs();
    this.displayNotification('success', 'Tax succesfully applied');
  }

  applyDiscount(event) {
    event.newItems.map((item) => {
      if (item.discountSelection) {
        item.discountId = event.discountSelection.id;
      } else {
        item.discountId = '';
      }
      return item;
    });
    this.newOrder.variantSnapshots = [...event.newItems];
    this.addDiscount(event.discountSelection);
  }
}
