
import { defineComponent, watch } from 'vue';
import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import CategoryService from '@/services/CategoryService';
import ItemService from '@/services/ItemService';

export default defineComponent({
  name: 'CategoryListCreateOrder',
  created() {
    this.roleService = new RoleService(new LocalStorageService());
    this.localStorageService = new LocalStorageService();
    this.categoryService = new CategoryService();
    this.itemService = new ItemService();
  },
  props: ['locationId'],
  async mounted() {
    await this.getAllCategory(this.locationId);
    watch(
      () => this.locationId,
      (newLocationId, oldLocationId) => {
        if (newLocationId !== oldLocationId) {
          this.getAllCategory(newLocationId);
        }
      },
    );
  },

  data() {
    return {
      categoryList: [],
      page: 1,
      pageSize: 200,
      orderBy: 'name',
    };
  },
  methods: {
    async getAllCategory(location) {
      this.loading = true;
      if (location) {
        const locationId = location;
        const result = await this.categoryService.getAllCategories(
          locationId,
          this.page,
          this.pageSize,
          this.orderBy,
        );
        this.categoryList = result.data.categories;
        this.noRecord = this.categoryList.length ? false : true;
      }
      this.loading = false;
    },

    cardClickEvent(category) {
      this.$emit('productCategoryEvent', category);
    },
  },
});
