
import axios from 'axios';
import { onMounted, reactive, toRefs } from 'vue';

export default {
  name: 'SelectCustomerModal',
  emits: ['closed', 'customer-selected-on-list'],
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    // -- Vars
    const data = reactive({
      page: 1,
      searchTimeout: null,
      filter: {
        firstName: '',
        lastName: '',
        accountNumber: '',
      },
      firstName: '',
      lastName: '',
      accountNumber: '',
      lastPage: 0,
      countText: 0,
      customers: [],
      loadingData: false,
      loadingCustomers: false,
      flagToNotRepeatREquest: false,
      endpoint: `${process.env.VUE_APP_BASE_URL}/customer?locationId=${props.locationId}`,
    });

    // -- lifecycle
    onMounted(() => {
      document.body.style.overflow = 'hidden';
      loadCustomers();
      endOfScroll();
    });

    // -- Methods

    // Infinity scroll to fetch all customers
    const endOfScroll = () => {
      const element = window.document.getElementById('customerContent');
      let lastScrollTop = 0;
      element.onscroll = async (e) => {
        if (element.scrollTop < lastScrollTop) {
          lastScrollTop = element.scrollTop - 1;
          return;
        }
        lastScrollTop = element.scrollTop <= 0 ? 0 : element.scrollTop;
        if (element.scrollTop + element.offsetHeight >= element.scrollHeight) {
          data.page++;
          data.flagToNotRepeatREquest = false;
          if (data.page <= data.lastPage && !data.flagToNotRepeatREquest) {
            try {
              data.flagToNotRepeatREquest = true;
              data.loadingCustomers = true;
              const response = await axios.get(data.endpoint, {
                params: {
                  page: data.page,
                  lastName:
                    data.lastName.trim() !== '' &&
                    data.lastName.trim().length >= 1
                      ? data.lastName.trim()
                      : null,
                  firstName:
                    data.firstName.trim() !== '' &&
                    data.firstName.trim().length >= 1
                      ? data.firstName.trim()
                      : null,
                  accountNumber:
                    data.accountNumber.trim() !== '' &&
                    data.accountNumber.trim().length >= 1
                      ? data.accountNumber.trim()
                      : null,
                },
              });
              data.customers.push(...response.data.customer);
              data.loadingCustomers = false;
            } catch (error) {
              console.error(error);
              data.loadingCustomers = false;
            }
          }
        }
      };
    };

    const loadCustomers = async (search?: boolean) => {
      try {
        data.page = 1;
        data.loadingData = true;
        let queryParams = {};

        if (search) {
          data.filter.lastName =
            data.lastName.trim() !== '' && data.lastName.trim().length >= 1
              ? data.lastName.trim()
              : null;
          data.filter.firstName =
            data.firstName.trim() !== '' && data.firstName.trim().length >= 1
              ? data.firstName.trim()
              : null;
          data.filter.accountNumber =
            data.accountNumber.trim() !== '' &&
            data.accountNumber.trim().length >= 1
              ? data.accountNumber.trim()
              : null;
          queryParams = data.filter;
        }

        const response = await axios.get(data.endpoint, {
          params: {
            page: data.page,
            ...queryParams,
          },
        });
        data.customers = response.data.customer;
        data.lastPage = response.data.lastPage;
        data.loadingData = false;
      } catch (error) {
        data.loadingData = false;
        console.error(error);
        data.customers = [];
      }
    };

    const handleSearchField = async (event: any) => {
      if (data.searchTimeout) {
        clearTimeout(data.searchTimeout);
      }
      data.searchTimeout = setTimeout(() => {
        loadCustomers(true);
      }, 1000);
    };

    const selectCustomer = (customer: any) => {
      document.body.style.overflow = 'auto';
      context.emit('customer-selected-on-list', customer);
      context.emit('closed');
    };

    const close = () => {
      document.body.style.overflow = 'auto';
      context.emit('closed');
    };

    return {
      ...toRefs(data),
      close,
      selectCustomer,
      handleSearchField,
    };
  },
};
