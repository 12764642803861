
import { Options, Vue } from 'vue-class-component';
import axios, { AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import router from '@/router';
import OrderSummary from '@/components/OrderSummary.vue';
import SavedPaymentModule from '@/components/SavedPaymentModule.vue';
import TipOptions from '@/components/TipOptions.vue';
import SelectedCustomer from '@/components/SelectedCustomer.vue';
import QuoteOption from '@/components/QuoteOption.vue';
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import SelectCustomerModal from '@/components/SelectCustomerModal.vue';
import AddTaxButton from '@/shared/AddTaxButton.vue';
import ItemService from '@/services/ItemService';
import AddDiscountButton from '@/shared/AddDiscountButton.vue';
import ModalContainer from '@/shared/ModalContainer.vue';
import FortisTransaction from '../Interfaces/FortisTransaction';
import { PaymentType } from '@/enums/PaymentType';
import { TransactionType } from '@/enums/TransactionType';
import OrderService from '@/services/OrderService';
import Stepper from '@/components/Stepper.vue';
import DepositAccountService from '@/services/DepositAccountService';

@Options({
  props: [
    'newOrder',
    'calcResults',
    'roleService',
    'selectedCustomer',
    'isMarkAsPaidDisabled',
    'isSuperadminLoc',
    'isMarkAsPaidClicked',
    'depositeOptions',
    'payformVisible',
    'savedPaymentFormVisible',
    'isProcessingSavedCard',
    'savedPaymentMethods',
    'showDiscountButton',
    'isDiscountDisabled',
    'popoverShow',
    'locationDiscountOptions',
    'locationDiscounts',
    'selectedLocation',
    'selectedLocationId',
    'clickoncharger',
    'iFrameUrl',
    'iFrameUrlError',
    'showimport',
    'quotes',
    'selectedQuoteId',
    'loaderForQuote',
    'iframeshow',
    'iframeshowurl',
    'is_product_accountvault',
    'paymentDetailsloading',
    'orderTransactions',
    'orderId',
  ],
  components: {
    OrderSummary,
    SavedPaymentModule,
    TipOptions,
    SelectedCustomer,
    QuoteOption,
    SelectCustomerModal,
    AddTaxButton,
    AddDiscountButton,
    ModalContainer,
    Stepper,
  },
  emits: [
    'update-selected-customer',
    'update-quantity',
    'remove-from-order-tab',
    'reset-order',
    'create-order',
    'use-saved-card',
    'use-pay-form',
    'submit-payment-with-saved-card',
    'add-discount',
    'close-payment',
    'initiate-charge',
    'change-quote',
    'get-current-date',
    'get-uuid',
    'location-name',
    'location-address',
    'location-email',
    'customer-account',
    'customer-name',
    'customer-address',
    'customer-email',
    'get-item-type-initial-value',
    'get-price',
    'get-cost',
    'currency',
    'save-quote',
    'remove-discount',
    'update-calcs',
    'payform-listener',
    'finish-transaction',
    'select-all-items',
    'on-adding-tax',
    'get-order-transactions',
  ],
  watch: {
    currentPaymentIndex: {
      handler: 'getSplitTotal',
      immediate: true,
    },
  },
})
export default class CheckoutProcess extends Vue {
  lastAmountSplit = 0;
  isLoading: boolean = false;
  customerValue = '';
  itemsService = new ItemService();
  showSelectCustomerModal = false;
  isCashPaymentMethod = false;
  isAchPaymentMethod = false;
  currentPaymentMethodId = '';
  step: any = 1;
  show_submodule: any = 1;
  newOrder = new CreateOrderRequest();
  depositeOptions: any = null;
  showCartBtn = false;
  calcResults: any = null;
  roleService: any = null;
  selectedCustomer: any = null;
  customers: any[] = [];
  selectedCustomerId: any = null;
  customerOptions: any[] = [];
  isMarkAsPaidDisabled: any = null;
  isSuperadminLoc: any = null;
  isMarkAsPaidClicked: any = null;
  showDiscountButton: any = null;
  isDiscountDisabled: any = null;
  popoverShow: any = null;
  selectedLocation: any = null;
  selectedLocationId: any = null;
  locationDiscountOptions: any = null;
  is_product_accountvault = false;
  paymentDetailsloading = false;
  isProcessingSavedCard: boolean;
  showErrorMsg = false;
  errorMsg = '';
  activateNotification = false;
  typeNotification = '';
  titleNotification = '';
  messageNotification = '';
  showPayForms = false;
  numberOfSplit = 0;
  currentPaymentIndex = 0;
  paymentMethodSelected = '';
  orderTransactions = [];
  transactionApiId = '';
  orderId = '';
  showDeleteTransactionModal = false;
  isDeleting = false;
  showCancelOrderModal = false;
  isOrderCancel = false;
  orderService = new OrderService();
  depositAccountService = new DepositAccountService();
  paymentType = PaymentType;
  showAddNewPaymentButton = false;
  listOfDepositAccounts: [];
  filteredDepositAccounts: any;
  loading: boolean = false;
  moreDepositAccount: boolean = false;
  noResult: boolean = false;
  searchDefaultDeposit ='';
  emptyDepositAccount: boolean = false;
  finishTransactionButton: boolean = false;
  disableCheckoutItems:boolean = false;

  get isTransactionInProgress() {
    return this.$store.getters.getIsTransactionInProgress;
  }

  get steps() {
    return [
      {
        order: 1,
        title: 'Order Summary',
        completed: this.step > 1 ? true : false,
        current: this.step == 1 ? true : false
      },
      {
        order: 2,
        title: 'Deposit account',
        completed: this.step > 6 ? true : false,
        current: this.step < 6 && this.step > 1 ? true : false

      },
      {
        order: 3,
        title: 'Payment',
        completed: this.step > 7 ? true : false,
        current: this.step == 7 ? true : false
      },
    ];
  }

  get customerSelected() {
    return (
      (!this.selectedCustomer || !this.newOrder.customerId) &&
      this.newOrder.isAchPaymentMethod
    );
  }

   async getSplitTotal() {  
    this.isLoading = true;
    
    if (Number(this.numberOfSplit) !== 0 && Number(this.currentPaymentIndex >= 1)) {
      let divisionResult = (this.calcResults.total / this.numberOfSplit).toFixed(2);

      if (Number(this.currentPaymentIndex) === Number(this.numberOfSplit)) {
        const orderPayments = await this.getOrderTransactions(this.orderId);
        const totalAmount = orderPayments.map((payment) => Number(payment.paymentAmount)).reduce((a: number, b: number) => a + b, 0);

        divisionResult = (this.calcResults.total - Number(totalAmount)).toFixed(2);
      }

      this.isLoading = false;
      this.lastAmountSplit = Number(divisionResult);
      return;
    }

    this.isLoading = false;
    this.lastAmountSplit = this.calcResults.total;

    return;
  }

  goToTransactionPage(orderId: string) {
    router.push({
      path: `/orders/${orderId}`,
      query: { locationId: this.$store.getters.selectedLocationId },
    });
  }

  async deleteOrderTransactions() {
    this.isDeleting = true;
    const transaction = this.orderTransactions.find(
      (transaction) => transaction.transactionApiId === this.transactionApiId,
    );

    const fortisTransaction: FortisTransaction = {
      transaction_api_id: this.transactionApiId,
      transaction_amount: transaction.paymentAmount,
      location_id: transaction.transaction.locationId,
      void_type: undefined,
      depositAccountId: this.newOrder.depositAccountId,
      depositAccountTitle: this.newOrder.depositAccountTitle,
    };

    try {
      await axios.delete(
        `${process.env.VUE_APP_BASE_URL}/transaction/order-payment/${this.orderId}?transactionApiId=${this.transactionApiId}`,
      );

      if (transaction.transaction.accountType === 'cash') {
        await this.refundCashTransaction(fortisTransaction);
      } else if (transaction.transaction.isRefundable === true) {
        await this.refundCcOrAchTransaction(
          fortisTransaction,
          transaction.accountType,
        );
      } else {
        fortisTransaction.void_type = 'Void';
        await this.voidTransaction(
          fortisTransaction,
          transaction.transaction.id,
        );
      }

      this.renderOrderTransactions();
      this.showSuccessMessage('Transaction deleted successfully!');
      this.transactionApiId = '';
      if (this.orderTransactions.length === 1) {
        await this.cancelOrder();
        this.showDeleteTransactionModal = false;
        this.isDeleting = false;
      } else {
        this.isDeleting = false;
        this.showDeleteTransactionModal = false;
        this.showAddNewPaymentButton = true;
      }
    } catch (error) {
      console.log('error', error);
      this.isDeleting = false;

      this.activateNotification = true;
      this.typeNotification = 'error';
      this.titleNotification = '';
      this.messageNotification = `An error occured when deleting transaction!`;
      setTimeout(() => {
        this.activateNotification = false;
      }, 5000);
    }
  }

  moveToAddNewPayment(){
    this.step = 6;
    this.getSplitTotal();
    this.showAddNewPaymentButton = false;
    this.initiateCharge();
  }

  showSuccessMessage(message: string) {
    this.activateNotification = true;
    this.typeNotification = 'success';
    this.titleNotification = '';
    this.messageNotification = message;
    setTimeout(() => {
      this.activateNotification = false;
    }, 5000);
  }

  selectDepositOption(value, depositAccountName) {
    this.searchDefaultDeposit = '';
    this.fetchAllDepositAccounts()
    this.newOrder.product_transaction_id = value;
    this.newOrder.depositAccountId = value;
    this.newOrder.depositAccountTitle = depositAccountName;
    this.paymentMethodSelected = value;
    this.initiateCharge();
  }

  

  async refundCashTransaction(fortisTransaction: FortisTransaction) {
    const RefundCashResponse: AxiosResponse<any> = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/fortis/transaction?refund=cash`,
      fortisTransaction,
    );

    return RefundCashResponse;
  }

  async refundCcOrAchTransaction(
    fortisTransaction: FortisTransaction,
    paymentLabel: string,
  ) {
    const refundResponse = await axios.post(
      `${process.env.VUE_APP_BASE_URL}/fortis/transaction?refund=${paymentLabel}`,
      fortisTransaction,
    );

    return refundResponse;
  }

  async voidTransaction(fortisTransaction: FortisTransaction, id: string) {
    const voidResponse = await axios.put(
      `${process.env.VUE_APP_BASE_URL}/fortis/transaction/${id}/void`,
      fortisTransaction,
    );

    return voidResponse;
  }

  async renderOrderTransactions() {
    const newOrderTransactions = await this.getOrderTransactions(this.orderId);
    this.orderTransactions.splice(0, this.orderTransactions.length);
    this.orderTransactions.push(...newOrderTransactions);
    this.currentPaymentIndex = newOrderTransactions.length + 1;
    this.$forceUpdate();
  }

  async getOrderTransactions(orderId: string) {
    try {
      const orderResult = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/transaction/order-payment/${orderId}`,
      );

      return orderResult.data.orderPayments;
    } catch (error) {
      console.log(error);
    }
  }

  async cancelOrder() {
    this.isOrderCancel = true;
    const order = await this.getOrder();
    this.showAddNewPaymentButton = false;

    const refundOrder =
      order.paymentType === PaymentType.Cash
        ? this.createRefundOrder(PaymentType.Cash, order)
        : order.paymentType === PaymentType.CreditCard
        ? this.createRefundOrder(PaymentType.CreditCard, order)
        : this.createRefundOrder(PaymentType.Ach, order);

    let apiResponse;
    try {
      apiResponse = await this.dispatchCashRefund(refundOrder);
      if (apiResponse.status === 200) {
        if (this.orderTransactions.length > 0) {
          await this.orderTransactions.forEach(async (transaction: any) => {
            const fortisTransaction: FortisTransaction = {
              transaction_api_id: transaction.transactionApiId,
              transaction_amount: transaction.paymentAmount,
              location_id: transaction.transaction.locationId,
              void_type: undefined,
              depositAccountId: this.newOrder.depositAccountId,
              depositAccountTitle: this.newOrder.depositAccountTitle,
            };
            if (transaction.transaction.accountType === 'cash') {
              await this.refundCashTransaction(fortisTransaction);
            } else {
              fortisTransaction.void_type = 'Void';
              await this.voidTransaction(
                fortisTransaction,
                transaction.transaction.id,
              );
            }
          });
        }
        this.isOrderCancel = false;
        this.goToTransactionPage(refundOrder.id);
      }
    } catch (err) {
      apiResponse = err.response;
      console.log('err', err);
      this.isOrderCancel = false;

      this.activateNotification = true;
      this.typeNotification = 'error';
      this.titleNotification = '';
      this.messageNotification = `An error occured when canceling order!`;
      setTimeout(() => {
        this.activateNotification = false;
      }, 5000);
    }
  }

  createRefundOrder(paymentType: PaymentType, order) {
    const newOrder = new CreateOrderRequest();
    newOrder.paymentType = paymentType;
    newOrder.transactionType = TransactionType.Void;
    newOrder.customer = order.customer;
    newOrder.location = order.location;
    newOrder.relatedOrder = order.id;
    newOrder.id = v4();

    if (order.discounts && order.discounts.length > 0) {
      order.discounts[0].id = undefined;
      newOrder.discounts = order.discounts;
    }
    newOrder.variantSnapshots = order.variantSnapshots;
    return newOrder;
  }

  async getOrder() {
    try {
      const res = await this.orderService.getOrderDetails(
        this.orderId,
        this.$store.getters.selectedLocationId,
      );
      return res.data.order;
    } catch (error) {
      console.log(error);
    }
  }

  async dispatchCashRefund(order: CreateOrderRequest) {
    return await axios.post(`${process.env.VUE_APP_BASE_URL}/order`, order);
  }

  nextPayment() {
    if (this.currentPaymentIndex <= this.numberOfSplit) {
      this.currentPaymentIndex++;
      this.$emit(
        'initiate-charge',
        this.numberOfSplit,
        this.currentPaymentIndex,
      );
      this.showPayForms = false;
      this.show_submodule = 4;
    }
  }

  setSplitPayment() {
    this.splitPayment(this.numberOfSplit);
    this.currentPaymentIndex = 1;
    this.getSplitTotal();
  }

  splitPayment(numberOfSplits: number) {
    this.numberOfSplit = Number(numberOfSplits);
    this.step = 6;
  }

  usePayformSplitPayment() {
    this.showPayForms = true;
    this.show_submodule = 6;
    window.addEventListener(
      'message',
      (event) => {
        this.$emit('payform-listener', event);
      },
      { once: true },
    );
    this.$emit('use-pay-form', this.currentPaymentIndex);
  }

  useSavedCardSplitPayment() {
    // this.nextclick();
    this.showPayForms = true;
    this.show_submodule = 5;
    this.$emit('use-saved-card', this.currentPaymentIndex);
  }

  handleInput() {
      const searchQuery = this.searchDefaultDeposit.toLowerCase();
      this.filteredDepositAccounts = this.listOfDepositAccounts.filter(
        (account: any) =>
          account.title.toLowerCase().includes(searchQuery) ||
          account.id.toLowerCase().includes(searchQuery),
      );
      if (this.filteredDepositAccounts.length == 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
    }

   async fetchAllDepositAccounts() {
    this.loading = true;
     try {
      const resultAllDepositAccount = await this.depositAccountService.getAllDepositAccount(this.selectedLocationId);
      this.listOfDepositAccounts = resultAllDepositAccount.data.depositAccounts;
      this.filteredDepositAccounts = this.listOfDepositAccounts;
       this.moreDepositAccount = this.listOfDepositAccounts.length > 7;
       this.emptyDepositAccount = this.filteredDepositAccounts.length == 0;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    this.getSelectedCustomer(this.selectedCustomer);
    this.fetchAllDepositAccounts();
    this.depositeOptions.forEach((element: any) => {
      if (
        element.value === this.newOrder.product_transaction_id &&
        element.paymentMethod === 'cash'
      ) {
        this.isCashPaymentMethod = true;
        this.isAchPaymentMethod = false;
      } else if (
        element.value === this.newOrder.product_transaction_id &&
        element.paymentMethod === 'cc'
      ) {
        this.isAchPaymentMethod = false;
        this.isCashPaymentMethod = false;
      }
      if (
        element.value === this.newOrder.product_transaction_id &&
        element.paymentMethod === 'ach'
      ) {
        this.isAchPaymentMethod = true;
        this.isCashPaymentMethod = false;
      }
    });
    this.newOrder.isCashPaymentMethod = this.isCashPaymentMethod;
    this.newOrder.isAchPaymentMethod = this.isAchPaymentMethod;
  }
  zIndex = 2000;
  updateCalcs() {
    this.$emit('update-calcs');
  }
  currency(value: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  }
  nextclick() {
    this.step++;
  }

  get disableTip() {
    const order: any = this.newOrder;
    let flag = true;
    order.variantSnapshots.filter((item: any) => {
      if (item.itemType === 2) {
        flag = false;
      }
    });
    return flag || this.isProcessingSavedCard;
  }
  previousclick() {
    this.step--;
  }

  updateQuantity(item: any, type: string) {
    this.$emit('update-quantity', item, type);
  }

  removeFromOrder(item: any) {
    this.$emit('remove-from-order-tab', item);
  }

  async checkout() {
     await this.finalStockCheck();
    this.nextclick();
  }

  finishTransaction(){
    this.loading =true;
    document.body.style.overflow = 'visible';
    this.disableCheckoutItems = true;
    this.$emit('finish-transaction', this.newOrder);
  }

  async finalStockCheck() {
    if (this.step == 2 || this.step == 1) {
      const newOrderSnapshotwww = this.newOrder.variantSnapshots;
      const snapshots = Array.isArray(newOrderSnapshotwww)
        ? newOrderSnapshotwww
        : [newOrderSnapshotwww];
      for (const i of snapshots) {
        const itemId = i.itemId;
        const locationId = this.$store.getters.selectedLocationId;
        const itemPromise = await this.itemsService.getItemById(
          itemId,
          locationId,
        );
        const itemType = itemPromise.data.itemType;
        const checkArray =
          itemType === 3 ? itemPromise.data.bundles : itemPromise.data.variants;

        if (
          itemType === 3 &&
          checkArray.every((item) =>
            item.variant.variantLocations.every(
              (location) =>
                location.currentStock < 1 &&
                location.isInventoryTrackingEnabled,
            ),
          )
        ) {
          this.showErrorMessage(itemPromise.data.name);
          return;
        } else {
          this.showErrorMsg = false;
        }

        if ( itemType === 1 &&
          checkArray.every((variant) =>
            variant.variantLocations.every(
              (location) =>
                location.currentStock < 1 &&
                location.isInventoryTrackingEnabled,
            ),
          )
        ) {
          this.showErrorMessage(itemPromise.data.name);
          return;
        } else {
          this.showErrorMsg = false;
        }
      }
    }
  }

  async initiateCharge() {
    await this.finalStockCheck();
    this.showPayForms = false;
    this.depositeOptions.forEach((element: any) => {
      if (
        element.value === this.newOrder.product_transaction_id &&
        element.paymentMethod === 'cash'
      ) {
        this.isCashPaymentMethod = true;
        this.isAchPaymentMethod = false;
      } else if (
        element.value === this.newOrder.product_transaction_id &&
        element.paymentMethod === 'cc'
      ) {
        this.isAchPaymentMethod = false;
        this.isCashPaymentMethod = false;
      }
      if (
        element.value === this.newOrder.product_transaction_id &&
        element.paymentMethod === 'ach'
      ) {
        this.isAchPaymentMethod = true;
        this.isCashPaymentMethod = false;
      }
    });
    this.newOrder.isCashPaymentMethod = this.isCashPaymentMethod;
    this.newOrder.isAchPaymentMethod = this.isAchPaymentMethod;
    this.$emit('initiate-charge', this.numberOfSplit, this.currentPaymentIndex);
  }

  resetOrder() {
    this.$emit('reset-order');
  }

  createOrder() {
    this.showCartBtn = true;
    this.zIndex = 100;
    // PaymentType Cash = 2
    this.$emit('create-order', 2, this.numberOfSplit, this.currentPaymentIndex);
  }

  useSavedCard() {
    // this.nextclick();
    this.showPayForms = true;
    this.show_submodule = 5;
    this.$emit('use-saved-card');
  }
  usePayform() {
    // this.nextclick();
    this.showPayForms = true;
    this.show_submodule = 6;
    window.addEventListener(
      'message',
      (event) => {
        this.$emit('payform-listener', event);
      },
      { once: true },
    );
    this.$emit('use-pay-form');
  }
  errorTimeout() {
    setTimeout(() => {
      this.showErrorMsg = false;
      this.errorMsg = '';
    }, 3000);
  }

  showErrorMessage(productName) {
    this.activateNotification = true;
    this.typeNotification = 'error';
    this.titleNotification = '';
    this.messageNotification = `Sorry,${productName} is no longer in stock`;
    setTimeout(() => {
      this.activateNotification = false;
    }, 5000);
    this.previousclick();
  }

  submitPaymentWithSavedCard(payment: any) {
    this.showCartBtn = true;
    this.$emit('submit-payment-with-saved-card', payment);
  }

  addDiscount(emitdiscount: any) {
    this.goToOrder();
    this.$emit('add-discount', emitdiscount);
  }
  closePayment() {
    if (this.step === 7) {
      this.showCancelOrderModal = true;
    } else {
      document.body.style.overflow = 'visible';
      this.$emit('close-payment');
    }
  }
  changeQuote() {
    this.$emit('change-quote');
  }
  getcurrentdate() {
    this.$emit('get-current-date');
  }
  getuuid() {
    this.$emit('get-uuid');
  }
  locationName() {
    this.$emit('location-name');
  }
  locationAddress() {
    this.$emit('location-address');
  }
  locationEmail() {
    this.$emit('location-email');
  }
  customerAccount() {
    this.$emit('customer-account');
  }
  customerName() {
    this.$emit('customer-name');
  }
  customerAddress() {
    this.$emit('customer-address');
  }
  customerEmail() {
    this.$emit('customer-email');
  }
  getItemTypeInitialValue(variant: any) {
    this.$emit('get-item-type-initial-value', variant);
  }
  getPrice(price: any) {
    this.$emit('get-price', price);
  }
  getCost(quantity: any, price: any) {
    this.$emit('get-cost', quantity, price);
  }

  saveQuote() {
    this.$emit('save-quote');
  }

  removeDiscount() {
    this.$emit('remove-discount');
  }

  goToOrder() {
    this.step = 1;
    this.show_submodule = 1;
  }

  selectAllItems() {
    this.$emit('select-all-items');
  }

  clearCustomer() {
    this.customerValue = '';
    this.selectedCustomerId = null;
    this.$emit('update-selected-customer', null);
    this.$forceUpdate();
  }

  getSelectedCustomer(customer: any) {
    if (customer !== null && customer != undefined) {
      this.customerValue =
        customer.first_name +
        ' ' +
        customer.last_name +
        ' - Acc #: ' +
        customer.account_number;
      this.selectedCustomerId = customer.id;
      this.$emit('update-selected-customer', customer);
      this.$forceUpdate();
    } else {
      this.customerValue = '';
      this.selectedCustomerId = null;
      this.$emit('update-selected-customer', null);
      this.$forceUpdate();
    }
  }
}
