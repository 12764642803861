import axios, { AxiosResponse } from 'axios';

export default class DepositAccountService {
     BASE_URL = `${process.env.VUE_APP_BASE_URL}/deposit-account`;

     getAllDepositAccount(locationId: string): Promise<AxiosResponse> {
          return axios.get(`${this.BASE_URL}`, {
               params: {
                    locationId,
               },
          });
     }
}
