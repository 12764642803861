
import { Options, Vue } from 'vue-class-component';
import { ItemType } from '@/enums/ItemType';
import CreateDiscountPopoverService from '../services/createDiscountPopoverService';
import { OrderCalculationService } from '@/services/OrderCalculationService';

@Options({
  props: ['variant', 'location'],
  emits: ['add-to-order', 'removed-from-cart', 'remove-from-order'],
  providers: [CreateDiscountPopoverService, OrderCalculationService],
  filters: {},
})
export default class ProductCard extends Vue {
  discountService = new CreateDiscountPopoverService();
  calcService = new OrderCalculationService();
  itemType = ItemType;
  variant: any;
  location: any;
  showBundles = false;
  currency(value: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  }

  getItemTypeValue(data: any) {
    if (data.itemType === ItemType.Good) {
      return 'Goods';
    } else if (data.itemType === ItemType.Service) {
      return 'Services';
    }
  }

  getPhotoSrc(): string {
    if (this.variant.itemHasImage && this.variant.itemImageSrc) {
      return this.variant.itemImageSrc;
    } else {
      return '';
    }
  }

  isTopPickVariant(toppick: any): string {
    if (toppick.length > 0 && toppick[0].isTop) {
      return 'warning';
    }
    return '';
  }

  isTopPickVar(toppick: any): boolean {
    return !!(toppick.length > 0 && toppick[0].isTop);
  }
  addToOrder(item: any) {
    if (item.itemType === ItemType.Bundle) {
      const variants: any = [];
      if (item.bundles) {
        for (let i = 0; i < item.bundles.length; i++) {
          const bundleItem: any = { ...item.bundles[i] };
          const itemNew: any = bundleItem.item;
          const variantNew: any = bundleItem.variant;
          const variantStock = Math.min(
            variantNew.variantLocations.map(
              (location) => location.currentStock,
            ),
          );
          const newBundleItem: any = {
            variantId: variantNew.id,
            itemId: itemNew.id,
            itemHasImage: itemNew.hasImage,
            itemImageSrc: itemNew.hasImage
              ? itemNew.itemImageSrc
              : '/image_placeholder.png',
            itemName: itemNew.name,
            itemType: 3,
            itemDiscount: 0,
            itemDescription: itemNew.description,
            itemManufacturer: itemNew.manufacturer,
            variantName: variantNew.name,
            variantSku: variantNew.sku,
            variantPrice: parseFloat(variantNew.price.toString()),
            variantUpc: variantNew.upc,
            variantStockMin: variantStock,
            variantQuantity: 0,
            variantLocation: [],
            bundleDiscount: item.bundleDiscount,
            bundleDiscountFinal: item.bundleDiscountFinal,
            bundleCustomPrice: item.bundleCustomPrice,
          };
          if (variantNew.variantDiscountedPrice) {
            newBundleItem.variantPrice =
              variantNew.variantPrice - variantNew.variantDiscountedPrice;
          }
          variants.push(newBundleItem);
        }
      }
      const productName =
        item.itemName != ''
          ? item.itemName
          : item.itemDescription != ''
          ? item.itemDescription
          : 'Product';
      this.$emit('add-to-order', variants, productName, this.variant);
    } else {
      const newItem: any = {
        variantId: item.variantId,
        itemId: item.itemId,
        itemHasImage: item.itemHasImage,
        itemImageSrc: item.itemHasImage
          ? item.itemImageSrc
          : '/image_placeholder.png',
        itemName: item.itemName,
        itemType: item.itemType,
        itemDiscount: 0,
        itemDescription: item.itemDescription,
        itemManufacturer: item.itemManufacturer,
        variantName: item.variantName,
        variantSku: item.variantSku,
        variantPrice: item.variantPrice,
        variantWholesalePrice: item.variantWholesalePrice,
        variantQuantity: item.variantQuantity,
        itemTaxes: item.itemTaxes,
        variantStock: item.variantStock,
        variantTracking: item.variantTracking,
        variantUpc: item.variantUpc,
        variantLocation: item.variantLocation,
      };
      if (item.variantDiscountedPrice) {
        newItem.variantPrice = item.variantPrice - item.variantDiscountedPrice;
      }
      const productName = item.itemName != '' ? item.itemName : 'Product';
      this.$emit('add-to-order', newItem, productName, this.variant);
    }
  }

  isInStock() {
    switch (this.variant.itemType) {
      case ItemType.Bundle:
        return !(
          this.variant.variantStock !== null &&
          this.variant.variantStock <= 0 &&
          this.variant.variantTracking
        );
      case ItemType.Good:
        return (
          (this.variant.variantStock > 0 && this.variant.variantTracking) ||
          !this.variant.variantTracking
        );
      default:
        return true;
    }
  }
}
