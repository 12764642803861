
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import { Options, Vue } from 'vue-class-component';
import { ItemType } from '@/enums/ItemType';

@Options({
  props: [
    'newOrder',
    'showimport',
    'quotes',
    'selectedQuoteId',
    'loaderForQuote',
    'iframeshow',
    'iframeshowurl',
    'calcResults',
  ],
  data() {
    return {
      ItemType,
    };
  },
  emits: [
    'change-quote',
    'get-current-date',
    'get-uuid',
    'location-name',
    'location-address',
    'location-email',
    'customer-account',
    'customer-name',
    'customer-address',
    'customer-email',
    'get-item-type-initial-value',
    'get-price',
    'get-cost',
    'currency',
    'save-quote',
  ],
})
export default class QuoteOption extends Vue {
  newOrder: any = {};
  showimport = false;
  quotes: any = [];
  selectedQuoteId: any = null;
  loaderForQuote = false;
  iframeshow = false;
  iframeshowurl: any = null;
  calcResults: any = {};
  changeQuote() {
    this.$emit('change-quote');
  }
  getcurrentdate() {
    this.$emit('get-current-date');
  }
  getuuid() {
    this.$emit('get-uuid');
  }
  locationName() {
    this.$emit('location-name');
  }
  locationAddress() {
    this.$emit('location-address');
  }
  locationEmail() {
    this.$emit('location-email');
  }
  customerAccount() {
    this.$emit('customer-account');
  }
  customerName() {
    this.$emit('customer-name');
  }
  customerAddress() {
    this.$emit('customer-address');
  }
  customerEmail() {
    this.$emit('customer-email');
  }
  getItemTypeInitialValue(variant: any) {
    this.$emit('get-item-type-initial-value', variant);
  }
  getPrice(price: any) {
    this.$emit('get-price', price);
  }
  getCost(quantity: any, price: any) {
    this.$emit('get-cost', quantity, price);
  }
  currency(price: any) {
    this.$emit('currency', price);
  }
  saveQuote() {
    this.$emit('save-quote');
  }
}
