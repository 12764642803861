<template>
  <select-customer-modal
    :location-id="locationId ? locationId : selectedLocationId"
    v-if="showSelectCustomerModal"
    @customer-selected-on-list="getSelectedCustomer"
    @closed="showSelectCustomerModal = false"
  />
  <img src="/loading.svg" class="loading" v-if="loading" />
  <div class="quotes-form col-lg-3 pl-2" v-else>
    <div class="quotes-customer pb-3" v-if="createNewQuote">
      <div class="quotes-heading mt-2">Customer Info</div>
      <div class="search-input-customer">
        <i class="material-icons mat-icon">search</i>
        <input
          class="custom-select"
          type="text"
          name="q"
          v-model="customerValue"
          placeholder="Search"
          :readonly="true"
          style="width: 100%; outline: 0"
          @click="showSelectCustomerModal = true"
        />
      </div>
    </div>
    <div class="send-quotes" v-if="createNewQuote">
      <div class="quote-due">
        <div class="quote-sub-header">
          Due On <span style="color: red">*</span> :
        </div>
        <DatePicker
          v-if="!quotetype"
          :id-attr="datepickerSetting.id"
          :name-attr="datepickerSetting.name"
          :class-attr="datepickerSetting.class"
          :value-attr="getDateFormat('date')"
          :placeholder-attr="'YYYY-MM-DD'"
          :year-minus="datepickerSetting.yearMinus"
          :from="datepickerSetting.fromDate"
          :to="datepickerSetting.toDate"
          @value-changed="datepickerSetting.changeEvent"
        />
        <input
          v-else
          v-model="selectedDueDate"
          type="text"
          readonly="true"
          class="search-input"
          style="border: none"
        />
      </div>
    </div>
    <div v-if="!showimport" class="pb-3">
      <div class="clearfix">&nbsp;</div>
      <div>
        <p>
          <span style="font-weight: bold; font-size: large">Status</span>
          <span style="color: red"></span> :
          {{ quoteIsPaid ? 'Paid' : 'Unpaid' }}
        </p>
        <span v-if="order?.order !== null && quoteIsPaid">
          <span style="font-weight: bold; font-size: large">Transaction</span> :
          <router-link
            :to="
              '/orders/' +
              order.order.id +
              '?locationId=' +
              $route.query.locationId
            "
            target="_blank"
            style="text-decoration: underline; color: blue"
            >{{ order.order.id }}</router-link
          >
        </span>
      </div>
      <div class="clearfix">&nbsp;</div>
      <div class="quotes-heading">
        Quote Name <span style="color: red">*</span> :
      </div>
      <div class="row save-quote pl-3" style="width: 90%" v-if="!showimport">
        <div>
          <input
            v-model="quoteName"
            :readonly="quotetype || !createNewQuote"
            class="form-control"
            placeholder="Please Enter Quote Name"
            style="width: 12rem"
          />
        </div>
        <div class="col-lg-2" v-if="!quotetype">
          <button
            v-if="!createNewQuote"
            class="btn btn-primary btn-sm"
            @click="newQuote(true)"
          >
            New Quote
          </button>

          <button
            v-else
            class="btn btn-primary btn-sm"
            @click="saveQuote()"
            :disabled="!quoteName"
          >
            <span class="material-icons">save</span> Save
          </button>
        </div>
      </div>
    </div>
    <div class="send-quotes">
      <div v-if="itemsLength > 0">
        <div
          v-if="
            locationResource.allow_create_quick_invoice &&
            locationResource.allow_update_quick_invoice
          "
        >
          <div v-if="!createNewQuote">
            <div class="quotes-heading">Send quote crated</div>
            <div class="send-quote-info">
              Send via email or text. Payment via clickable link
            </div>

            <div class="quote-email">
              <div class="quote-sub-header" v-if="!createNewQuote">
                Attach PDF quote to clickable link :
              </div>
              <div class="quote-details row" v-if="!createNewQuote">
                <div v-if="locationResource.is_storage_enabled">
                  <div
                    class="row mb-2"
                    v-for="(file, index) in files"
                    :key="index"
                  >
                    <div class="col-md-12">
                      <a
                        :href="file.url + '&access-token=' + accessToken"
                        target="_blank"
                        class="link-attachement"
                      >
                        {{ file.file_name }}
                      </a>
                      <button
                        @click="confirmDeleteFile(file.id)"
                        v-if="
                          !this.quoteIsPaid &&
                          locationResource.allow_delete_files
                        "
                      >
                        <span class="material-icons">delete</span>
                      </button>
                    </div>
                  </div>
                  <br />
                  <div class="search-input">
                    <img
                      src="/loading.svg"
                      class="loading"
                      v-show="loadingFile"
                    />
                    <input
                      v-show="!loadingFile && files.length < 4 && !quoteIsPaid"
                      type="file"
                      ref="inputFile"
                      @change="loadFile"
                      :accept="contentType"
                    />
                    <p v-if="files.length === 4">
                      Maximum of 4 files can be attached to a Quick Invoice.
                    </p>
                    <p v-if="quoteIsPaid">
                      Quick Invoice already closed and paid.
                    </p>
                  </div>
                </div>
                <div class="send-quote-info" v-else>
                  File storage service is not configured for this Location on
                  zeamster. <br />
                  Please contact support if you need assistance enabling file
                  storage service.
                </div>
              </div>

              <div class="quote-sub-header">Email :</div>
              <form
                id="email-notification"
                @submit.prevent="sendEmailNotification"
                class="quote-details row"
              >
                <div class="search-input">
                  <input
                    v-model="customerEmail"
                    type="text"
                    placeholder="JohnDoe@gmail.com"
                    required
                    style="border: none"
                  />
                </div>
                <div class="quote-send-button" v-if="!createNewQuote">
                  <button
                    type="submit"
                    class="btn btn-outline-secondary btn-sm send-button"
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div class="separator">
              <hr class="line" />
              <span class="or">or</span>
              <hr class="line" />
            </div>
            <div class="quote-text pb-3" style="margin-top: -2%">
              <div style="font-weight: 500">Text Message :</div>
              <form
                id="sms-notification"
                @submit.prevent="sendSMSNotification"
                class="quote-details row"
                v-if="locationResource.allow_sms_notification"
              >
                <div class="search-input">
                  <input
                    v-model="customerPhone"
                    type="text"
                    required
                    minlength="10"
                    maxlength="10"
                    placeholder="5555555555"
                    style="border: none"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  />
                </div>
                <div class="quote-send-button" v-if="!createNewQuote">
                  <button
                    type="submit"
                    class="btn btn-outline-secondary btn-sm send-button"
                  >
                    Send
                  </button>
                </div>
              </form>

              <div class="send-quote-info" v-else>
                SMS service is not configured for this Location on zeamster.
                <br />
                Please contact support if you need assistance enabling SMS
                service Service.
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="send-quote-info">
            Quick invoice is not configured for this Location on zeamster.
            <br />
            Please contact support if you need assistance enabling Quick invoice
            service.
          </div>
        </div>
      </div>
      <div v-else>No items available.</div>
    </div>
  </div>
  <ConfirmationModal
    key="QuoteDetail"
    :title="alertTitle"
    :message="alertMessage"
    positiveButton="OK"
    v-if="showConfirm"
    @dismiss="showConfirm = false"
    @confirm="positiveButton"
  >
  </ConfirmationModal>

  <ConfirmationModal
    key="deleteFile"
    title="Confirmation"
    :message="`Are you sure you want to delete file?`"
    positiveButton="OK"
    negativeButton="Cancel"
    v-if="confirmDelete"
    @confirm="deleteFile"
    @cancel="cancelDeleteFile"
    @dismiss="cancelDeleteFile"
  >
  </ConfirmationModal>
</template>

<script>
import DatePicker from '@/shared/DatePicker.vue';
import { mapGetters } from 'vuex';
import SelectCustomerModal from '@/components/SelectCustomerModal.vue';
import LocationService from '@/services/LocationService';
import QuoteService from '@/services/QuoteService';
import { decodeJWT } from '@/helper/Helpers';

export default {
  name: 'QuoteForm',
  components: { DatePicker, SelectCustomerModal },
  emit: ['selected-customer', 'new-quote', 'selected-due-date'],
  props: [
    'quoteIsPaid',
    'order',
    'newOrder',
    'quotetype',
    'quotename',
    'quoteinfo',
    'locationId',
    'createNewQuote',
    'customer',
    'itemsLength',
  ],
  methods: {
    cancelDeleteFile() {
      this.confirmDelete = false;
      this.fileId = null;
    },
    confirmDeleteFile(fileId) {
      this.confirmDelete = true;
      this.fileId = fileId;
    },
    async deleteFile() {
      this.confirmDelete = false;
      this.loading = true;
      const quoteId = this.order?.id ? this.order.id : this.$route.query.id;
      const locationId =
        this.locationId && this.locationId !== undefined
          ? this.locationId
          : this.selectedLocationId;

      try {
        const quoteService = new QuoteService();
        const response = await quoteService.deleteFile(locationId, quoteId, {
          fileId: this.fileId,
          resourceType: 'delete-file',
        });

        await this.loadFiles();

        if (response.status === 204) {
          this.alertTitle = 'Success!';
          this.alertMessage = 'File deleted successfully.';
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'File has not been deleted.';
          this.showConfirm = true;
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.alertTitle = 'Error!';
          this.alertMessage = error.response.data.errors[0].message;
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'Internal server error.';
          this.showConfirm = true;
        }
      } finally {
        this.loading = false;
      }
    },
    convertPdfToBase64(pdfFile) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64Data = btoa(reader.result);
          resolve(base64Data);
        };
        reader.onerror = () => {
          reject('Error reading the PDF file');
        };
        reader.readAsBinaryString(pdfFile);
      });
    },
    async loadFiles() {
      const quoteId = this.order?.id ? this.order.id : this.$route.query.id;
      const locationId =
        this.locationId && this.locationId !== undefined
          ? this.locationId
          : this.selectedLocationId;
      const quoteService = new QuoteService();
      try {
        const files = await quoteService.getFiles(locationId, quoteId, {
          resourceType: 'list-files',
        });
        this.files = files.data;
      } catch (error) {
        this.files = [];
      }
    },
    async loadFile(event) {
      const pdfFile = event.target.files[0];
      if (pdfFile.type !== 'application/pdf') {
        this.alertTitle = 'Alert!';
        this.alertMessage = 'Please select a PDF file.';
        this.showConfirm = true;
        this.$refs.inputFile.value = null;
        return;
      }
      try {
        this.loading = true;
        const base64Data = await this.convertPdfToBase64(pdfFile);
        this.file = base64Data;
        const quoteId = this.order?.id ? this.order.id : this.$route.query.id;
        const locationId =
          this.locationId && this.locationId !== undefined
            ? this.locationId
            : this.selectedLocationId;

        const quoteService = new QuoteService();
        const response = await quoteService.attachedFile(locationId, quoteId, {
          file: this.file,
          contentType: this.contentType,
          resourceType: 'attach-file',
        });

        await this.loadFiles();

        if (response.data.success) {
          this.alertTitle = 'Success!';
          this.alertMessage = 'File upload successfully.';
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'File has not been uploaded.';
          this.showConfirm = true;
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.alertTitle = 'Error!';
          this.alertMessage = error.response.data.errors[0].message;
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'Internal server error.';
          this.showConfirm = true;
        }
      } finally {
        this.loading = false;
      }
    },
    newQuote() {
      this.$emit('new-quote', true);
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    async checkResources() {
      this.loading = true;
      const locationId =
        this.locationId && this.locationId !== undefined
          ? this.locationId
          : this.selectedLocationId;
      const locationService = new LocationService();
      const response = await locationService.checkLocationResources(locationId);

      await this.loadFiles();
      this.accessToken = decodeJWT()?.access_token;

      this.locationResource = response.data;
      this.loading = false;
    },
    saveQuote() {
      this.$emit(
        'save-quote',
        this.quoteName,
        this.selectedDueDate,
        this.selectedCustomer,
      );
    },
    async sendEmailNotification() {
      this.loading = true;
      let notification = {};

      if (this.quoteIsPaid) {
        this.loading = false;
        this.alertTitle = 'Alert!';
        this.alertMessage = 'Quick Invoice already closed and paid.';
        this.showConfirm = true;
        return;
      }

      if (!this.validateEmail(this.customerEmail)) {
        this.loading = false;
        this.alertTitle = 'Alert!';
        this.alertMessage = 'Invalid email address.';
        this.showConfirm = true;
        return;
      }

      const quoteId = this.order?.id ? this.order.id : this.$route.query.id;
      const locationId =
        this.locationId && this.locationId !== undefined
          ? this.locationId
          : this.selectedLocationId;
      const quoteService = new QuoteService();

      try {
        notification = {
          email: this.customerEmail,
          resourceType: 'notify',
        };

        const response = await quoteService.sendEmailNotification(
          locationId,
          quoteId,
          notification,
        );

        if (response.data.success) {
          this.alertTitle = 'Success!';
          this.alertMessage = 'Email sent successfully.';
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'Email has not been sent.';
          this.showConfirm = true;
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.alertTitle = 'Error!';
          this.alertMessage = error.response.data.errors[0].message;
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'Internal server error.';
          this.showConfirm = true;
        }
      } finally {
        this.loading = false;
      }
    },
    async sendSMSNotification() {
      this.loading = true;
      let notification = {};

      if (this.quoteIsPaid) {
        this.loading = false;
        this.alertTitle = 'Alert!';
        this.alertMessage = 'Quick Invoice already closed and paid.';
        this.showConfirm = true;
        return;
      }

      const quoteId = this.order?.id ? this.order.id : this.$route.query.id;
      const locationId =
        this.locationId && this.locationId !== undefined
          ? this.locationId
          : this.selectedLocationId;
      const quoteService = new QuoteService();

      try {
        notification = {
          phoneNumber: this.customerPhone,
          resourceType: 'notify',
        };

        const response = await quoteService.sendSMSNotification(
          locationId,
          quoteId,
          notification,
        );

        if (response.data.success) {
          this.alertTitle = 'Success!';
          this.alertMessage = 'SMS sent successfully.';
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'SMS has not been sent.';
          this.showConfirm = true;
        }
      } catch (error) {
        if (error.response.status === 400) {
          this.alertTitle = 'Error!';
          this.alertMessage = error.response.data.errors[0].message;
          this.showConfirm = true;
        } else {
          this.alertTitle = 'Error!';
          this.alertMessage = 'Internal server error.';
          this.showConfirm = true;
        }
      } finally {
        this.loading = false;
      }
    },
    getDateFormat(val) {
      return '';
    },
    positiveButton() {
      this.showConfirm = false;
    },
    getSelectedCustomer(customer) {
      if (customer) {
        this.selectedCustomer = customer;
        this.customerValue =
          customer.first_name +
          ' ' +
          customer.last_name +
          ' - Acc #:' +
          customer.account_number;
        this.customerId = customer.id;
        this.customerEmail = customer.email;
        this.customerPhone = customer.cell_phone;
        this.$forceUpdate();
        this.$emit('selected-customer', customer);
        return customer;
      }
    },
  },
  data() {
    return {
      accessToken: '',
      fileId: null,
      confirmDelete: false,
      files: [],
      loadingFile: false,
      file: null,
      contentType: 'application/pdf',
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,
      loading: true,
      currentLocationId: this.locationId,
      locationResource: null,
      quoteName:
        this.order && this.order?.quote_name ? this.order.quote_name : '',
      selectedDueDate:
        this.quoteinfo && this.quoteinfo.dueDate ? this.quoteinfo.dueDate : '',
      showSelectCustomerModal: false,
      customerValue:
        this.quoteinfo && this.quoteinfo.customerName
          ? this.quoteinfo.customerName
          : '',
      selectedCustomer: '',
      customerId: '',
      customerEmail:
        this.quoteinfo && this.quoteinfo.email ? this.quoteinfo.email : '',
      customerPhone:
        this.quoteinfo && this.quoteinfo.phone ? this.quoteinfo.phone : '',
    };
  },
  computed: {
    ...mapGetters(['allLocationList', 'allCustomerList', 'selectedLocationId']),
  },
  mounted() {
    this.checkResources();
  },
  created() {
    this.datepickerSetting = {
      id: 'dueOnId',
      name: 'dueOnName',
      class: 'myDateInput',
      yearMinus: '0',
      locale: {
        weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        todayBtn: 'Today',
        clearBtn: 'Clear',
        closeBtn: 'Close',
      },
      changeEvent: (value) => {
        if (value) {
          const selectedDate = new Date(value + ' GMT');
          this.selectedDueDate = selectedDate.toISOString();
          this.$emit('selected-due-date', this.selectedDueDate);
        }
      },
    };
  },
  watch: {
    createNewQuote(newValue) {
      if (!newValue) {
        this.quoteName =
          this.order && this.order?.quote_name ? this.order.quote_name : '';
      } else {
        this.quoteName = '';
      }
    },
  },
};
</script>

<style scoped>
.quotes-heading {
  font-weight: 600;
  font-size: 17px;
}

.quotes-form {
  background: #f3f3f3;
}

.quote-details {
  align-items: center;
  justify-content: space-between;
  padding: 0px 13px;
}

.send-quote-info {
  color: grey;
  font-size: smaller;
}

.save-quote {
  justify-content: space-between;
  padding: 0px 10px;
}

.search-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 7px;
  background: white;
}

.mat-icon {
  margin-right: 10px;
  color: #555;
}

.quote-sub-header {
  font-weight: 500;
  padding-top: 15px;
}

.send-button {
  font-weight: 900;
}

.separator {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.search-input-customer {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 7px;
  background: white;
}

.line {
  flex: 1;
  border: none;
  border-top: 1px solid #ccc;
}

.or {
  margin: 0 10px;
  color: #555;
}
.link-attachement {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
