import { isEmpty } from 'lodash';

export const pattern = /^0*\d{0,3}\.\d{0,3}$|^\d{1,3}$/gi;
export const decimalPattern = new RegExp(/^\d*\.?\d*$/);
export const customDecimalPattern = (decimalDigits = 2) =>
  new RegExp(`^\\d*\\.\\d{0,${decimalDigits}}$|^\\d{1,${decimalDigits}}$`, 'g'); // Ex. /^\d*\.\d{0,2}$|^\d{1,2}$/;

export function isValidDecimalNumber(value: any, max = 3) {
  if (isNaN(value)) {
    return false;
  }
  if (
    value != null &&
    String(value).indexOf('.') > 0 &&
    String(value).split('.')[1].length >= max
  ) {
    return false;
  } else {
    return true;
  }
}

export function isAlphaNumeric(e: KeyboardEvent) {
  console.log('isAlphaNumeric', e.code);
  const keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
  const ret =
    (keyCode >= 48 && keyCode <= 57) || keyCode == 37 || keyCode == 46;
  return ret;
}

export function addError(
  field: string,
  message: string,
  error: string,
  object: Record<string, object>,
) {
  const temp = { ...object };
  temp[field] = {
    ...temp[field],
    [error]: message,
  };
  return temp;
}

export function removeError(
  field: string,
  object: Record<string, object>,
  error: string = null,
) {
  let temp = { ...object };
  if (isEmpty(temp)) {
    return;
  }
  if (!isEmpty(field) && !isEmpty(error) && !isEmpty(temp[field])) {
    const { [error]: e, ...rest }: Record<string, any> = temp[field];
    temp[field] = { ...rest };
  }
  if (temp[field] && isEmpty(temp[field])) {
    const { [field]: e, ...rest } = temp;
    temp = { ...rest };
  }
  return temp;
}

export const getUniqueListBy = (arr: object[], key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export function isInputNumber(event: InputEvent) {
  const char = String.fromCharCode(event.which);
  if (!/[0-9]/.test(char)) {
    event.preventDefault();
  }
}

export function isValidRange(value: any, min: number, max: number) {
  return !isNaN(value) && value >= min && value <= max;
}

export const formatCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value);
};
export function onKeypressValidDecimal(event: InputEvent, decimalDigits = 2) {
  const char = String.fromCharCode(event.which);
  if (!customDecimalPattern(decimalDigits).test(char)) {
    event.preventDefault();
  }
}
