
import {ItemType} from "@/enums/ItemType";

export default {
  name: 'CreateOrderItemList',
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  emits: ['add-to-order'],
  data() {
    return {
      itemType: ItemType,
    };
  },
  computed: {
    isInStock() {
      const {variant} = this;
      switch (variant.itemType) {
        case ItemType.Bundle:
          return !(
            variant.variantStock !== null &&
            variant.variantStock <= 0 &&
            variant.variantTracking
          );
        case ItemType.Good:
          return (
            (variant.variantStock > 0 && variant.variantTracking) ||
            !variant.variantTracking
          );
        default:
          return true;
      }
    },
  },
  methods: {
    addToOrder(item) {
      if (item.itemType === 3) {
        const variants = [];
        if (item.bundles) {
          for (let i = 0; i < item.bundles.length; i++) {
            const bundleItem = item.bundles[i];
            const itemNew = bundleItem.item;
            const variantNew = bundleItem.variant;
            const variantStock = Math.min(
              ...variantNew.variantLocations.map(
                (location) => location.currentStock,
              ),
            );
            const newBundleItem = {
              variantId: variantNew.id,
              itemId: itemNew.id,
              itemHasImage: itemNew.hasImage,
              itemImageSrc: itemNew.hasImage
                ? itemNew.itemImageSrc
                : '/image_placeholder.png',
              itemName: itemNew.name,
              itemType: 3,
              itemDiscount: 0,
              itemDescription: itemNew.description,
              itemManufacturer: itemNew.manufacturer,
              variantName: variantNew.name,
              variantSku: variantNew.sku,
              variantPrice: parseFloat(variantNew.price.toString()),
              variantUpc: variantNew.upc,
              variantStockMin: variantStock,
              variantQuantity: 1,
              variantLocation: [],
            };
            if (variantNew.variantDiscountedPrice) {
              newBundleItem.variantPrice =
                variantNew.variantPrice - variantNew.variantDiscountedPrice;
            }
            variants.push(newBundleItem);
          }
          // this.removeStockFromOrder();
        }
        const productName =
          item.itemName !== ''
            ? item.itemName
            : item.itemDescription !== ''
            ? item.itemDescription
            : 'Product';
        this.$emit('add-to-order', variants, productName, this.variant);
      } else {
        const newItem = {
          variantId: item.variantId,
          itemId: item.itemId,
          itemHasImage: item.itemHasImage,
          itemImageSrc: item.itemHasImage
            ? item.itemImageSrc
            : '/image_placeholder.png',
          itemName: item.itemName,
          itemType: item.itemType,
          itemDiscount: 0,
          itemDescription: item.itemDescription,
          itemManufacturer: item.itemManufacturer,
          variantName: item.variantName,
          variantSku: item.variantSku,
          variantPrice: item.variantPrice,
          variantWholesalePrice: item.variantWholesalePrice,
          variantQuantity: item.variantQuantity,
          itemTaxes: item.itemTaxes,
          variantStock: item.variantStock,
          variantTracking: item.variantTracking,
          variantUpc: item.variantUpc,
          variantLocation: [],
        };
        if (item.variantDiscountedPrice) {
          newItem.variantPrice =
            item.variantPrice - item.variantDiscountedPrice;
        }
        // this.removeStockFromOrder();
        const productName = item.itemName !== '' ? item.itemName : 'Product';
        this.$emit('add-to-order', newItem, productName, this.variant);
      }
    },
    removeStockFromOrder() {
      const { variant } = this;
      if (variant.variantTracking && variant.variantStock > 0) {
        variant.variantStock--;
      }
    },
  },
};
