
import { defineComponent, ref, reactive, computed, watch } from 'vue';
export default defineComponent({
  name: 'DatePicker',
  props: {
    idAttr: {
      type: String,
    },
    nameAttr: {
      type: String,
    },
    classAttr: {
      type: String,
    },
    valueAttr: {
      type: String,
      default: () => {
        let d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [month, day, year].join('-');
      },
    },
    placeholderAttr: {
      type: String,
      default: '',
    },
    yearMinus: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
    disabledDate: {
      type: Array,
      default: () => {
        return [];
      },
    },
    locale: {
      type: Object,
      default: () => {
        return {
          format: 'MM/DD/YYYY',
          weekday: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          todayBtn: 'Today',
          clearBtn: 'Clear',
          closeBtn: 'Close',
          months: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'June',
            'July',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec',
          ],
        };
      },
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    enableAutocomplete: {
      type: Boolean,
      default: false,
    },
  },
  setup(props :any, { emit }) {
    const formatSetting = reactive({
      format: props.locale.format,
      formatRegexp: new RegExp('([0-9]{4})/([0-9]{2})/([0-9]{2})'),
      yearIndex: 1,
      monthIndex: 3,
      dateIndex: 5,
    });
    const dateRegexp = new RegExp(
      '([Y]{4}|[y]{4}|[M]{2}|[m]{2}|[D]{2}|[d]{2})([^ a-zA-Z])([Y]{4}|[y]{4}|[M]{2}|[m]{2}|[D]{2}|[d]{2})([^ a-zA-Z])([Y]{4}|[y]{4}|[M]{2}|[m]{2}|[D]{2}|[d]{2})',
    );
    const dateFormatGroup = props.locale.format.match(dateRegexp);
    if (!dateFormatGroup) {
      formatSetting.format = 'MM/DD/YYYY';
    }
    if (dateFormatGroup) {
      const isY = (v) => v == 'YYYY' || v == 'yyyy';
      const isM = (v) => v == 'MM' || v == 'mm';
      const isD = (v) => v == 'DD' || v == 'dd';
      let tempRegexp = '';
      for (let i = 1; i < dateFormatGroup.length; i++) {
        if (i == 2) {
          tempRegexp += '([^ a-zA-Z])';
          continue;
        }
        if (i == 4) {
          tempRegexp += '([^ a-zA-Z])';
          continue;
        }
        if (isY(dateFormatGroup[i])) {
          tempRegexp += '([0-9]{4})';
          formatSetting.yearIndex = i;
          continue;
        }
        if (isM(dateFormatGroup[i])) {
          tempRegexp += '([0-9]{2})';
          formatSetting.monthIndex = i;
          continue;
        }
        if (isD(dateFormatGroup[i])) {
          tempRegexp += '([0-9]{2})';
          formatSetting.dateIndex = i;
          continue;
        }
      }
      formatSetting.formatRegexp = new RegExp(tempRegexp);
    }
    const formatDate = (dateObj, hasMinus, format = undefined) => {
      if (format == undefined) {
        format = formatSetting.format;
      }
      let yyyy = dateObj.getFullYear();
      if (hasMinus) {
        yyyy = parseInt(yyyy) - parseInt(String(props.yearMinus));
      }
      format = format.replace(/yyyy/g, yyyy);
      format = format.replace(/YYYY/g, yyyy);
      format = format.replace(
        /MM/g,
        ('0' + (dateObj.getMonth() + 1)).slice(-2),
      );
      format = format.replace(/dd/g, ('0' + dateObj.getDate()).slice(-2));
      format = format.replace(/DD/g, ('0' + dateObj.getDate()).slice(-2));
      format = format.replace(/HH/g, ('0' + dateObj.getHours()).slice(-2));
      format = format.replace(/mm/g, ('0' + dateObj.getMinutes()).slice(-2));
      format = format.replace(/ss/g, ('0' + dateObj.getSeconds()).slice(-2));
      format = format.replace(
        /SSS/g,
        ('00' + dateObj.getMilliseconds()).slice(-3),
      );
      return format;
    };
    const selectedValue = ref('');
    const datepicker = reactive({
      show: false,
      hasRange: computed(() => {
        let result = false;
        if (
          props.from &&
          props.to &&
          formatSetting.formatRegexp.test(props.from) &&
          formatSetting.formatRegexp.test(props.to)
        ) {
          result = true;
        }
        return result;
      }),
      year: 2020,
      years: computed(() => {
        const yearList = [];
        for (let i = datepicker.year - 10; i < datepicker.year + 10; i++) {
          if (datepicker.hasRange) {
            const fromDate = props.from.match(formatSetting.formatRegexp);
            const toDate = props.to.match(formatSetting.formatRegexp);
            if (i < Number(fromDate[formatSetting.yearIndex])) {
              continue;
            }
            if (i > Number(toDate[formatSetting.yearIndex])) {
              continue;
            }
          }
          yearList.push(i);
        }
        return yearList;
      }),
      month: 1,
      monthList: computed(() => {
        const result = [];
        for (let i = 1; i <= 12; i++) {
          if (datepicker.hasRange) {
            const fromDate = props.from.match(formatSetting.formatRegexp);
            const toDate = props.to.match(formatSetting.formatRegexp);

            const fromYearIndex = Number(fromDate[formatSetting.yearIndex]);
            const fromMonthIndex = Number(fromDate[formatSetting.monthIndex]);
            const toYearIndex = Number(toDate[formatSetting.yearIndex]);
            const toMonthIndex = Number(toDate[formatSetting.monthIndex]);

            if (datepicker.year == fromYearIndex && i < fromMonthIndex) {
              if (datepicker.month <= i) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                datepicker.month = fromMonthIndex;
              }
              continue;
            }
            if (datepicker.year == toYearIndex && i > toMonthIndex) {
              if (datepicker.month >= i) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                datepicker.month = toMonthIndex;
              }
              continue;
            }
          }
          result.push(i);
        }
        return result;
      }),
      days: computed(() => {
        const year =
          parseInt(datepicker.year.toString()) +
          parseInt(props.yearMinus.toString());
        const month = datepicker.month;
        const startDate = new Date(year + '/' + month + '/1');
        const lastDate = new Date(year, month, 0);
        const startDateWeekday = startDate.getDay();
        const lastDateWeekday = lastDate.getDay();
        if (startDateWeekday != 0) {
          startDate.setDate(startDate.getDate() - startDateWeekday);
        }
        if (lastDateWeekday != 6) {
          const padding = 6 - lastDateWeekday;
          lastDate.setDate(lastDate.getDate() + padding);
        }
        const days = [];
        let row = [];
        const today = formatDate(new Date(), true);
        let isDisabled = false;
        while (startDate.getTime() - lastDate.getTime() <= 0) {
          isDisabled = false;
          const yyyy =
            parseInt(startDate.getFullYear().toString()) -
            parseInt(props.yearMinus.toString());
          const mm = startDate.getMonth() + 1;
          const dd = startDate.getDate();
          if (datepicker.hasRange) {
            const fromDate = props.from.match(formatSetting.formatRegexp);
            const toDate = props.to.match(formatSetting.formatRegexp);

            const fromYearIndex = Number(fromDate[formatSetting.yearIndex]);
            const fromMonthIndex = Number(fromDate[formatSetting.monthIndex]);
            const toYearIndex = Number(toDate[formatSetting.yearIndex]);
            const toMonthIndex = Number(toDate[formatSetting.monthIndex]);

            if (
              yyyy < fromYearIndex ||
              (yyyy == fromYearIndex && mm < toMonthIndex) ||
              (yyyy == fromYearIndex &&
                mm == fromMonthIndex &&
                dd < Number(fromDate[formatSetting.dateIndex]))
            ) {
              isDisabled = true;
            }
            if (
              yyyy > toYearIndex ||
              (yyyy == toYearIndex && mm > toMonthIndex) ||
              (yyyy == toYearIndex &&
                mm == toMonthIndex &&
                dd > Number(toDate[formatSetting.dateIndex]))
            ) {
              isDisabled = true;
            }
          }
          if (!isDisabled && props.disabledDate.length > 0) {
            isDisabled =
              props.disabledDate.indexOf(formatDate(startDate, true)) >= 0;
          }
          const dateObj = {
            year: yyyy,
            month: mm,
            day: dd,
            weekday: startDate.getDay(),
            dateString: formatDate(startDate, true),
            isToday: formatDate(startDate, true) == today,
            isDisabled: isDisabled,
          };
          row.push(dateObj);
          if (row.length >= 7) {
            days.push(row);
            row = [];
          }
          startDate.setDate(startDate.getDate() + 1);
        }
        return days;
      }),
    });
    watch(selectedValue, (value, prevValue) => {
      if (value != '') {
        let result = '';
        if (formatSetting.formatRegexp.test(value)) {
          const temp = value.match(formatSetting.formatRegexp);
          result = formatDate(
            new Date(
              Number(temp[formatSetting.yearIndex]),
              Number(temp[formatSetting.monthIndex]) - 1,
              Number(temp[formatSetting.dateIndex]),
            ),
            false,
          );
        } else {
          result = prevValue;
        }
        selectedValue.value = result;
      }
      emit('value-changed', value);
    });
    watch(
      () => datepicker.show,
      (state) => {
        if (state) {
          let thisMonth = new Date();
          if (selectedValue.value) {
            const temp = selectedValue.value.match(formatSetting.formatRegexp);
            thisMonth = new Date(
              temp[formatSetting.yearIndex.toString()],
              temp[formatSetting.monthIndex.toString()] - 1,
              temp[formatSetting.dateIndex.toString()],
            );
          }
          datepicker.year = thisMonth.getFullYear();
          datepicker.month = thisMonth.getMonth() + 1;
        }
      },
    );
    watch(
      () => props.valueAttr,
      (value, prevValue) => {
        if (value != '' && value != prevValue) {
          let result = '';
          if (formatSetting.formatRegexp.test(value)) {
            const temp = value.match(formatSetting.formatRegexp);
            result = formatDate(
              new Date(
                Number(temp[formatSetting.yearIndex]),
                Number(temp[formatSetting.monthIndex]) - 1,
                Number(temp[formatSetting.dateIndex]),
              ),
              false,
            );
            selectedValue.value = result;
          }
        }
      },
    );
    const prevMonth = () => {
      const tempPrevYear =
        datepicker.month == 1 ? datepicker.year - 1 : datepicker.year;
      const tempPrevMonth = datepicker.month == 1 ? 12 : datepicker.month - 1;
      if (datepicker.hasRange) {
        const fromDate = props.from.match(formatSetting.formatRegexp);

        if (tempPrevYear < Number(fromDate[formatSetting.yearIndex])) {
          return false;
        }
        if (
          tempPrevYear == Number(fromDate[formatSetting.yearIndex]) &&
          tempPrevMonth < Number(fromDate[formatSetting.monthIndex])
        ) {
          return false;
        }
      }
      datepicker.year = tempPrevYear;
      datepicker.month = tempPrevMonth;
    };
    const nextMonth = () => {
      const tempNextYear =
        datepicker.month == 12 ? datepicker.year + 1 : datepicker.year;
      const tempNextMonth = datepicker.month == 12 ? 1 : datepicker.month + 1;
      if (datepicker.hasRange) {
        const toDate = props.to.match(formatSetting.formatRegexp);
        if (tempNextYear > Number(toDate[formatSetting.yearIndex])) {
          return false;
        }
        if (
          tempNextYear == Number(toDate[formatSetting.yearIndex]) &&
          tempNextMonth > Number(toDate[formatSetting.monthIndex])
        ) {
          return false;
        }
      }
      datepicker.year = tempNextYear;
      datepicker.month = tempNextMonth;
    };
    const selectToday = () => {
      let today = new Date();
      let tempYear =
        parseInt(today.getFullYear().toString()) -
        parseInt(props.yearMinus.toString());
      let tempMonth = today.getMonth() + 1;
      if (datepicker.hasRange) {
        const fromDate = props.from.match(formatSetting.formatRegexp);
        const toDate = props.to.match(formatSetting.formatRegexp);
        if (today < new Date(fromDate.toString())) {
          tempYear = Number(fromDate[formatSetting.yearIndex]);
          tempMonth = Number(fromDate[formatSetting.monthIndex]);
          today = new Date(fromDate.toString());
        }
        if (today > new Date(toDate.toString())) {
          tempYear = Number(toDate[formatSetting.yearIndex]);
          tempMonth = Number(toDate[formatSetting.monthIndex]);
          today = new Date(toDate.toString());
        }
      }
      datepicker.year = tempYear;
      datepicker.month = tempMonth;
      selectedValue.value = formatDate(today, true);
      datepicker.show = false;
    };
    const clear = () => {
      selectedValue.value = '';
      datepicker.show = false;
    };
    const close = () => {
      datepicker.show = false;
    };
    const select = (value) => {
      selectedValue.value = value;
      datepicker.show = false;
    };
    select(props.valueAttr);
    return {
      selectedValue,
      datepicker,
      prevMonth,
      nextMonth,
      selectToday,
      select,
      clear,
      close,
    };
  },
});
