import ItemVariantFlattend from '@/Interfaces/ItemVariantFlattend';
import Customer from '@/Interfaces/Customer';
import Location from '@/Interfaces/Location';
import { PaymentType } from '@/enums/PaymentType';
import { TransactionType } from '@/enums/TransactionType';
import Discount from '@/Interfaces/Discount';

export class CreateOrderRequest {
  public id?: string;
  public variantSnapshots: ItemVariantFlattend[] = [];
  public variantQuoteSnapshots: ItemVariantFlattend[] = [];
  public total?: number = 0;
  public subtotal?: number = 0;
  public tax?: number = 0;
  public tip?: number = 0;
  public isPaid = false;
  public location: Location = {} as Location;
  public customer: Customer = {} as Customer;
  public paymentType: PaymentType = PaymentType.None;
  public transactionType: TransactionType = TransactionType.None;
  public discounts?: Discount[] = [];
  public accountType?: string;
  public relatedOrder?: string;
  public productName?: string;
  public productType?: string;
  public isBack?: boolean;
  public save_account?: boolean;
  public product_transaction_id?: any;
  public discountApplied?: boolean;
  public quote_name?: any;
  public customerId?: any;
  public isCashPaymentMethod?: boolean;
  public isAchPaymentMethod?: boolean;
  public transaction_api_id?: string;
  public dueDate?: string;
  itemDiscount?: Array<any>;
  quoteRequest?: string;
  jsonQuoteImport?: string;
  depositAccountId?: string;
  depositAccountTitle?: string;
}
