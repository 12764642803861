
import { Options, Vue } from 'vue-class-component';
import ProductCard from './ProductCard.vue';

@Options({
  props: ['items', 'location', 'isProductLoading'],
  emits: ['add-to-order'],
  components: {
    ProductCard,
  },
})
export default class ProductCardList extends Vue {
  location: any;
  addToOrder(newItem: any, productName: string, updatedVariant: any) {
    this.$emit('add-to-order', newItem, productName, updatedVariant);
  }
}
