
import { Options, Vue } from 'vue-class-component';
import AccountVault from '@/components/AccountVault.vue';

@Options({
  props: ['paymentMethods', 'isProcessingSavedCard'],
  components: { AccountVault },
  emits: ['submit-payment-with-saved-card'],
  computed: {
    confirmationText() {
      return this.selectedCard.routing
        ? 'Do you want to proceed with this Token?'
        : 'Do you want to proceed with this card?';
    },
  },
})
export default class SavedPaymentModule extends Vue {
  paymentMethods: any;
  isProcessingSavedCard: any;
  selectedOption: any = null;
  paymentMethodOptions: any[] = [];
  selectedCard: any = null;
  loading = false;
  currency(value: any) {
    return value === null ? '' : '$' + parseFloat(value).toFixed(2);
  }
  mounted() {
    this.paymentMethodOptions = this.paymentMethods.map((method: any) => {
      return {
        account_holder_name: method.account_holder_name,
        exp_date: method.exp_date,
        first_six: method.first_six,
        last_four: method.last_four,
        routing: method.routing,
        value: method.id,
        text: `${
          method.account_holder_name !== null ? method.account_holder_name : ''
        } - ${method.last_four}`,
      };
    });
  }

  submitPaymentMethod(selectedOption: any) {
    this.loading = true;
    this.$store.commit('setTransactionInProgress', true);
    const selectedPaymentMethod: any = this.paymentMethods.find(
      (method: any) => method.id === selectedOption,
    );
    this.$emit('submit-payment-with-saved-card', selectedPaymentMethod);
  }

  askConfirmation(data: any) {
    this.selectedCard = data;
  }
}
