<template>
  <div class="stepper">
    <div v-for="(step, index) in stepList" :key="index" class="step">
      <div
        class="step-header-inactive"
        :class="{ completed: step.completed }"
        v-if="!step.current"
      >
        <div class="step-number" v-if="!step.completed">{{ index + 1 }}</div>
        <div class="step-tick" v-if="step.completed">
          <span class="material-icons">done</span>
        </div>
      </div>
      <div class="step-header-current" v-if="step.current">
        <div class="step-number">{{ index + 1 }}</div>
      </div>
      <div class="step-title">{{ step.title }}</div>
      <div class="divider" v-if="index < steps.length - 1"></div>
      <hr />
    </div>
  </div>
</template>

<script>
export default {
  props: ['steps'],
  computed: {
    stepList() {
      return this.steps;
    },
  },
};
</script>

<style scoped>
.stepper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 60px;
  padding: 0px 30px 0px 30px;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 10px;
}

.step-header-current {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  background: #111827;
}

.step-header-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: bold;
  background: grey;
}

.step-number {
  color: whitesmoke;
}

.step-title {
  margin-top: 4px;
  font-weight: 600;
  font-size: 12px;
}

.divider {
  width: 140px;
  color: #000;
  border-bottom: 1px dotted #ccc;
}

.completed {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #d6e6dc;
  color: #00cc00;
  padding-top: 6px;
}

</style>