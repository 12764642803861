import axios, { AxiosResponse } from 'axios';
import Customer from '../Interfaces/Contact';
import { CreateCustomerRequest } from '@/contracts/CreateCustomerReqest';

export default class ContactService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/customer`;

  async getAllContactsWith(locationId: any, page: number, pageSize: number) {
    if (locationId) {
      //-----store implementation----//
      return axios.get(
        `${process.env.VUE_APP_BASE_URL}/customer?locationId=${locationId}&page=${page}&pageSize=${pageSize}`,
      );
    }
    // TODO: Need to uncomment below line(14) and comment the following that line(15) for pagination.
    return this.getAllContacts();
  }
  async getAllContacts(): Promise<AxiosResponse> {
    //-----store implementation----//
    return axios.get(`${this.BASE_URL}`);
  }

  async getContactsByLocation(locationId = ''): Promise<AxiosResponse> {
    //-----store implementation----//
    return axios.get(`${this.BASE_URL}?locationId=${locationId}`);
  }

  async getContactById(
    contactId = '',
    locationId: string,
  ): Promise<AxiosResponse> {
    //-----store implementation----//
    return axios.get(`${this.BASE_URL}?customerId=${contactId}`, {
      params: {
        locationId,
      },
    });
  }

  async getCustomerHistory(
    customerId = '',
    page: number,
    pageSize: number,
  ): Promise<AxiosResponse> {
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/order/customer_list?customerId=${customerId}&page=${page}&pageSize=${pageSize}`,
    );
  }

  async deleteContactById(contactId = ''): Promise<AxiosResponse> {
    return axios.delete(`${this.BASE_URL}/${contactId}`);
  }

  async saveContact(
    customer: CreateCustomerRequest,
    isEditMode: boolean,
    contactId: string,
    location: any,
  ): Promise<AxiosResponse> {
    const customerToAdd: CreateCustomerRequest = {
      first_name: customer.first_name,
      last_name: customer.last_name,
      account_number: customer.account_number,
      email: customer.email,
      company_name: customer.company_name,
      address: customer.address,
      city: customer.city,
      state: customer.state,
      zip: customer.zip,
      home_phone: customer.home_phone,
      cell_phone: customer.cell_phone,
      office_phone: customer.office_phone,
      office_ext_phone: customer.office_ext_phone,
      contact_balance: customer.contact_balance,
      email_trx_receipt: customer.email_trx_receipt,
      header_message_type_id: customer.header_message_type_id,
      header_message: customer.header_message,
      contact_api_id: customer.contact_api_id,
      parent_id: customer.parent_id,
      location_id: customer.location_id,
    };

    if (isEditMode) {
      return axios.put(`${this.BASE_URL}/${contactId}`, customer);
    } else {
      return axios.post(`${this.BASE_URL}`, customerToAdd);
    }
  }

  async removeContact(customer: Customer): Promise<AxiosResponse> {
    return axios.delete(`${this.BASE_URL}/${customer.id}`);
  }
}
