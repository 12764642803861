
import ModalContainer from '@/shared/ModalContainer.vue';
import TaxesSelection from '@/components/TaxesSelection.vue';

export default {
  name: 'AddTaxButton',
  components: {
    TaxesSelection,
    ModalContainer,
  },
  props: {
    items: {
      required: true,
      default: [],
    },
    btnClass: {
      required: false,
      default: 'btn-sm',
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  emits: ['on-adding-tax'],
  data() {
    return {
      showTaxSelection: false,
    };
  },
};
