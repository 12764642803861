
import { Options, Vue } from 'vue-class-component';
import { ItemType } from '@/enums/ItemType';
import SelectedCustomer from '@/components/SelectedCustomer.vue';
@Options({
  props: ['newOrder', 'calcResults', 'roleService', 'selectedCustomer'],
  components: { SelectedCustomer },
  emits: [
    'update-quantity',
    'remove-from-order-tab',
    'update-calcs',
    'select-all-items',
  ],
})
export default class OrderSummary extends Vue {
  option: any = 1;
  newOrder: any = null;
  calcResults: any = null;
  roleService: any = null;
  currency(value: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  }
  getItemTypeInitialValue(data: any) {
    if (data.itemType === ItemType.Good) {
      return '(G)';
    } else if (data.itemType === ItemType.Service) {
      return '(S)';
    }
  }

  updateQuantity(item: any, type: string) {
    if (type === 'minus') {
      if (item.variantQuantity > 1) {
        this.$emit('update-quantity', item, type);
      }
    } else {
      this.$emit('update-quantity', item, type);
    }
  }

  removeFromOrder(item: any) {
    this.$emit('remove-from-order-tab', item);
  }

  updateCalcs() {
    this.$emit('update-calcs');
  }

  selectAllItems() {
    this.$emit('select-all-items');
  }
}
