import axios, { AxiosResponse } from 'axios';

export default class CategoryService {
     BASE_URL = `${process.env.VUE_APP_BASE_URL}/categories`;

     getAllCategories(locationId: string, page: number, pageSize: number, orderBy: string): Promise<AxiosResponse> {
          return axios.get(`${this.BASE_URL}`, {
               params: {
                    locationId,
                    page,
                    pageSize,
                    orderBy,
               },
          });
     }

     deleteCategory(categoryId: string, locationId: string): Promise<AxiosResponse> {
          return axios.delete(`${this.BASE_URL}/${categoryId}`, {
               params: {
                    locationId,
               },
          });
     }

     editCategory(categoryId: string, locationId: string, category: object): Promise<AxiosResponse> {
          const url = `${this.BASE_URL}/${categoryId}`;
          const params = {
            locationId,
          };
        
          return axios.put(url, category, { params });
     }

     createCategory(category: object): Promise<AxiosResponse> {
          return axios.post(`${this.BASE_URL}`, category);
     }

   // getProductsCategory
     // resource=products

     getProductsCategory(categoryId: string, locationId: string, page: number, pageSize: number, orderBy: string, search: string): Promise<AxiosResponse> {
          return axios.get(`${this.BASE_URL}/${categoryId}`, {
               params: {
                    resource: 'products',
                    locationId,
                    page,
                    pageSize,
                    orderBy,
                    search
               },
          });
     }

     updateItemCategory(categoryId: string, locationId: string, data: object): Promise<AxiosResponse> {
          const url = `${this.BASE_URL}/${categoryId}`;
          const params = {
               locationId,
             };

            return axios.put(url, data, { params });
     }
}
