
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  isValidRange,
  onKeypressValidDecimal,
  removeError,
  addError,
} from '@/helper/validations';
import CreateDiscountPopoverService from '@/services/createDiscountPopoverService';
import { DiscountType } from '@/enums/DiscountType';
import { ItemType } from '@/enums/ItemType';

export default {
  name: 'DiscountSelection',
  props: {
    items: {
      required: true,
      default: [],
    },
  },
  emits: ['on-adding-discount'],
  data() {
    return {
      itemType: ItemType,
      filteredlocationDiscounts: [],
      discountSelection: '',
      activeTab: 'existingDiscount',
      newDiscount: {
        name: undefined,
        value: undefined,
        type: '',
      },
      formError: {},
      isValidDiscountName: false,
      isValidDiscountValue: false,
      isValidDiscountType: false,
      loading: false,
      isExistingDisc: false,
      discountTypeOptions: [
        { value: '', text: 'Select discount type' },
        { value: DiscountType.Monetary, text: '$' },
        { value: DiscountType.Percentage, text: '%' },
      ],
      allLocations: [],
      createDiscountService: undefined,
    };
  },
  mounted() {
    this.createDiscountService = new CreateDiscountPopoverService();
    this.allLocations = JSON.parse(localStorage.getItem('locations'));
    const selectedLocationId = localStorage.getItem('primary_location_id');
    if (!this.locationDiscounts.length) {
      this.setSelectedLocationId(selectedLocationId);
      this.setLocationDiscount();
    }
    this.filteredlocationDiscounts = this.filterFutureAndExpiredDates(this.locationDiscounts);
  },
  computed: {
    ...mapGetters([
      'locationDiscountOptions',
      'locationDiscounts',
      'selectedLocationId',
    ]),
    validate() {
      return this.isValidDiscountName && this.isValidDiscountValue;
    },
    enableAddDiscount() {
    return (
      this.isSomeSelected &&
      ((this.isValidOrNotEmpty && this.isFormErrorValid) ||
        (this.isActiveTabCreateDiscount && this.isValidDiscountType))
      );
    },
    isSomeSelected() {
      return this.someSelected;
    },    
    isValidOrNotEmpty() {
      return this.validate || this.discountSelection !== '';
    },
    isFormErrorValid() {
      return !this.formError.discountSelection;
    },
    isActiveTabCreateDiscount() {
      return this.activeTab === 'createDiscount';
    },
    isValidType() {
      return this.validateDiscountType();
    },
    someSelected() {
      return this.itemsList.some((item) => item.discountSelection);
    },
    itemsList() {
       return this.filterItems();
    },
    listToBeDisplayedInModal(){
      let itemListFiltered = this.filterItems();
      itemListFiltered = itemListFiltered.filter(item => !item.isCompItem);
      return itemListFiltered;
    },
    allSelected() {
      return this.itemsList.every((item) => item.discountSelection);
    },
  },
  methods: {
    ...mapActions(['setLocationDiscount']),
    ...mapMutations(['setNewDiscountByLocation', 'setSelectedLocationId']),
    filterItems() {
      if (
        this.discountSelection &&
        this.discountSelection.discountType.data[0] == 1
      ) {
        return this.items.filter(
          (item) => item.variantPrice > parseInt(this.discountSelection.value),
        );
      } else if (
        !this.discountSelection &&
        this.newDiscount.value &&
        this.newDiscount.type == DiscountType.Monetary
      ) {
        return this.items.filter(
          (item) => item.variantPrice > this.newDiscount.value,
        );
      }
      return this.items;
    },

    filterFutureAndExpiredDates(items) {
      const currentDate = new Date();
      const filteredItems = items.filter(item => {
        if (item.discountFrom && item.discountTo) {
          const discountFromDate = new Date(item.discountFrom);
          const discountToDate = new Date(item.discountTo);
         return discountFromDate.getTime() < currentDate.getTime() && discountToDate.getTime() > currentDate.getTime();
      }
        return true;
      });
      return filteredItems;
    },

    validateDiscountName() {
      if (this.newDiscount.name.trim() === '') {
        this.formError = {
          ...addError(
            'name',
            'Discount name is required',
            'required',
            this.formError,
          ),
        };
        return false;
      }
      this.formError = { ...removeError('name', this.formError, 'required') };
      return true;
    },
    validateDiscountType() {
      if (String(this.newDiscount.type).trim() === '') {
        this.formError = {
          ...addError(
            'type',
            'Discount type is required',
            'required',
            this.formError,
          ),
        };
        return false;
      }
      this.formError = { ...removeError('type', this.formError, 'required') };
      return true;
    },
    validateDiscountValue() {
      this.filterItems();
      this.formError = { ...removeError('value', this.formError) };
      let ok = true;
      if (
        String(this.newDiscount.value).trim() !== '' &&
        this.newDiscount.value > 0
      ) {
        this.formError = {
          ...removeError('value', this.formError, 'required'),
        };

        if (this.newDiscount.type === DiscountType.Percentage) {
          if (!isValidRange(this.newDiscount.value, 1, 99)) {
            // range
            this.formError = {
              ...addError(
                'value',
                'Discount value must be between 1 and 99',
                'range',
                this.formError,
              ),
            };
            ok = false;
          } else {
            this.formError = {
              ...removeError('value', this.formError, 'range'),
            };
          }
        }

        if (this.newDiscount.type === DiscountType.Monetary) {
          if (!isValidRange(this.newDiscount.value, 0.01, 999999)) {
            // range
            this.formError = {
              ...addError(
                'value',
                'Discount value should be in range 0.01 to 999999',
                'range',
                this.formError,
              ),
            };
            ok = false;
          } else {
            this.formError = {
              ...removeError('value', this.formError, 'range'),
            };
          }
        }
      } else {
        this.formError = {
          ...addError(
            'value',
            'Discount value is required',
            'required',
            this.formError,
          ),
        };
        ok = false;
      }
      return ok;
    },
    inputDecimal(event) {
      onKeypressValidDecimal(event);
    },

    refreshSelection(){
      this.itemsList.forEach((item) => {
        item.discountSelection = false;
      });
      this.newDiscount.name = '';
      this.discountSelection = '';
    },
    async createDiscount() {
      this.locationDiscounts.map((disc: any) => {
        if (disc.id === this.discountId) {
          this.discountSelection = disc;
          this.isExistingDisc = true;
        }
      });

      if (!this.isExistingDisc) {
        const { name, type, value } = this.newDiscount;
        const location = this.allLocations.find(
          (location) => location.id === this.selectedLocationId,
        );
        const discount = {
          name,
          value: String(value),
          discountType: type,
          locationId: this.selectedLocationId,
          locationDiscount: [
            {
              isDeleted: false,
              location,
            },
          ],
        };

        try {
          const response = await this.createDiscountService.saveDiscount(
            discount,
          );
          if (response) {
            await this.setLocationDiscount();
            this.discountSelection = this.locationDiscounts.filter(
              (disc: any) =>
                disc.name.trim().toLowerCase() ===
                this.newDiscount.name.trim().toLowerCase(),
            )[0];
            this.newDiscount = {
              name: undefined,
              value: undefined,
              type: '',
            };
          }
        } catch (error) {
          console.error('[createDiscount] error', error);
        }
        this.loading = false;
      }
    },
    toggleAll(event) {
      const checkbox: HTMLInputElement = event.target;
      this.itemsList.forEach((item) => {
        item.discountSelection = checkbox.checked;
      });
    },
    async addDiscount() {
      if (this.validate) {
        await this.createDiscount();
      }
      this.$emit('on-adding-discount', {
        discountSelection: {
          ...this.discountSelection,
          discountType: this.discountSelection.discountType.data[0],
        },
        newItems: this.itemsList,
      });
    },
  },
  watch: {
    'newDiscount.value'(newVal) {
      if (typeof newVal !== 'undefined') {
        this.discountSelection = '';
        this.isValidDiscountValue = this.validateDiscountValue();
      }
    },
    'newDiscount.name'(newVal) {
      if (typeof newVal !== 'undefined') {
        this.discountSelection = '';
        this.isValidDiscountName = this.validateDiscountName();
      }
    },
    'newDiscount.type'(newVal) {
      if (typeof newVal !== 'undefined') {
        this.isValidDiscountType = this.validateDiscountType();
        this.isValidDiscountValue = this.validateDiscountValue();
      }
    },
    discountSelection(newVal) {
      if (newVal) {
        const today = new Date();
        const discountTo = new Date(newVal.discountTo);
        if (newVal.discountTo !== null && discountTo < today) {
          this.formError = {
            ...addError(
              'discountSelection',
              'The discount you selected has expired. Please select another discount.',
              'required',
              this.formError,
            ),
          };
        } else {
          this.formError = {
            ...removeError('discountSelection', this.formError, 'required'),
          };
        }
      }
    },
  },
};
