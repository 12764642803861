import axios, { AxiosResponse } from 'axios';
import Tax from '../Interfaces/Tax';
import Item from '@/Interfaces/Item';
import Audit from '@/Interfaces/Audit';
import Location from '@/Interfaces/Location';

export default class TaxService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/tax`;

  // API Calls
  async getAllTaxes(
    page?: number,
    pageSize?: number,
    search?: any,
    searchField?: any,
    orderBy?: any,
    orderByField?: any,
  ) {
    if (page && pageSize) {
      return axios.get(
        `${process.env.VUE_APP_BASE_URL}/tax?page=${page}&pageSize=${pageSize}&search=${search}&searchField=${searchField}&orderBy=${orderBy}&orderByField=${orderByField}`,
      );
    } else {
      return axios.get(`${process.env.VUE_APP_BASE_URL}/tax`);
    }
  }

  async getTaxesByLocation(locId: any) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/tax?locationId=${locId}`);
  }

  /** need to add pagination here */
  async getAllTaxesWithSnapshotTaxes() {
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/tax?expand=snapshotTaxes`,
    );
  }

  async getTaxesSnapshot(filter: any) {
    const { startDate, endDate, ...rest } = filter;
    const strDate = new Date(startDate).toUTCString();
    const edDate = new Date(endDate).toUTCString();
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/tax?expand=snapshotTaxes`,
      {
        params: {
          ...rest,
          startDate: strDate,
          endDate: edDate,
        },
      },
    );
  }

  async getTaxById(taxId: any): Promise<AxiosResponse> {
    return axios.get(`${this.BASE_URL}?taxId=${taxId}`);
  }

  async getTaxWithItemsById(taxId: any): Promise<AxiosResponse> {
    return axios.get(`${this.BASE_URL}?taxId=${taxId}&expand=items`);
  }

  async getAuditTrailForTax(taxId: any): Promise<Audit[]> {
    const res = await axios.get(`${this.BASE_URL}?taxId=${taxId}&expand=audit`);
    if (res.status === 200) return res.data.audit;
    else return [];
  }

  async createTax(tax: Tax) {
    return axios.post(`${process.env.VUE_APP_BASE_URL}/tax`, tax);
  }

  async updateTax(tax: Tax) {
    return axios.put(`${process.env.VUE_APP_BASE_URL}/tax/${tax.id}`, tax);
  }

  async removeTax(taxId: string): Promise<any> {
    return axios.delete(`${process.env.VUE_APP_BASE_URL}/tax/${taxId}`);
  }

  async saveItemTaxes(
    tax: Tax,
    itemtaxes: any,
    isSelectAll: string,
    applyAllGoods: string,
    applyAllServices: string,
    selectedLocations: any,
    isEditMode: boolean,
  ) {
    const itemtaxe = {
      taxLocations: itemtaxes.taxLocations,
      items: [],
    };
    if (tax.applyToAllGoods && tax.applyToAllServices) {
      return axios.post(
        `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
        itemtaxe,
        { params: { check: 1, itemType: '1,2' } },
      );
    } else if (tax.applyToAllGoods) {
      return axios.post(
        `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
        itemtaxe,
        { params: { check: 1, itemType: '1' } },
      );
    } else if (tax.applyToAllServices) {
      return axios.post(
        `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
        itemtaxe,
        { params: { check: 1, itemType: '2' } },
      );
    } else if (tax.applyToSpecificItems) {
      if (isSelectAll === 'add') {
        return axios.post(
          `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
          itemtaxe,
          { params: { check: 1, itemType: '1,2' } },
        );
      } else if (isSelectAll === 'remove') {
        return axios.post(
          `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
          itemtaxe,
          { params: { check: 0, itemType: '1,2' } },
        );
      } else {
        return axios.post(
          `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
          itemtaxes,
        );
      }
    }

    if (applyAllGoods === 'remove' && applyAllServices === 'remove') {
      return axios.post(
        `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
        itemtaxe,
        { params: { check: 0, itemType: '1,2' } },
      );
    }

    if (
      !tax.applyToAllGoods &&
      !tax.applyToAllServices &&
      !tax.applyToSpecificItems
    ) {
      if (isEditMode) {
        return axios.post(
          `${process.env.VUE_APP_BASE_URL}/tax/items/${tax.id}`,
          itemtaxe,
          { params: { check: 0, itemType: '1,2' } },
        );
      }
    }
  }

  // Helpers

  async saveTax(
    tax: Tax,
    selectedLocations: string[],
    selectedItems: any[],
    allItems: Item[],
    isEditMode: boolean,
    locations: Location[],
  ) {
    const locationIds = [...new Set(selectedLocations)];

    // Related unrelated locations to tax locations
    const taxLocations = tax.taxLocations.map((taxLoc) => taxLoc.location!.id);
    const locationNoAlreadyExists = locations.filter(
      (location: Location) => !taxLocations.includes(location.id),
    );

    if (locationNoAlreadyExists.length > 0) {
      locationNoAlreadyExists.forEach((location: Location) => {
        tax.taxLocations.push({
          location,
          isActive: false,
        });
      });
    }

    // Verify if tax location was selected
    tax.taxLocations = tax.taxLocations.map((taxLocation) => {
      return {
        ...taxLocation,
        isActive: locationIds.includes(taxLocation.location!.id) ? true : false,
      };
    });

    tax.items = [];
    selectedItems.forEach((itemId: any) => {
      if (typeof itemId === 'string') {
        const item = allItems.find((item) => item.id === itemId);
        // @ts-ignore
        if (item) tax.items.push(item);
      } else {
        // @ts-ignore
        tax.items.push(itemId);
      }
    });

    if (this.isValidTax(tax) && !isEditMode) {
      return await this.createTax(tax);
    } else if (this.isValidTax(tax)) {
      return await this.updateTax(tax);
    }
  }
  underline(s: any) {
    const arr = s.split('');
    s = arr.join('\u0332');
    if (s) s = s + '\u0332';
    return s;
  }

  // Validators

  private isValidTax(tax: Tax): boolean {
    if (!tax.name || tax.name === '') {
      return false;
    }

    if (!tax.value || tax.value < 0.001) {
      return false;
    }

    return true;
  }
}
