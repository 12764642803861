import { Currencies, Money } from 'ts-money';

export class MoneyFactory {
  private currency: Currency;

  private readonly DEFAULT_CURRENCY = Currencies.USD;

  public constructor(currency?: Currency) {
    if (!currency) this.currency = this.DEFAULT_CURRENCY;
    else this.currency = currency;
  }

  public toMoney = (amount: number) => Money.fromDecimal(amount, this.currency);

  public toDecimal = (money: Money) => money.amount / 100;

  public getCurrency = () => this.currency;

  public setCurrency(currency: Currency) {
    this.currency = currency;
  }
}

interface Currency {
  symbol: string;
  name: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  code: string;
  name_plural: string;
}

export const toMoney = (amount: number): Money =>
  Money.fromDecimal(amount, Currencies.USD);
export const toDecimal = (money: Money): number => money.amount / 100;
