import axios, { AxiosResponse } from 'axios';

export default class ComplimentaryService {
     BASE_URL = `${process.env.VUE_APP_BASE_URL}/complimentary-reason`;

     getAllComplementaryReasons(locationId: string, page: number, pageSize: number,): Promise<AxiosResponse> {
          return axios.get(`${this.BASE_URL}`, {
               params: {
                    locationId,
                    page,
                    pageSize,
               },
          });
     }

     createComplementaryReason(locationId: string, compReason: object) {
          const url = `${this.BASE_URL}`;
          const params = {
               locationId,
          };
          return axios.post(url, compReason, { params });
     }
}
