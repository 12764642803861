import { QuoteNotificationType } from '@/types/QuoteNotificationType';
import axios, { AxiosResponse } from 'axios';

export default class QuoteService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/quote`;

  // API Calls
  async getAllQuotes(
    locationId: any,
    page: number,
    pageSize: number,
    search: any,
    searchField: any,
    orderBy: any,
    orderByField: any,
  ) {
    if (locationId) {
      return axios.get(
        `${process.env.VUE_APP_BASE_URL}/quote?locationId=${locationId}&page=${page}&pageSize=${pageSize}`,
      );
    }
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/quote?page=${page}&pageSize=${pageSize}&search=${search}&searchField=${searchField}&orderBy=${orderBy}&orderByField=${orderByField}`,
    );
  }

  async getQuoteDetails(id: any, locationId?: string) {
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/quote?quoteId=${id}&locationId=${locationId}`,
    );
  }

  async deleteFile(
    locationId: string,
    quoteId: string,
    quoteNotificationType: QuoteNotificationType,
  ): Promise<AxiosResponse> {
    return axios.delete(
      `${process.env.VUE_APP_BASE_URL}/quote-notification/${quoteId}?locationId=${locationId}&resourceType=${quoteNotificationType.resourceType}&fileId=${quoteNotificationType.fileId}`,
    );
  }

  async getFiles(
    locationId: string,
    quoteId: string,
    quoteNotificationType: QuoteNotificationType,
  ): Promise<AxiosResponse> {
    return axios.get(
      `${process.env.VUE_APP_BASE_URL}/quote-notification/${quoteId}?locationId=${locationId}&resourceType=${quoteNotificationType.resourceType}`,
    );
  }

  async attachedFile(
    locationId: string,
    quoteId: string,
    quoteNotificationType: QuoteNotificationType,
  ): Promise<AxiosResponse> {
    return axios.post(
      `${process.env.VUE_APP_BASE_URL}/quote-notification/${quoteId}?locationId=${locationId}`,
      quoteNotificationType,
    );
  }

  async sendSMSNotification(
    locationId: string,
    quoteId: string,
    quoteNotificationType: QuoteNotificationType,
  ): Promise<AxiosResponse> {
    return axios.post(
      `${process.env.VUE_APP_BASE_URL}/quote-notification/${quoteId}?locationId=${locationId}&notificationType=mobile`,
      quoteNotificationType,
    );
  }

  async sendEmailNotification(
    locationId: string,
    quoteId: string,
    quoteNotificationType: QuoteNotificationType,
  ): Promise<AxiosResponse> {
    return axios.post(
      `${process.env.VUE_APP_BASE_URL}/quote-notification/${quoteId}?locationId=${locationId}&notificationType=email`,
      quoteNotificationType,
    );
  }
}
