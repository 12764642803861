
import { Options, Vue } from 'vue-class-component';
@Options({
  props: ['newOrder', 'calcResults'],
  components: {},
  emits: ['update-calcs'],
})
export default class TipOptions extends Vue {
  calcResults: any = null;
  newOrder: any = null;
  currency(value: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value);
  }
  changeTip(data: any) {
    if (data.item.tipText === true) {
      if (typeof data.item.tipTextType === 'undefined') {
        data.item.tipTextType = 1;
      }
      if (Number(data.item.tipTextType) === 2) {
        data.item.tip =
          (data.item.variantPrice *
            data.item.variantQuantity *
            data.item.tipAmount) /
          100;
      } else {
        data.item.tip = 0;
      }
    } else {
      data.item.tip =
        (data.item.variantPrice *
          data.item.variantQuantity *
          data.item.tipText) /
        100;
    }
    this.calcResults.tip = this.getTipTotal();
    this.$emit('update-calcs');
  }
  changeTipType(data: any) {
    if (Number(data.item.tipTextType) === 2) {
      if (data.item.tipAmount) {
        data.item.tip =
          (data.item.variantPrice *
            data.item.variantQuantity *
            data.item.tipAmount) /
          100;
      } else {
        data.item.tip = 0;
      }
    } else {
      if (data.item.tipAmount) {
        data.item.tip = parseFloat(data.item.tipAmount);
      } else {
        data.item.tip = 0;
      }
    }
    this.calcResults.tip = this.getTipTotal();
  }
  getTipTotal() {
    let tip = 0;
    this.newOrder.variantSnapshots.map((item: any) => {
      const itemTip = isNaN(item.tip) ? 0 : item.tip;
      tip = tip + itemTip;
      return item;
    });
    return tip;
  }
  calculateTip(data: any) {
    if (Number(data.item.tipTextType) === 2) {
      if (data.item.tipAmount) {
        data.item.tip =
          (data.item.variantPrice *
            data.item.variantQuantity *
            parseFloat(data.item.tipAmount)) /
          100;
      } else {
        data.item.tip = 0;
      }
    } else {
      if (data.item.tipAmount) {
        data.item.tip = parseFloat(data.item.tipAmount);
      } else {
        data.item.tip = 0;
      }
    }
    this.calcResults.tip = this.getTipTotal();
    this.$emit('update-calcs');
    return data.item.tip;
  }

  isInputNumber(event: any) {
    const char = String.fromCharCode(event.which);
    if (!/^[0-9]*\.?[0-9]*$/.test(char)) {
      event.preventDefault();
    }
  }
}
