
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  isValidRange,
  isInputNumber,
  removeError,
  addError,
} from '@/helper/validations';
import TaxService from '../services/TaxService';
import Tax from '@/Interfaces/Tax';
import { ItemType } from '@/enums/ItemType';

export default {
  name: 'TaxesSelection',
  props: {
    items: {
      required: true,
      default: [],
    },
  },
  emits: ['on-adding-tax'],
  data() {
    return {
      itemType: ItemType,
      taxSelection: '',
      newTax: {
        name: undefined,
        value: undefined,
      },
      formError: {},
      isValidTaxName: false,
      isValidTaxValue: false,
      taxService: undefined,
      loading: false,
    };
  },
  mounted() {
    this.taxService = new TaxService();
    const selectedLocationId = localStorage.getItem('primary_location_id');
    if (!this.taxesByLocation.length) {
      this.getTaxesByLocation(selectedLocationId);
    }
  },
  computed: {
    ...mapGetters(['taxesByLocation']),
    validate() {
      return this.isValidTaxName && this.isValidTaxValue;
    },
    enableAddTax() {
      return this.someSelected && (this.validate || this.taxSelection !== '');
    },
    someSelected() {
      return this.itemsList.some((item) => item.addTax);
    },
    itemsList() {
      return this.items;
    },
    allSelected() {
      return this.itemsList.every((item) => item.addTax);
    },
  },
  methods: {
    ...mapActions(['getTaxesByLocation']),
    ...mapMutations(['setNewTaxByLocation']),
    validateTaxName() {
      if (this.newTax.name.trim() === '') {
        this.formError = {
          ...addError(
            'name',
            'Tax name is required',
            'required',
            this.formError,
          ),
        };
        return false;
      }
      this.formError = { ...removeError('name', this.formError, 'required') };
      return true;
    },
    validateTaxValue() {
      this.formError = { ...removeError('value', this.formError) };
      let ok = true;
      if (String(this.newTax.value).trim() !== '' && this.newTax.value != 0) {
        this.formError = {
          ...removeError('value', this.formError, 'required'),
        };

        if (!isValidRange(this.newTax.value, 1, 99.99)) {
          // range
          this.formError = {
            ...addError(
              'value',
              'Tax value must be between 1% and 99.99%',
              'range',
              this.formError,
            ),
          };
          ok = false;
        } else {
          this.formError = { ...removeError('value', this.formError, 'range') };
        }
      } else {
        this.formError = {
          ...addError(
            'value',
            'Tax value is required',
            'required',
            this.formError,
          ),
        };
        ok = false;
      }
      return ok;
    },
    inputNumber(event: any) {
      const value = event.target.value;
      const validValue = value
        .replace(/[^0-9.]/g, '')
        .replace(/(\..*)\./g, '$1');
      this.newTax.value = validValue;
    },
    async createTax() {
      this.loading = true;
      const locations = JSON.parse(localStorage.getItem('locations'));
      const selectedLocationId = localStorage.getItem('primary_location_id');
      const selectedLocation = locations.filter(
        (loc) => loc.id === selectedLocationId,
      );

      const { name, value } = this.newTax;
      const tax: Tax = {
        id: '',
        name,
        value,
        taxLocations: [{ isActive: true, location: selectedLocation[0] }],
      };
      try {
        const result = await this.taxService.createTax(tax);
        this.setNewTaxByLocation(result.data.data.tax);
        this.newTax = {
          name: undefined,
          value: undefined,
        };
        this.isValidTaxValue = false;
        this.isValidTaxName = false;
        this.taxSelection = result.data.data.tax;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
    toggleAll(event) {
      const checkbox: HTMLInputElement = event.target;
      this.itemsList.forEach((item) => {
        item.addTax = checkbox.checked;
      });
    },
    async addTax() {
      if (this.validate) {
        await this.createTax();
      }
      const newItems = this.itemsList.map((item) => {
        if (item.addTax) {
          item = {
            ...item,
            taxesId: [this.taxSelection.id], // To send to /order
            itemTaxes: [this.taxSelection], // To calculate taxes in UI
            addedFromCreateOrder: true,
          };
        }
        return item;
      });
      this.$emit('on-adding-tax', {
        taxSelection: this.taxSelection,
        newItems,
      });
    },
  },
  watch: {
    'newTax.value'(newVal) {
      if (typeof newVal !== 'undefined') {
        this.isValidTaxValue = this.validateTaxValue();
      }
    },
    'newTax.name'(newVal) {
      if (typeof newVal !== 'undefined') {
        this.isValidTaxName = this.validateTaxName();
      }
    },
  },
};
