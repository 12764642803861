import axios, { AxiosResponse } from 'axios';

export default class LocationService {
  async getAllLocations(): Promise<AxiosResponse> {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/location`);
  }

  async getLocationById(locationId: string): Promise<AxiosResponse> {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/location`, {
      params: {
        locationId,
      },
    });
  }

  async checkLocationResources(locationId: string): Promise<AxiosResponse> {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/location`, {
      params: {
        locationId,
        expand: 'check-resources',
      },
    });
  }
}
