import { Money, Currencies } from 'ts-money';
import Tax from '@/Interfaces/Tax';
import { DiscountType } from '../enums/DiscountType';
import Discount from '@/Interfaces/Discount';
import { MoneyFactory } from './MoneyFactory';
import CalculationResults from '@/Interfaces/CalculationResults';
import ItemVariantFlattend from '@/Interfaces/ItemVariantFlattend';
import { CreateOrderRequest } from '@/contracts/CreateOrderRequest';
import { TaxCalculationData } from '@/Interfaces/TaxCalculationData';
import { ItemType } from '@/enums/ItemType';

export class OrderCalculationService {
  private moneyFactory: MoneyFactory;

  public constructor() {
    this.moneyFactory = new MoneyFactory();
  }

  public calculateOrder(order: CreateOrderRequest): CalculationResults {
    const results = new CalculationResults();
    let subtotal = new Money(0, this.moneyFactory.getCurrency());
    let discountsAmount: Money;
    let total: Money;

    if (this.hasItems(order)) {
      subtotal = this.calculateSubtotal(order.variantSnapshots);
    }
    if (this.hasDiscounts(order)) {
      discountsAmount = this.calculateDiscounts(
        order,
        subtotal,
        order.discounts!,
      );
      results.discountAmount = this.moneyFactory.toDecimal(discountsAmount);
      subtotal = subtotal.subtract(discountsAmount);
    }
    const taxData = this.setupCalcs(order);
    // Calculate taxes
    const reducedTax: Money = this.calculateAllTaxes(taxData);
    results.tax = this.moneyFactory.toDecimal(reducedTax);
    total = subtotal.add(reducedTax);
    results.subtotal = this.moneyFactory.toDecimal(subtotal);
    results.tip = this.calculateAllTips(order);
    results.total = this.moneyFactory.toDecimal(total);
    if (results.tip > 0) {
      results.total = results.total + parseFloat(results.tip);
    }
    return results;
  }

  public calculateAllTips(order: CreateOrderRequest) {
    let tip = 0;
    order.variantSnapshots.map((item) => {
      if (item.tip) {
        tip = tip + item.tip;
      }
      if (item.isCompItem || item.complementaryReasonId) {
        tip = 0;
      }
      return item;
    });
    return tip.toFixed(2);
  }

  public setupCalcs(order: CreateOrderRequest): TaxCalculationData[] {
    const taxCalcData: TaxCalculationData[] = [];

    for (const variant of order.variantSnapshots) {
      const taxes: Tax[] = variant.itemTaxes || [];

      for (const tax of taxes) {
        const result = taxCalcData.find((item) => item.tax.id === tax.id);

        if (result) {
          result.variantSnapshots.push(variant);
          continue;
        }

        const calcData: TaxCalculationData = {
          tax: tax,
          variantSnapshots: [variant],
        };
        taxCalcData.push(calcData);
      }
    }

    return taxCalcData;
  }

  public calculateSubtotal(variants: ItemVariantFlattend[]): Money {
    let subtotal = new Money(0, Currencies.USD);
    for (const variant of variants) {
      let calcSub: any;
      if (!variant.bundleDiscountFinal && !variant.bundleCustomPrice) {
        if (variant.isCompItem || variant.complementaryReasonId) {
          calcSub = 0;
        } else {
          calcSub = variant.variantPrice * variant.variantQuantity;
        }
      }
      if (variant.bundleDiscountFinal) {
        if (variant.isCompItem || variant.complementaryReasonId) {
          calcSub = 0;
        } else {
          calcSub = variant.bundleDiscountFinal * variant.variantQuantity;
        }
      }
      if (variant.bundleCustomPrice) {
        if (variant.isCompItem || variant.complementaryReasonId) {
          calcSub = 0;
        } else {
          calcSub = variant.bundleCustomPrice * variant.variantQuantity;
        }
      }
      subtotal = subtotal.add(this.moneyFactory.toMoney(calcSub.toFixed(2)));
    }
    return subtotal;
  }

  public calculateDiscounts(
    order: CreateOrderRequest,
    subtotal: Money,
    discounts: Discount[],
  ): Money {
    let discountsAmount = new Money(0, this.moneyFactory.getCurrency());
    if (discounts.length === 0) {
      this.stripDiscounts(order);
    }
    for (const discount of discounts) {
      const discountType = discount.discountType;
      if (discountType === DiscountType.Monetary) {
        this.calculateMonetaryDiscountForItems(order, subtotal, discount);
      } else if (discountType === DiscountType.Percentage) {
        this.calculatePercentageDiscountForItems(order, discount);
      }
      discountsAmount = this.moneyFactory.toMoney(
        +this.sumItemDiscounts(order).toFixed(2),
      );
    }

    if (discountsAmount.getAmount() / 100 > subtotal.getAmount() / 100) {
      return subtotal;
    }

    return discountsAmount;
  }

  public calculateItemDiscounts(itemPrice: any, discounts: any): any {
    const discountsAmount = 0;
    const itemDiscountPrice: number[] = [];

    for (const itemDiscount of discounts) {
      const discountType = itemDiscount.discount.discountType.data[0];
      if (discountType === DiscountType.Monetary) {
        const disValue = Number(itemDiscount.discount.value);
        itemDiscountPrice.push(disValue);
      } else if (discountType === DiscountType.Percentage) {
        const disValue =
          (itemPrice * Number(itemDiscount.discount.value)) / 100;
        itemDiscountPrice.push(disValue);
      }
    }
    return itemDiscountPrice.reduce((p, c) => p + c, 0);
  }

  public calculateMonetaryDiscountForItems(
    order: CreateOrderRequest,
    subtotal: Money,
    discount: Discount,
  ) {
    let nSubtotal = this.moneyFactory.toDecimal(subtotal);
    for (const snapshot of order.variantSnapshots) {
      if (!snapshot.discountSelection) {
        nSubtotal =
          nSubtotal - snapshot.variantPrice * snapshot.variantQuantity;
      }
    }
    for (const snapshot of order.variantSnapshots) {
      if (snapshot.discountSelection) {
        snapshot.itemDiscount = snapshot.variantQuantity * discount.value;
      } else {
        snapshot.itemDiscount = 0;
      }
      if (snapshot.isCompItem) {
        snapshot.itemDiscount = 0;
        snapshot.discountSelection = false;
        snapshot.discountId = null;
      }
    }
  }

  public calculatePercentageDiscountForItems(
    order: CreateOrderRequest,
    discount: Discount,
  ) {
    for (const snapshot of order.variantSnapshots) {
      if (snapshot.discountSelection) {
        snapshot.itemDiscount = snapshot.variantPrice * snapshot.variantQuantity * (discount.value / 100);
      }else{
        snapshot.itemDiscount = 0;
      }
      if(snapshot.discountId){
        snapshot.itemDiscount = snapshot.variantPrice * snapshot.variantQuantity * (discount.value / 100);
        snapshot.discountSelection = true;
      }
      if (snapshot.isCompItem) {
        snapshot.itemDiscount = 0;
        snapshot.discountSelection = false;
        snapshot.discountId = null;
      }
    }
  }

  public sumItemDiscounts(order: CreateOrderRequest): number {
    let itemDiscounts: number[] = [];

    itemDiscounts = order.variantSnapshots
      .map((snapshot) => snapshot.itemDiscount)
      .filter((discount) => discount);

    if (itemDiscounts.length > 0) {
      return itemDiscounts.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      });
    } else return 0;
  }

  public stripDiscounts(order: CreateOrderRequest): void {
    order.variantSnapshots.forEach((snapshot) => {
      snapshot.itemDiscount = 0;
    });
  }

  public calculateAllTaxes(taxData: TaxCalculationData[]): Money {
    let totalTaxValue = 0;

    for (const item of taxData) {
      let taxableValue = 0;

      for (const variant of item.variantSnapshots) {
        let variantTax: number;

        if (variant.itemDiscount && variant.discountSelection) {
          const variantPriceLessDiscount =
            variant.variantPrice * variant.variantQuantity -
              variant.itemDiscount >=
            0
              ? variant.variantPrice * variant.variantQuantity -
                variant.itemDiscount
              : 0;

          variantTax = +(variantPriceLessDiscount * item.tax.value).toFixed(2);
        } else {
          variantTax = +(
            variant.variantPrice *
            variant.variantQuantity *
            item.tax.value
          ).toFixed(2);
        }

        taxableValue += variantTax;
        if (variant.isCompItem || variant.complementaryReasonId) {
          taxableValue = 0;
        }
      }

      taxableValue = Math.round((taxableValue + 0.00001) * 100);

      const value = +(taxableValue / 100).toFixed(2);
      totalTaxValue += value;
    }

    return this.moneyFactory.toMoney(+totalTaxValue.toFixed(2));
  }

  private hasItems(order: any) {
    return order.variantSnapshots && order.variantSnapshots.length > 0;
  }

  private hasDiscounts(order: any) {
    return order.discounts && order.discounts.length > 0;
  }
}
