export const parseDueDate = (dueDate: string) => {
  if (dueDate) {
    return new Date(dueDate).toJSON().slice(0, 10).replace(/-/g, '/');
  }
  return '';
};

export const decodeJWT = () => {
  const token = localStorage.getItem('user-token');
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  return null;
};
