
import CreateOrderItemList from './CreateOrderItemList.vue';

export default {
  name: 'CreateOrderItemTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['add-to-order'],
  components: {
    CreateOrderItemList,
  },
  methods: {
    addToOrder(newItem, productName, updatedVariant) {
      this.$emit('add-to-order', newItem, productName, updatedVariant);
    },
  },
};
