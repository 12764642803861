import Location from '@/Interfaces/Location';
import LocationDiscount from '@/Interfaces/LocationDiscount';
import axios, { AxiosResponse } from 'axios';

export default class CreateDiscountPopoverService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/locationDiscount`;

  // API Calls

  async saveDiscount(discount: any): Promise<AxiosResponse> {
    return axios.post(`${this.BASE_URL}`, discount);
  }

  async saveDiscountItems(
    discount: any,
    locationId: string,
  ): Promise<AxiosResponse> {
    return await axios.put(
      `${this.BASE_URL}/itemsDiscount/${discount.id}`,
      discount,
      {
        params: {
          locationId,
        },
      },
    );
  }

  async getLocationDiscount(locationId?: any): Promise<AxiosResponse[]> {
    if (locationId) {
      const res = await axios.get(`${this.BASE_URL}?locationId=${locationId}`);
      if (res.status === 200) return res.data.locations;
      else return [];
    } else {
      const res = await axios.get(`${this.BASE_URL}`);
      if (res.status === 200) return res.data.locations;
      else return [];
    }
  }

  async getLocationDiscountByID(discountId?: any, locationId?: string) {
    const res = await axios.get(`${this.BASE_URL}/discount/${discountId}`, {
      params: {
        locationId,
      },
    });
    if (res.status === 200) return res.data;
    else return [];
  }

  async getItemDiscountByItemID(itemId?: any, locationId?: any) {
    const res = await axios.get(
      `${this.BASE_URL}/items/${itemId}?locationId=${locationId}`,
    );
    if (res.status === 200) return res.data;
    else return [];
  }

  async updateLocationDiscount(
    discount: any,
    locationId: string,
    selectedLocations: string[],
    locations: Location[],
  ) {
    const locationIds = [...new Set(selectedLocations)];

    // Related unrelated locations to tax locations
    const locationDiscount = discount.locationDiscount.map(
      (taxLoc) => taxLoc.location!.id,
    );
    const locationNoAlreadyExists = locations.filter(
      (location: Location) => !locationDiscount.includes(location.id),
    );

    if (locationNoAlreadyExists.length > 0) {
      locationNoAlreadyExists.forEach((location: Location) => {
        discount.locationDiscount.push({
          location,
          isDeleted: true,
        });
      });
    }

    // Verify if tax location was selected
    discount.locationDiscount = discount.locationDiscount.map(
      (locationDiscount) => {
        return {
          ...locationDiscount,
          isDeleted: locationIds.includes(locationDiscount.location!.id)
            ? false
            : true,
        };
      },
    );

    if (
      discount.id === undefined ||
      discount.id === '' ||
      discount.id === null
    ) {
      return axios.post(`${this.BASE_URL}`, discount, {
        params: {
          locationId,
        },
      });
    } else {
      return axios.put(`${this.BASE_URL}/${discount.id}`, discount, {
        params: {
          locationId,
        },
      });
    }
  }

  async removeDiscount(discountId: string, locationId: string): Promise<any> {
    return axios.delete(
      `${process.env.VUE_APP_BASE_URL}/locationDiscount/${discountId}?locationId=${locationId}`,
      { data: { discountId } },
    );
  }
}
