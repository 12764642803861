
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalContainer',
  emits: ['close'],
  props: {
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    staticBackdrop: {
      type: Boolean,
      default: false,
    },
    bodyProps: {
      required: false,
    },
    headerProps: {
      required: false,
    },
    footerProps: {
      required: false,
    },
    modalMask: {
      type: Boolean,
      default: true,
    },
    largeModal: {
      type: Boolean,
      default: false,
    },
  },
});
