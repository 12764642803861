
import DiscountSelection from '@/components/DiscountSelection.vue';
import ModalContainer from '@/shared/ModalContainer.vue';

export default {
  name: 'AddDiscountButton',
  components: {
    DiscountSelection,
    ModalContainer,
  },
  props: {
    items: {
      required: true,
      default: [],
    },
    btnClass: {
      required: false,
      default: 'btn-sm',
    },
    disabled: {
      required: false,
      default: false,
    },
  },
  emits: ['on-adding-discount'],
  data() {
    return {
      showDiscountSelection: false,
    };
  },
};
