import axios, { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';

export default class OrderService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/order`;

  // API Calls

  async getAllOrders(): Promise<AxiosResponse> {
    return axios.get(`${this.BASE_URL}`);
  }
  async getAllOrdersWith(
    locationId: any,
    page: number,
    pageSize: number,
    searchField?: any,
    searchType?: string,
    startDate?: string | null,
    endDate?: string | null,
    searchValue?: string,
  ) {
    let searchStr = '';
    let dateStr = '';
    if (searchField) {
      searchStr = `&orderByField=${searchField}&orderBy=${searchType}`;
    }
    if (startDate && endDate) {
      const strDate = new Date(startDate + ' 00:00:00').toString();
      const edDate = new Date(endDate + ' 23:59:59').toString();
      dateStr = `&expand=snapshots&startDate=${moment(
        strDate,
      )}&endDate=${moment(edDate)}`;
    }
    if (searchValue) {
      dateStr += '&search=' + searchValue;
    }
    if (locationId && locationId != '') {
      const apiResponse = axios.get(
        `${process.env.VUE_APP_BASE_URL}/order?locationId=${locationId}&page=${page}&pageSize=${pageSize}${searchStr}${dateStr}`,
      );
      return apiResponse;
    }
    // alert(`${process.env.VUE_APP_BASE_URL}/order?page=${page}&pageSize=${pageSize}${searchStr}`)
    const apiResponse2 = axios.get(
      `${process.env.VUE_APP_BASE_URL}/order?page=${page}&pageSize=${pageSize}${searchStr}${dateStr}`,
    );
    return apiResponse2;
  }

  async getAllOrdersWithSnapshots(): Promise<AxiosResponse> {
    return axios.get(`${this.BASE_URL}?expand=snapshots`);
  }

  async getAllOrdersWithSnapshotsWithDates(
    startDate: any,
    endDate: any,
    filter: any,
    depositAccountId: string,
  ): Promise<AxiosResponse> {
    const { orderBy, orderByField, locationId, search } = filter;
    let dateStr = '';
    if (startDate && endDate) {
      const strDate = new Date(startDate + ' 00:00:00').toString();
      const edDate = new Date(endDate + ' 23:59:59').toString();
      dateStr = `pageSize=${filter.pageSize}&page=${
        filter.page
      }&expand=snapshots&startDate=${moment(strDate)}&endDate=${moment(
        edDate,
      )}&search=${search}`;
    }
    return axios.get(`${this.BASE_URL}?${dateStr}`, {
      params: {
        orderBy,
        orderByField,
        locationId,
        depositAccountId
      },
    });
  }

  async getAllOrdersWithSnapshotsWithDatesLoc(
    startDate: any,
    endDate: any,
    locId: string,
    filter: any,
    depositAccountId: string,
  ): Promise<AxiosResponse> {
    const { orderBy, orderByField, locationId, search } = filter;
    let dateStr = '';
    if (startDate && endDate) {
      const strDate = new Date(startDate + ' 00:00:00').toString();
      const edDate = new Date(endDate + ' 23:59:59').toString();
      dateStr = `pageSize=${filter.pageSize}&page=${
        filter.page
      }&expand=snapshots&startDate=${moment(strDate)}&endDate=${moment(
        edDate,
      )}&search=${search}`;
    }
    return axios.get(`${this.BASE_URL}?locationId=${locId}&${dateStr}`, {
      params: {
        orderBy,
        orderByField,
        locationId,
        depositAccountId
      },
    });
  }

  async getProductSalesWithSnapshotsWithDates(
    startDate: any,
    endDate: any,
    filter?: any,
    locationId?: string,
  ): Promise<AxiosResponse> {
    const strDate = new Date(startDate + ' 00:00:00').toString();
    const edDate = new Date(endDate + ' 23:59:59').toString();
    let dateStr = `&page=${filter.page}&pageSize=${filter.pageSize}&expand=productSnapshots&orderByField=${filter.orderByField}&orderBy=${filter.orderBy}&noSales=${filter.noSales}`;

    if (startDate && endDate) {
      dateStr += `&startDate=${moment(strDate)}&endDate=${moment(edDate)}`;
    }

    return axios.get(`${this.BASE_URL}?locationId=${locationId}${dateStr}`);
  }

  async getOrderDetails(orderId: string, locationId: string) {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/order/${orderId}`, {
      params: {
        locationId,
      },
    });
  }
}
