import axios, { AxiosResponse } from 'axios';
import CreateItemRequest from '@/Interfaces/CreateItemRequest';
import Item from '@/Interfaces/Item';
import Variant from '@/Interfaces/Variant';
import Location from '@/Interfaces/Location';
import router from '@/router';
import Audit from '@/Interfaces/Audit';
import UploadImageResponse from '@/Interfaces/UploadImageResponse';
import Tax from '@/Interfaces/Tax';
import ConfirmationService from './ConfirmationService';

export default class ItemService {
  BASE_URL = `${process.env.VUE_APP_BASE_URL}/item`;

  // API Calls
  async validateVariantUpc(
    page: number,
    pagesize: number,
    searchTerm: string,
    locationId: string,
    variantId?: string,
  ): Promise<AxiosResponse> {
    return axios.get(
      `${this.BASE_URL}?page=${page}&pageSize=${pagesize}&search=${searchTerm}&locationIds=${locationId}&searchField=upc&specificSearch=true&variantId=${variantId}`,
    );
  }

  async getAllItemsFor(
    page?: number,
    pagesize?: number,
    searchTerm?: string,
    locationId?: string,
    locationIds?: string[],
    sortBy?: string,
    sortType?: string,
    itemType?: string,
  ): Promise<AxiosResponse> {
    if (locationId) {
      return await axios.get(
        `${this.BASE_URL}?page=${page}&pageSize=${pagesize}&search=${searchTerm}&locationIds=${locationId}`,
      );
    } else if (locationIds && searchTerm) {
      return await axios.get(
        `${
          this.BASE_URL
        }?page=${page}&pageSize=${pagesize}&search=${searchTerm}&locationIds=${locationIds.join()}&sortBy=${sortBy}&sortType=${sortType}&itemType=${itemType}`,
      );
    } else if (searchTerm) {
      return await axios.get(
        `${this.BASE_URL}?page=${page}&pageSize=${pagesize}&search=${searchTerm}`,
      );
    } else if (page && pagesize) {
      return locationId
        ? await axios.get(
            `${this.BASE_URL}?page=${page}&pageSize=${pagesize}&locationIds=${locationId}`,
          )
        : locationIds
        ? await axios.get(
            `${
              this.BASE_URL
            }?page=${page}&pageSize=${pagesize}&locationIds=${locationIds.join()}&sortBy=${sortBy}&sortType=${sortType}&itemType=${itemType}`,
          )
        : axios.get(`${this.BASE_URL}?page=${page}&pageSize=${pagesize}`);
    } else {
      return axios.get(`${this.BASE_URL}`);
    }
  }

  async getAllItems(locationId?: string): Promise<AxiosResponse> {
    if (locationId) {
      return axios.get(`${this.BASE_URL}?location=${locationId}`);
    } else {
      return axios.get(`${this.BASE_URL}`);
    }
  }

  async getItemById(
    itemId: string,
    locationId: string,
  ): Promise<AxiosResponse> {
    return axios.get(`${this.BASE_URL}/${itemId}`, {
      params: {
        locationId,
      },
    });
  }

  async saveItem(item: CreateItemRequest): Promise<AxiosResponse> {
    try {
      const res = await axios.post(`${this.BASE_URL}`, item);
      return res;
    } catch (error) {
      return Promise.reject(error.response.data);
    }
  }

  async saveImportedItems(item: any): Promise<AxiosResponse> {
    return axios.post(`${this.BASE_URL}/csv-import`, item);
  }

  async importItems(items: Item[], locationId: string): Promise<AxiosResponse> {
    return axios.post(
      `${process.env.VUE_APP_BASE_URL}/product/csv-import?locationId=${locationId}`,
      items,
    );
  }

  async removeItem(item: Item, locationId: string): Promise<AxiosResponse> {
    return axios.delete(`${this.BASE_URL}/${item.id}?locationId=${locationId}`);
  }

  async updateItem(
    item: Item,
    deletedVariants: Variant[],
    locationId: string,
  ): Promise<AxiosResponse> {
    const requestItem = JSON.parse(JSON.stringify(item));
    deletedVariants.forEach((x) => requestItem.variants.push(x));
    return axios.put(`${this.BASE_URL}/${item.id}`, requestItem, {
      params: {
        locationId,
      },
    });
  }
  async updateAllItem(item: any, locationId: string): Promise<AxiosResponse> {
    return axios.post(`${this.BASE_URL}/updateAll`, item, {
      params: {
        locationId,
      },
    });
  }
  async getTaxesForItem(itemId: string, locationId: string) {
    return await axios.get(`${this.BASE_URL}/${itemId}?expand=taxes`, {
      params: {
        locationId,
      },
    });
  }

  // Item Handlers
  async handleSubmit(
    item: Item,
    selectedLocations: Location[],
    deletedVariants: Variant[],
    isEditMode: boolean,
    photo: File | null,
    selectedTaxes: any,
    allTaxes: Tax[],
    confirmationService: ConfirmationService,
    vm: any,
    locationId: string,
  ) {
    if (photo) {
      item.hasImage = true;
    }

    // if (item.itemType === 1) {
    //   this.showAlertForUpcItem(item)
    // }

    const itemToEdit: Item = {
      id: item.id,
      name: item.name,
      description: item.description,
      itemType: item.itemType,
      variants: item.variants,
      locations: selectedLocations,
      hasImage: item.hasImage,
      taxes: selectedTaxes,
      manufacturer: item.manufacturer,
      bundles: item.bundles,
      bundleSKU: item.bundleSKU,
      bundleDiscount: item.bundleDiscount,
      bundleCustomPrice: item.bundleCustomPrice,
    };

    const itemToCreate: CreateItemRequest = {
      name: item.name,
      description: item.description,
      itemType: item.itemType,
      variants: item.variants,
      locations: selectedLocations,
      hasImage: item.hasImage,
      taxes: selectedTaxes,
      manufacturer: item.manufacturer,
      bundles: item.bundles,
      bundleSKU: item.bundleSKU,
      bundleDiscount: item.bundleDiscount,
      bundleCustomPrice: item.bundleCustomPrice,
    };

    if (item.itemType === 3) {
      if (isEditMode) {
        itemToEdit.locations = item.locations;
        itemToEdit.variants.map((variant) => {
          variant.variantLocations.map((location) => {
            if (
              itemToEdit &&
              itemToEdit.locations.length > 0 &&
              itemToEdit.locations[0].id === location.location.id
            ) {
              location.location.isActive = true;
              location.isActive = true;
            } else {
              location.location.isActive = false;
              location.isActive = false;
            }
            return location;
          });
          variant.isActive = true;
          return variant;
        });
      }

      if (!itemToCreate.locations.length) {
        itemToCreate.locations = item.locations;
      }

      if (itemToCreate.variants.length) {
        itemToCreate.variants[0].name = itemToCreate.name || '';
      }

      !isEditMode
        ? await this.handleCreateItem(
            itemToCreate,
            photo,
            confirmationService,
            vm,
          )
        : await this.handleEditItem(
            itemToEdit,
            deletedVariants,
            photo,
            confirmationService,
            vm,
            locationId,
          );
    }

    if (
      this.checkForUpcValue(item.variants, 'upc', '') === -1 ||
      item.itemType === 1 ||
      item.itemType === 2
    ) {
      !isEditMode
        ? await this.handleCreateItem(
            itemToCreate,
            photo,
            confirmationService,
            vm,
          )
        : await this.handleEditItem(
            itemToEdit,
            deletedVariants,
            photo,
            confirmationService,
            vm,
            locationId,
          );
    }

    return item;
  }

  // showAlertForUpcItem (item: any) {
  // if (this.checkForUpcValue(item.variants, 'upc', '') >= 0) {
  //   this.ShowAlertForUpc('upc')
  // }
  // }

  async handleRemoveItem(
    item: Item,
    vm: Record<string, any>,
    locationId?: string,
  ) {
    await this.removeItem(item, locationId);
    if (item.itemType === 3) {
      await vm.showNotifications({
        title: 'Success',
        type: 'success',
        activate: true,
        message: `Bundle '${item.name}' deleted successfully!`,
      });
    } else if (item.itemType === 2) {
      await vm.showNotifications({
        title: 'Success',
        type: 'success',
        activate: true,
        message: `Service '${item.name}' deleted successfully!`,
      });
    } else {
      await vm.showNotifications({
        title: 'Success',
        type: 'success',
        activate: true,
        message: `Product '${item.name}' deleted successfully!`,
      });
    }

    router.push('/items');
  }

  async handleCreateItem(
    item: CreateItemRequest,
    photo: File | null,
    confirmationService: ConfirmationService,
    vm: any,
  ) {
    let itemCreated = false;
    if (photo) await this.uploadImage(photo, item);
    try {
      await this.saveItem(item);
      itemCreated = true;
    } catch (error) {
      await vm.showNotifications({
        title: 'Error',
        type: 'error',
        activate: true,
        message: error.errors[0].replace('Error: ', ''),
      });
      itemCreated = false;
      confirmationService.setFlagValue(false);
    } finally {
      if (itemCreated) {
        /*if (item.itemType === 3) {
          await vm.showNotifications({
            title: "Success",
            type: "success",
            activate: true,
            message: `Bundle '${item.name}' added successfully!`
          })
        } else if (item.itemType === 2) {
          await vm.showNotifications({
            title: "Success",
            type: "success",
            activate: true,
            message: `Service '${item.name}' added successfully!`
          })
        } else {
          await vm.showNotifications({
            title: "Success",
            type: "success",
            activate: true,
            message: `Product '${item.name}' added successfully!`
          })
        }*/
        //confirmationService.setFlagValue(false)
        //router.push('/items')
      }
    }
  }

  async handleEditItem(
    item: Item,
    deletedVariants: Variant[],
    photo: File | null,
    confirmationService: ConfirmationService,
    vm: any,
    locationId: string,
  ) {
    try {
      if (photo) await this.uploadImage(photo, item);
      await this.updateItem(item, deletedVariants, locationId);
    } catch (error) {
      await vm.showNotifications({
        title: 'Error',
        type: 'error',
        activate: true,
        message: error.errors[0].replace('Error: ', ''),
      });
      confirmationService.setFlagValue(false);
    } finally {
      if (item.itemType === 3) {
        await vm.showNotifications({
          title: 'Success',
          type: 'success',
          activate: true,
          message: `Bundle '${item.name}' updated successfully!`,
        });
      } else if (item.itemType === 2) {
        await vm.showNotifications({
          title: 'Success',
          type: 'success',
          activate: true,
          message: `Service '${item.name}' updated successfully!`,
        });
      } else {
        await vm.showNotifications({
          title: 'Success',
          type: 'success',
          activate: true,
          message: `Product '${item.name}' updated successfully!`,
        });
      }
      confirmationService.setFlagValue(false);
      router.push('/items');
    }
  }

  private async uploadImage(
    photo: File,
    item: Item | CreateItemRequest,
  ): Promise<UploadImageResponse> {
    let uploadResponse: UploadImageResponse = { succeeded: false };
    uploadResponse = await this.handleImageUpload(item, photo);
    item.hasImage = uploadResponse.succeeded;
    if (uploadResponse.succeeded) {
      item.imgKey = uploadResponse.key;
    }

    return uploadResponse;
  }

  async getStockAuditTrailForVariant(
    itemId: string,
    variantId: string,
  ): Promise<Audit[]> {
    const res = await axios.get(
      `${this.BASE_URL}/${itemId}?expand=variantLocationAudit&variantId=${variantId}`,
    );
    if (res.status === 200) return res.data.audit;
    else return [];
  }

  async getAuditTrailForItem(itemId: string): Promise<Audit[]> {
    const res = await axios.get(`${this.BASE_URL}/${itemId}?expand=audit`);
    if (res.status === 200) return res.data.audit;
    else return [];
  }

  // Image Handlers
  async handleImageUpload(
    item: Item | CreateItemRequest,
    photo: File,
  ): Promise<UploadImageResponse> {
    const photoResp = await axios.post(`${this.BASE_URL}/image-upload`);
    const uploadUrl = photoResp.data.signedUrl.url;
    const fields = photoResp.data.signedUrl.fields;
    const formData = new FormData();
    for (const key in fields) {
      formData.append(key, fields[key]);
    }
    let imgKey;
    if (photo.type) {
      imgKey = photoResp.data.signedUrl.fields['key'];
      formData.append('Content-Type', photo.type);
      formData.append('file', photo);
    } else {
      imgKey = photo;
      formData.append('Content-Type', 'image/jpeg');
      formData.append('file', photo);
    }
    try {
      const image = await axios.post(uploadUrl, formData);
      return { succeeded: true, key: imgKey };
    } catch (err) {
      return { succeeded: false };
    }
  }

  // Video Handlers
  async handleVideoUpload(photo: File): Promise<UploadImageResponse> {
    const photoResp = await axios.post(`${this.BASE_URL}/video-upload`);
    const uploadUrl = photoResp.data.signedUrl.url;
    const fields = photoResp.data.signedUrl.fields;
    const formData = new FormData();
    for (const key in fields) {
      formData.append(key, fields[key]);
    }

    formData.append('Content-Type', 'video/mp4');
    formData.append('file', photo);

    try {
      const image = await axios.post(uploadUrl, formData);
      return { succeeded: true, key: uploadUrl + '/' + fields.key };
    } catch (err) {
      return { succeeded: false };
    }
  }

  async addVideoInDb(video: any, vm: any) {
    await axios.post(`${process.env.VUE_APP_BASE_URL}/addvideo`, video);
    await vm.showNotifications({
      title: 'Success',
      type: 'success',
      activate: true,
      message: `Video Added successfully!`,
    });
    router.push('/videolist');
  }

  async getVideoById(videoId: string): Promise<AxiosResponse> {
    return axios.get(`${process.env.VUE_APP_BASE_URL}/getvideoRow/${videoId}`);
  }

  async updateVideoInDb(video: any, vm: any, flag?: any) {
    await axios.put(`${process.env.VUE_APP_BASE_URL}/editvideo`, video);
    if (flag != 1) {
      await vm.showNotifications({
        title: 'Success',
        type: 'success',
        activate: true,
        message: `Video updated successfully!`,
      });
      router.push('/videolist');
    }
  }

  async deleteVideoInDb(id: any) {
    await axios.delete(`${process.env.VUE_APP_BASE_URL}/deletevideo/${id.id}`);
  }

  async handleImageDelete(item: Item) {
    await axios.put(`${this.BASE_URL}/${item.id}?deleteImage=true`);
  }

  // Variant Handlers
  handleVariantRemove(
    item: Item,
    variant: Variant,
    deletedVariants: Variant[],
  ) {
    if (Object.prototype.hasOwnProperty.call(variant, 'id')) {
      variant.isDeleted = true;
      deletedVariants.push(variant);
    }

    this.removeVariant(item, variant);
  }

  // Variant Helpers
  addVariant(item: Item) {
    const newVariant: any = {
      name: '',
      sku: '',
      price: 0,
      variantLocations: [],
      upc: '',
      isDeleted: false,
      quantity: 1,
      isActive: false,
      messageValidations: { upc: '' },
    };
    if (item) {
      // if (this.checkForUpcValue(item.variants, 'upc', '') >= 0 && item.itemType === 1) {
      //   this.ShowAlertForUpc('upc')
      // } else {
      item.variants.push(newVariant);
      // }
    }
  }

  checkForUpcValue(array: any, attr: any, value: any) {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  ShowAlertForUpc(type: string) {
    // alert(`Please Add ${type}`)
    // this.$emit('is-progress', false)
  }

  removeVariant(item: Item, variant: Variant) {
    for (let i = item.variants.length - 1; i >= 0; i--) {
      if (item.variants[i] === variant) {
        item.variants.splice(i, 1);
      }
    }
  }

  importVariant(item: Item, selectedVariant: any) {
    const newVariant: any = {
      name: selectedVariant.name,
      sku: selectedVariant.sku,
      price: selectedVariant.price,
      variantLocations: [],
      upc: selectedVariant.upc,
      isDeleted: false,
      quantity: 1,
      isActive: false,
    };
    item.variants.push(newVariant);
  }

  async updateVariant(variants: any, locationId: any): Promise<AxiosResponse> {
    return axios.put(
      `${this.BASE_URL}/updateIsTop-ForVariant/${locationId}`,
      variants,
    );
  }
}
